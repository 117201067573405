<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title>Mitarbeiter</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content class="no-padding-top">
            <Skeleton v-if="employeeLoader.pending.value" :items="['line', 'line', 'line']"></Skeleton>
            <ion-list v-else>
                <ion-item
                    v-for="employee in employees"
                    @click="onClickEmployee(employee)"
                    :key="employee.id"
                >
                    <IonLabel>
                        <h3>
                            {{ employee.name }} (<AZKValue :employee="employee" :compact="true"/>)
                            <IonBadge v-if="employeesNeedAction.get(employee.id) > 0" color="danger">
                                {{ employeesNeedAction.get(employee.id) }}
                            </IonBadge>
                        </h3>
                        <span v-if="employeeIsImpersonated(employee)">
                            aktuell ausgewählt
                        </span>
                        <span
                            v-for="calendarMonth in employeeMonths(employee)"
                        >

                            <AutopilotStatusMonthSummaryChip
                                :autopilot-status="autopilotStatus.find(autopilotStatus => autopilotStatus.month === calendarMonth.getId() && autopilotStatus.employee.id === employee.id)"
                                :employee="employee"
                                :calendar-month="calendarMonth"
                                :month-label-format="'MMM'"
                            ></AutopilotStatusMonthSummaryChip>
                        </span>
                    </IonLabel>
                </ion-item>
            </ion-list>

            <ion-action-sheet
                :is-open="isActionSheetOpen"
                :buttons="actionSheetButtons"
                @didDismiss="handleActionSheetDismiss"
            ></ion-action-sheet>

            <ion-modal 
                :is-open="isEmployeeModalOpen"
                @didDismiss="isEmployeeModalOpen = false; autopilotStatusOfSelectedEmployee = []"
            >
                <ion-header>
                    <ion-toolbar>
                        <ion-buttons slot="start">
                            <ion-button @click="isEmployeeModalOpen = false">Schließen</ion-button>
                        </ion-buttons>
                        <ion-title>Mitarbeiter Details</ion-title>
                    </ion-toolbar>
                </ion-header>
                <ion-content class="ion-padding">
                    <h2>{{ selectedEmployee.name }}</h2>
                    <h3>Lieblingsstationen</h3>
                    <ion-list>
                        <ion-item v-for="jl in selectedEmployee.favoriteJobsites.map((j) => getShortJobsiteDescription(j, false, ' - ')).toSorted()"
                        >
                            <ion-label>{{jl}}</ion-label>
                        </ion-item>
                    </ion-list>
                    
                    <template v-for="calendarMonth in employeeMonths(selectedEmployee)">
                        <h2>{{ date.formatKapitelDate(calendarMonth.begin, 'MMMM yyyy') }}</h2>
                        <AutopilotStatusMonthSummaryChip
                            :autopilot-status="autopilotStatusOfSelectedEmployee.find(autopilotStatus => autopilotStatus.month === calendarMonth.getId() && autopilotStatus.employee.id === selectedEmployee.id)"
                            :employee="selectedEmployee"
                            :calendar-month="calendarMonth"
                            :month-label-format="'MMM'"
                        ></AutopilotStatusMonthSummaryChip>
                        <h3>Buchungsvorhersage</h3>
                        Wir vermuten, dass mit den aktuellen Einstellungen noch c.a. {{ Math.round((autopilotStatusOfSelectedEmployee.find(autopilotStatus => autopilotStatus.month === calendarMonth.getId() && autopilotStatus.employee.id === selectedEmployee.id)?.predictedAdditionalBookings ?? 0 )*100)/100 }} Buchungen dazukommen.
                        <h3>Aktive Schichttypen</h3>
                        {{ (autopilotStatusOfSelectedEmployee.find(
                        autopilotStatus => autopilotStatus.month === calendarMonth.getId()
                            && autopilotStatus.employee.id === selectedEmployee.id)?.shiftPortfolio || []).join(", ") }}
                        <h3>Aktive Stationen</h3>
                        <ion-list>
                            <ion-item v-for="aj in getJobsitesSortedByLabel(autopilotStatusOfSelectedEmployee.find(
                                autopilotStatus => autopilotStatus.month === calendarMonth.getId() 
                                && autopilotStatus.employee.id === selectedEmployee.id)?.autopilotPreferences.autopilotJobsites.map((aj => aj.jobsite)) || [])"
                            >
                                <ion-label>{{getShortJobsiteDescription(aj,  false, " - ")}}</ion-label> (⌀ {{ autopilotStatusOfSelectedEmployee.find(
                                    autopilotStatus => autopilotStatus.month === calendarMonth.getId()
                                    && autopilotStatus.employee.id === selectedEmployee.id)?.avgDemandPerMonth.find(
                                        avg => avg.jobsite.id === aj.id)?.averageMonthlyDemand || 0 }} Anfr./Monat, Bedarfsvorhersage: #{{ autopilotStatusOfSelectedEmployee.find(
                                autopilotStatus => autopilotStatus.month === calendarMonth.getId()
                                    && autopilotStatus.employee.id === selectedEmployee.id)?.avgDemandPerMonth.find(
                                avg => avg.jobsite.id === aj.id)?.currentPrediction || 0 }})
                            </ion-item>
                        </ion-list>
                    </template> 
                        
                </ion-content>
            </ion-modal>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
import {
    IonContent,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonPage,
    IonTitle,
    IonToolbar,
    onIonViewWillEnter,
    loadingController,
    IonBadge,
    IonActionSheet,
    IonModal,
    IonButton,
    IonButtons
} from '@ionic/vue'
import { fetchEmployees } from '@/helper/employee'
import { useAuthStore } from '@/store/auth'
import {Ref, ref} from 'vue'
import { useRouter } from 'vue-router'
import Skeleton from "@/components/Skeleton.vue";
import {DataLoader} from "@/helper/dataLoader.js";
import {AInesObjective, AutopilotStatus, Employee, Jobsite} from "@/graphql/generated/graphql";
import { useAppState } from '@/helper/appState'
import {
    fetchAutopilotStatusWithStats,
    fetchCurrentAutopilotStatus,
    getAutopilotStatusSummary
} from '@/helper/autopilotStatus'
import {CalendarMonth, generateCalendarMonth, generateCalendarMonths, sorter} from '@/helper/calendar/calendarMonth'
import date from '@/helper/datetime/date'
import AutopilotStatusMonthSummaryChip from "@/views/components/Planning/AutopilotStatusMonthSummaryChip.vue";
import useOfflineHelper from "@/helper/offline";
import {getShortJobsiteDescription} from "@/helper/jobsite";
import {fetchAInesObjectives} from "@/helper/chat/ainesObjectives";
import AZKValue from "@/views/components/Planning/AZKValue.vue";

const authStore = useAuthStore()

const router = useRouter()

/** 
 * employees
 */
const employees : Ref<Employee[]> = ref([])
const employeesNeedAction: Ref<Map<Number, Number>> = ref(new Map())

const refetchObjectives = async () => {
    // for (const employee of employees.value) {
    //     const objectives: AInesObjective[] =  await fetchAInesObjectives(employee)
    //     const needAction = objectives.filter(o => o.needsAction).length
    //     employeesNeedAction.value.set(employee.id, needAction)
    // }
    const results = await Promise.all(
        employees.value.map(async (employee) => {
            const objectives: AInesObjective[] = await fetchAInesObjectives(employee);
            const needAction = objectives.filter(o => o.needsAction).length;
            return { employeeId: employee.id, needAction };
        })
    );

    results.forEach(({ employeeId, needAction }) => {
        employeesNeedAction.value.set(employeeId, needAction);
    });
}

const employeeLoader = new DataLoader(
    async () => {
        await useAppState().stopImpersonation()
        employees.value = await fetchEmployees()
        refetchObjectives()
    })

onIonViewWillEnter(employeeLoader.load)
employeeLoader.load()

const selectedEmployee = ref<Employee|null>(null)
const isActionSheetOpen = ref(false)
const isEmployeeModalOpen = ref(false)

const getJobsitesSortedByLabel = (jobsites: Jobsite[]) => {
    return jobsites.sort((a, b) => {
        const labelA = getShortJobsiteDescription(a, false, ' - ')
        const labelB = getShortJobsiteDescription(b, false, ' - ')
        return labelA.localeCompare(labelB)
    })
}

const actionSheetButtons = [
    {
        text: 'Als dieser Mitarbeiter anmelden',
        data: {
            action: 'impersonate',
        },
    },
    {
        text: 'Details anzeigen',
        data: {
            action: 'showDetails',
        },
    },
    {
        text: 'Abbrechen',
        role: 'cancel',
        data: {
            action: 'cancel',
        },
    },
]

const onClickEmployee = (employee: Employee) => {
    if(offlineHelper.isOffline.value){
        return;
    }
    selectedEmployee.value = employee
    autopilotLoaderOfSelectedEmployee.load()
    isActionSheetOpen.value = true
}

const handleActionSheetDismiss = async (event: any) => {
    const data = event.detail.data
    if (!data?.action || !selectedEmployee.value) {
        isActionSheetOpen.value = false
        return
    }

    switch (data.action) {
        case 'impersonate':
            const loading = await loadingController.create({
                message: 'Mitarbeiterwechsel...'          
            });
            loading.present();
            await useAppState().impersonate(selectedEmployee.value)
            loading.dismiss()
            router.push('/')
            break;
        case 'showDetails':
            isEmployeeModalOpen.value = true
            break;
    }
    
    isActionSheetOpen.value = false
}

const employeeIsImpersonated = (employee: Employee) => {
    return employee.user.userName === authStore.getImpersonationUsername()
}

/** autopilot Status */
const autopilotStatus : Ref<AutopilotStatus[]> = ref([])
const autopilotLoader = new DataLoader(async () => {
    autopilotStatus.value = await fetchCurrentAutopilotStatus()
})
const autopilotStatusOfSelectedEmployee: Ref<AutopilotStatus[]> = ref([])
const autopilotLoaderOfSelectedEmployee = new DataLoader(async () => {
    if(!selectedEmployee.value){
        return
    }
    const selectedEmployee_ = selectedEmployee.value as Employee

    const months = employeeMonths(selectedEmployee_)

    for (const month of months) {
        const autopilotStatus = await fetchAutopilotStatusWithStats(month, selectedEmployee_)
        if(!autopilotStatus){
            continue
        }
        autopilotStatusOfSelectedEmployee.value.push(autopilotStatus)
    }

})

const employeeMonths = (employee: Employee) : CalendarMonth[] => {
    const employeeStatusMonths = autopilotStatus.value
        .filter(aps => aps.employee.id === employee.id)
        .map(aps => generateCalendarMonth(aps.month))
        .sort(sorter);

    let from = date.startOfMonth(date.getToday())
    if (employeeStatusMonths[0] && employeeStatusMonths[0].begin < from) {
        from = employeeStatusMonths[0].begin
    }

    let to = date.addMonths(from, 1)
    const lastStatusMonth = employeeStatusMonths[employeeStatusMonths.length-1]
    if (lastStatusMonth && lastStatusMonth.begin > to) {
        to = lastStatusMonth.begin
    }

    return generateCalendarMonths(from, undefined, to)
}

const offlineHelper = useOfflineHelper()

onIonViewWillEnter(autopilotLoader.load)
autopilotLoader.load()

</script>
