<template>
    <RichResponseHeadline
            :title="headline"
            class="headline"
    />
    <PlanningStatusAndPreferences :month="initData" :focus-mode="'auto'" :showAvailabilitySuggestions="true" :showJobsiteSuggestions="true"></PlanningStatusAndPreferences>
</template>

<script setup lang="ts">

import PlanningStatusAndPreferences from "@/views/components/Planning/PlanningStatusAndPreferences.vue";
import {computed, PropType} from "vue";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import RichResponseHeadline from "@/views/Chat/RichResponses/components/RichResponseHeadline.vue";
import {formatMonthFromKapitelDate, getYearFromKapitelDate} from "@/helper/datetime/date";

const props = defineProps({
    initData: {
        type: String as PropType<kapitelDateString>,
        required: true
    }
})

const headline = computed(() => {
    return `Planungsstatus ${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`
})
</script>
<style lang="scss" scoped>
.headline {
    margin-bottom: 0.5em;
}
</style>
