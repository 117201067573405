<template>
    <Modal v-model="showRegisterBooking" title="Dienst eintragen" class="no-padding-top">
        <Skeleton
            v-if="loader.pending.value"
            :items="['margin', 'title', 'block--height-6rem']"
        ></Skeleton>
        <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
            <ion-card-header>
                <ion-card-title>Station</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-list>
                    <ion-item
                        detail
                        v-on:click="isChangeJobsiteModalActive = true"
                        lines="none"
                    >
                        <ion-label>
                            <template v-if="jobsiteSelected && jobsite">
                                <h3>{{ jobsite?.name }}</h3>
                                <p>{{ getJobsiteShortestCompleteDescription(jobsite, true) }}</p>
                            </template>
                            <p v-else-if="!jobsiteSelected">bitte auswählen</p>
                        </ion-label>
                    </ion-item>
                </ion-list>
                <JobsiteSearchModal
                    v-model="isChangeJobsiteModalActive"
                    @select="onChangeJobsite"
                    :alreadySelectedJobsites="alreadySelectedJobsites"
                    :quick-select-jobsite-category-segments="employeeJobsiteSegments"
                />
            </ion-card-content>
        </ion-card>
        <Skeleton
            v-if="loader.pending.value"
            :items="['margin', 'title', 'block--height-6rem']"
        ></Skeleton>
        <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
            <ion-card-header>
                <ion-card-title>Qualifikation</ion-card-title>
            </ion-card-header>
            <ion-card-content>
                <ion-list>
                    <ion-item
                        detail
                        v-on:click="isChangeQualificationModalActive = true"
                        lines="none"
                    >
                        <ion-label>
                            <h3>{{ qualification?.label }}</h3>
                        </ion-label>
                    </ion-item>
                </ion-list>
                <QualificationSelectorModal
                    v-model="isChangeQualificationModalActive"
                    @select="onChangeQualification"
                />
            </ion-card-content>
        </ion-card>
        <Skeleton
            v-if="loader.pending.value"
            :items="['margin', 'title', 'block--height-3rem']"
        ></Skeleton>
        <ion-card v-if="!loader.pending.value" class="custom-ion-card-floating">
            <!-- <ion-card class="custom-ion-card-dark"> -->
            <ion-card-header>
                <ion-card-title>Schichtauswahl</ion-card-title>
            </ion-card-header>
            <ion-card-content class="no-padding-top no-padding-bottom">
                <ion-list>
                    <ion-item
                        v-for="(
                                    registerBooking, index
                                ) in sortBookings(newRegisterBookings)"
                        :key="index"
                        detail
                        @click="()=>{onClickBooking(registerBooking)}"
                    >
                        <BookingSummary
                            :booking="registerBooking"
                            :shift="reverseGuessShift(registerBooking)"
                        ></BookingSummary>
                    </ion-item>
                    <ion-item
                        :detail="shiftsAddable"
                        v-on:click="() => { if (shiftsAddable) { isAddShiftModalActive = true } }"
                        lines="none"
                    >
                        <ion-label>
                            <p v-if="!shiftsAddable">zuerst Station auswählen</p>
                            <p v-else-if="shiftsAddable && newRegisterBookings.length === 0">bitte auswählen</p>
                            <h3 v-else-if="shiftsAddable && newRegisterBookings.length > 0">Schicht Hinzufügen</h3>
                        </ion-label>
                    </ion-item>
                    <EditRegisterBookingModal
                        v-model="isEditBookingModalActive"
                        :booking="editBooking"
                        v-on:remove:booking="onRemoveBooking"
                        v-on:start:updateShiftTimes="onStartUpdateShiftTimes"
                    />
                    <EditRegisterBookingTimesModal
                        v-model="isEditBookingTimesModalActive"
                        :booking="editBooking"
                        v-on:remove:booking="onRemoveBooking"
                        v-on:update:bookingTimes="onUpdateBookingTimes"
                    />
                    <AddShiftModal
                        v-model="isAddShiftModalActive"
                        v-on:add:shifts="onAddShifts"
                        :preselected-dates="props.datePreselection ? [props.datePreselection] : undefined"
                        :minDate="props.dateMin"
                        :maxDate="props.dateMax"
                    />
                </ion-list>
            </ion-card-content>
        </ion-card>
        <template v-slot:actions>
            <Skeleton
                v-if="loader.pending.value"
                :items="['button']"
            />
            <ion-button
                expand="block"
                :disabled="newRegisterBookings.length == 0"
                color="primary"
                v-else-if="!loader.pending.value"
                @click="isSubmitModalActive = true"
            >
                {{ pluralize(newRegisterBookings.length, 'Dienst', 'Dienste', '') }} eintragen
            </ion-button>
        </template>

        <Modal v-model="isSubmitModalActive" title="Einsatz abgesprochen?">
            <p>Eingetragene Dienste müssen mit der Station abgesprochen sein. Gegebenenfalls wird die Station über den eingetragenen Dienst informiert und muss diesen noch gegenbestätigen.</p>
            <p>Bitte bestätige, dass der geplante Einsatz abgesprochen ist und die Station darüber informiert ist:</p>
            <br>
            <IonCheckbox label-placement="end" justify="start" v-model="isSubmitModalConfirmed">
                Ja, der Dienst ist abgesprochen und die Station ist informiert
            </IonCheckbox>
            <br><br>
            <ion-button
                expand="block"
                color="primary"
                @click="onSubmit"
                :disabled="!isSubmitModalConfirmed"
            >
                Einsatz eintragen
            </ion-button>

        </Modal>
    </Modal>
</template>

<script setup lang="ts">
import {AInesAssistantType, Jobsite, Qualification, ShiftType} from "@/graphql/generated/graphql";
import {
    alertController,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonList,
    IonCheckbox
} from "@ionic/vue";
import Skeleton from "@/components/Skeleton.vue";
import {DataLoader} from "@/helper/dataLoader";
import {
    fetchJobsiteShiftTimes,
    getEmployeeJobsiteCategorySegments,
    getJobsiteShortestCompleteDescription
} from "@/helper/jobsite";
import {computed, PropType, ref} from "vue";
import {kapitelDateString, kapitelTimeString} from "@/graphql/kapitelTypes";
import {fetchRegisterBookingDefaults, setRegisterBookings, shiftDate} from "@/helper/registerBooking";
import JobsiteSearchModal from "@/views/components/JobsiteSearchModal.vue"
import QualificationSelectorModal from "@/components/QualificationSelectorModal.vue"
import AddShiftModal from "./AddShiftModal.vue";
import EditRegisterBookingModal from "./EditRegisterBookingModal.vue"
import EditRegisterBookingTimesModal from "./EditRegisterBookingTimesModal.vue"
import Modal from "@/components/Modal.vue";
import datetime, {convertDateTime2Date} from "@/helper/datetime/datetime";
import BookingSummary from "@/components/BookingSummary.vue";
import {useRouter} from "vue-router";
import {pluralize} from "@/helper/amountFormatter";
import {sendUserMessage} from "@/helper/chat/chatBL";


const props = defineProps({
    initData: {
        jobsite: {
            type: Object as PropType<Jobsite>,
            required: false,
            default: null
        },
        shiftDates: {
            type: Array<shiftDate>,
            required: false,
            default: null
        },
    },
    datePreselection: String as PropType<kapitelDateString>,
    dateMin: String as PropType<kapitelDateString>,
    dateMax: String as PropType<kapitelDateString>,
})

const showRegisterBooking = defineModel()

const jobsite = ref<Jobsite | undefined>(undefined);
const alreadySelectedJobsites = computed(():Jobsite[] | undefined => jobsite.value ? [jobsite.value]: [])

const qualification = ref<Qualification | undefined>(undefined);

const jobsiteSelected = computed(() => !!jobsite.value)
const shiftsAddable = computed(() => jobsiteSelected.value && !!jobsiteSpecificShiftTimes.value)

interface shiftTimes {
    [key: string]: {
        begin: kapitelTimeString;
        until: kapitelTimeString;
    };
}


const jobsiteSpecificShiftTimes = ref<shiftTimes>({});

interface times2shiftLookupInterface {
    [key: string]: ShiftType;
}
const times2shiftLookup: times2shiftLookupInterface = {}

const newRegisterBookings = ref<BookingInput[]>([]);

// Navigation Flow
const router = useRouter();
const isChangeJobsiteModalActive = ref(false);
const isChangeQualificationModalActive = ref(false);
const isEditBookingModalActive = ref(false);
const isEditBookingTimesModalActive = ref(false);
const editBooking = ref<BookingInput|undefined>(undefined)
const isAddShiftModalActive = ref(false);
const isSubmitModalActive = ref(false);
const isSubmitModalConfirmed = ref(false);


const generateBooking = (shift: shiftDate): BookingInput => {
    if (!jobsiteSpecificShiftTimes.value[shift.type]) {
        throw new Error("jobsiteSpecificShiftTimes undefined.");
    }

    if (!jobsite.value) {
        throw new Error("jobsite undefined.");
    }

    if (!qualification.value) {
        throw new Error("qualification undefined.");
    }

    times2shiftLookup[
        jobsiteSpecificShiftTimes.value[shift.type].begin+"-"+jobsiteSpecificShiftTimes.value[shift.type].until
        ] = shift.type

    const begin = datetime.convertDateAndTime2DateTime(
        shift.date,
        jobsiteSpecificShiftTimes.value[shift.type].begin,
    );

    let until = datetime.convertDateAndTime2DateTime(
        shift.date,
        jobsiteSpecificShiftTimes.value[shift.type].until,
    );

    if (datetime.isAfter(begin, until)) {
        until = datetime.addDays(until, 1);
    }

    return {
        begin: begin,
        until: until,
        jobsite: {
            id: jobsite.value.id
        },
        qualification: {
            id: qualification.value.id
        },
    };
};

const reverseGuessShift = (booking: BookingInput) => {
    const beginTime = datetime.convertDateTime2Time(booking.begin)
    const untilTime = datetime.convertDateTime2Time(booking.until)
    return times2shiftLookup[beginTime+"-"+untilTime] ?? ''
}

const sortBookings = (bookings: BookingInput[]) => {
    return bookings.sort((b1, b2) => {
        return datetime.cmpKapitelDateTime(b1.begin, b2.begin)
    })
}

const onChangeJobsite = async (jobsite_: Jobsite) => {
    jobsite.value = jobsite_
    const response = await fetchJobsiteShiftTimes(jobsite_)
    jobsiteSpecificShiftTimes.value = {};
    response.forEach((st) => {
        jobsiteSpecificShiftTimes.value[st.type] = {
            begin: st.begin,
            until: st.until,
        };
    });

}

const onChangeQualification = async (qualification_: Qualification) => {
    qualification.value = qualification_
}


const onAddShifts = (newShifts: shiftDate[]) => {
    const existingDates = newRegisterBookings.value.map((b) => convertDateTime2Date(b.begin));

    newShifts.forEach((s: shiftDate) => {
        const index = existingDates.indexOf(s.date);

        if (index > -1) { // update
            newRegisterBookings.value[index] = generateBooking(s);
        } else { // add
            newRegisterBookings.value.push(generateBooking(s))
        }
    });

    isAddShiftModalActive.value = false;
};

const onClickBooking = (booking: BookingInput) => {
    isEditBookingModalActive.value = true
    editBooking.value = booking
}

const onUpdateBookingTimes = (newBooking: BookingInput, oldBooking: BookingInput) =>  {
    const index = newRegisterBookings.value.indexOf(oldBooking)
    if(index>=0){
        newRegisterBookings.value.splice(index, 1)
    }

    newRegisterBookings.value.push(newBooking)
}

const onSubmit = async () => {
    const {successfulBookings, errors} = await setRegisterBookings(newRegisterBookings.value)

    isSubmitModalActive.value = false;

    const alert = (errors.length > 0)
        ? await alertController.create({
                header: 'Fehler',
                // subHeader: 'A Sub Header Is Optional',
                message: 'Nicht alle Dienste konnten erfolgreich eingetragen werden (' + errors.join('; ') + ')',
                buttons: [
                    {
                        text: 'Zurück',
                    }
                ],
            })
        : await alertController.create({
            header: 'Dienst eintragen erfolgreich',
            // subHeader: 'A Sub Header Is Optional',
            message: pluralize(successfulBookings.length, 'Dienst', 'Dienste')  + ' erfolgreich eingetragen',
            buttons: [{
                text: 'Ok',
                handler: () => {
                    newRegisterBookings.value = [];
                    showRegisterBooking.value = false
                    alertController.dismiss().then(()=>{sendUserMessage("Vielen Dank, ich konnte über das Interface " + pluralize(successfulBookings.length, "Dienst", "Dienste") + " eintragen.", {messageExpertAssistant: AInesAssistantType.RegisterBooking, isScriptedContent: true})})
                }
            }],
        })

    await alert.present()
};

const onRemoveBooking = (booking: BookingInput) => {
    const index = newRegisterBookings.value.indexOf(booking)
    if(index>=0){
        newRegisterBookings.value.splice(index, 1)
    }
}

const onStartUpdateShiftTimes = () => {
    isEditBookingTimesModalActive.value = true;
}

const employeeJobsiteSegments : Ref<JobsiteCategorySegment[]> = ref([])
getEmployeeJobsiteCategorySegments()
    .then(segments => employeeJobsiteSegments.value = segments)


/**
 * Init
 */

const fetchAndParseRegisterBookingDefaults = async () => {
    const response = await fetchRegisterBookingDefaults();

    if (!jobsite.value && response.jobsite) {
        jobsite.value = response.jobsite;
    }

    if (!qualification.value && response.qualification) {
        qualification.value = response.qualification;
    }

    response.shiftTimes.forEach((st) => {
        jobsiteSpecificShiftTimes.value[st.type] = {
            begin: st.begin,
            until: st.until,
        };
    });

};

const loader = new DataLoader(async () => {
    await fetchAndParseRegisterBookingDefaults()

    if (props.initData?.jobsite) {
        jobsite.value = props.initData.jobsite
    }
    
    if (props.initData?.shiftDates) {
        onAddShifts(props.initData.shiftDates)
    }

});
loader.load();


</script>

<style lang="scss"></style>
