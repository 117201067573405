import {useChatStore} from "@/helper/chat/chatStore";
import {AInesAssistantType} from "@/graphql/generated/graphql";

import {
    addMessage,
    createAssistant,
    createThread,
    getApiKey,
    MessagePersistParameters,
    persistMessage
} from "@/helper/chat/assistantAPI";
import {AssistantRunExpert} from "@/helper/chat/assistantRun/assistantRunExpert";
import {AssistantRunChooser} from "@/helper/chat/assistantRun/assistantRunChooser";
import {getAvailableAssistants, reheatAssistantFeatureMapping} from "@/helper/chat/assistantFeatureMapping";
import {ChatStatus} from "@/helper/chat/chatStatus";
import {consoleErrorChat, consoleLogChat} from "@/helper/console";
import {kapitelErrorHandler} from "@/helper/error";
import {ThreadMessage} from "@/helper/chat/threadMessage";

import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import useEmitter, {useGlobalEmitter} from "@/helper/emitter";

import {RichContent} from "@/helper/chat/richResponses";
import {kapitelDateTimeString} from "@/graphql/kapitelTypes";
import datetime from "@/helper/datetime/datetime";
import {useAuthStore} from "@/store/auth";
import {useCandidateStore} from "@/store/candidate";
import {arrayContentEquals} from "@/helper/array";
import {ScriptedMessage, scriptedMessages} from "@/helper/chat/scriptedMessage";
import {scriptedAInesRequests, setScriptedAInesRequests} from "@/helper/chat/ainesRequests";
import {computed, Ref, ref} from "vue";
import {useAppState} from "@/helper/appState";
import { useAInesObjectiveStore } from "@/helper/chat/ainesObjectives";


const allowConcurrencyLockoutOverride = true


export const appendMessage = async (
    message: string,
    messageExpertAssistant?: AInesAssistantType,
    role: 'user' | 'assistant' = 'user',
    isScriptedContent: boolean = true
): Promise<void> => {
    const chatStore = useChatStore()

    if (!availableExpertListRequiresChooser() && !messageExpertAssistant) {
        messageExpertAssistant = availableExpertList[0]
        consoleLogChat('defaulting expert assistant to %o', messageExpertAssistant)
    }

    if (!messageExpertAssistant) {
        throw new Error("no expert provided")
    }

    const expertRunConfig = await getExpertAssistantRunConfig(messageExpertAssistant);

    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        role
    ))

    addMessage(message, expertRunConfig.threadId, 'user').then(
        () => makeMessagePersistParams({
            config: expertRunConfig,
            role,
            content: message,
            isScriptedContent
        }).then(persistMessage)
    )
}

window.addEventListener("unhandledrejection", (event) => {
    const chatStore = useChatStore()

    // if (event.reason instanceof OpenAIError) {
    if (event.reason.constructor.name.includes('APIError') && event.reason.message.includes('You can retry your request')) {
        if (chatStore.currentExpertRun) {
            chatStore.currentExpertRun.onUnhandledStreamError(event.reason)
        }
        if (chatStore.currentChooserRun) {
            chatStore.currentChooserRun.onUnhandledStreamError(event.reason)
        }
    } else {
        // unhandled promise rejection
        console.warn('unhandledrejection %o', event)
    }
});


export const sendUserMessage = async (
    message: string,
    {
        messageExpertAssistant = undefined,
        prependedAssistantMessage = undefined,
        prependExpertAssistant = undefined,
        richContentsPreview = undefined,
        isScriptedContent = undefined
    }: {
        messageExpertAssistant?: AInesAssistantType,
        prependedAssistantMessage?: string,
        prependExpertAssistant?: AInesAssistantType,
        richContentsPreview?: RichContent[],
        isScriptedContent?: boolean
    } = {}
): Promise<void> => {
    if (!setUpAssistantDone.value) {
        throw new Error("not yet setUpAssistantDone")
    }

    const chatStore = useChatStore()

    if(chatStore.isFirstContact){
        chatStore.setIsFirstContact(false)
    }

    // concurrency lockout
    if (chatStore.pingPongProcessing) {
        if (allowConcurrencyLockoutOverride) {
            consoleErrorChat("hard resetting chatState to initial state despite currently pending run %o %o", chatStore.chatStatus, chatStore.currentExpertRun)
            resetConversation()
        } else {
            consoleErrorChat('concurrency lockout active')
            kapitelErrorHandler('concurrency lockout active')
            return
        }
    }

    // reset to init
    chatStore.setChatStatus(ChatStatus.READY)
    // if this failed
    if (ChatStatus.READY !== chatStore.chatStatus) {
        consoleErrorChat("hard resetting chatState to initial state")
        resetConversation()
    }

    chatStore.setPingPongProcessing(true)

    // preserve previousExpertRun before resetting
    const previousExpertRun = chatStore.currentExpertRun as AssistantRunExpert || undefined
    chatStore.resetCurrentExpertRun()

    chatStore.newPingPong()

    // set state to "expecting"
    chatStore.setChatStatus(ChatStatus.EXPECTING_NEXT_RESPONSE)


    // preview handling
    if (richContentsPreview) {
        chatStore.setCurrentExpertRunRichContentsPreview(richContentsPreview)
    }

    // choose only available expert to prevent chooser usage
    if (!availableExpertListRequiresChooser() && !messageExpertAssistant) {
        messageExpertAssistant = availableExpertList[0]
        consoleLogChat('defaulting expert assistant to %o', messageExpertAssistant)
    }

    // ensure chosen expert is currently available
    if (messageExpertAssistant && !availableExpertList.includes(messageExpertAssistant)) {
        consoleErrorChat('invalid expert assistant %o', messageExpertAssistant)
        kapitelErrorHandler('invalid expert assistant ' + messageExpertAssistant)
        resetConversation()
        return
    }


    // prepend role=assistant messages to thread
    if (prependedAssistantMessage) {
        if (!prependExpertAssistant) {
            prependExpertAssistant = messageExpertAssistant
        }
        if (!prependExpertAssistant) {
            chatStore.setPingPongProcessing(false)
            throw Error("target assistant needed.")
        }

        // ensure chosen expert is currently available
        if (prependExpertAssistant && !availableExpertList.includes(prependExpertAssistant)) {
            consoleErrorChat('invalid expert assistant %o', prependExpertAssistant)
            kapitelErrorHandler('invalid expert assistant ' + prependExpertAssistant)
            resetConversation()
            return
        }

        consoleLogChat('prepending "%s" to %o', prependedAssistantMessage, prependExpertAssistant)

        chatStore.setChatStatus(ChatStatus.PREPENDING_ASSISTANT_MESSAGE)

        await appendMessage(prependedAssistantMessage, prependExpertAssistant, 'assistant')
    }


    consoleLogChat('sending "%s" to %o', message, messageExpertAssistant ? AInesAssistantType[messageExpertAssistant] : 'unknown expert')

    // collect user message
    chatStore.addToMessageHistory(new ThreadMessage(
        message,
        'user'
    ))

    // determine next expert run
    let nextExpertRun: AssistantRunExpert | undefined;

    try {
        // no target assistant given? ask chooser.
        if (!messageExpertAssistant) {
            chatStore.setChatStatus(ChatStatus.CHOOSER_PENDING);
            [messageExpertAssistant, nextExpertRun] = await _sendUserMessageToChooser(message, isScriptedContent ?? false, previousExpertRun)
        } else if (availableExpertListRequiresChooser()) {
            // at least inform chooser assistant about the message for subsequent chooser questions
            const chooserConfig = await getCurrentChooserRunConfig()

            addMessage(message, chooserConfig.threadId, 'user').then(() => {
                makeMessagePersistParams({
                    config: chooserConfig,
                    role: "user",
                    content: message,
                }).then(persistMessage)
            })

        }

        // target assistant known? send to expert
        if (!nextExpertRun && messageExpertAssistant) {
            nextExpertRun = await _sendUserMessageToTargetAssistant(message, isScriptedContent ?? false, messageExpertAssistant, previousExpertRun)
        }
    } catch (e: unknown) {
        kapitelErrorHandler(e as Error)
    } finally {
        // release concurrency lockout
        chatStore.setPingPongProcessing(false)
    }

    if (!nextExpertRun) {
        chatStore.setChatStatus(ChatStatus.FAILED)
        return
    }

    chatStore.setCurrentExpertRun(nextExpertRun)
}
// message sender requires choosing of target assistant by chooserAssistant
const _sendUserMessageToChooser = async (
    message: string,
    isScriptedContent: boolean,
    previousExpertRun: AssistantRunExpert | undefined
): Promise<[AInesAssistantType | undefined, AssistantRunExpert | undefined]> => {

    // kick off targetExpertAssistant chooser
    consoleLogChat('choosing expertAssistant')
    const chooserPromise = _chooseExpertAssistant(message, isScriptedContent)

    // potential shortcut: check for existing expert and optimistically send message there as well
    if (previousExpertRun) {
        consoleLogChat('send message to previous expertAssistant as well')

        const previousExpertReuseConfirmedPromise = chooserPromise.then((targetExpertAssistant): boolean => previousExpertRun.runConfig.assistantType === targetExpertAssistant)

        const expertRunWithReusedExpert = await _sendUserMessageToTargetAssistant(
            message,
            isScriptedContent,
            previousExpertRun.runConfig.assistantType,
            previousExpertRun,
            previousExpertReuseConfirmedPromise
        )

        // wait for confirmation of previous expert and return (already running) run
        // otherwise continue with chosen expert and a new run like that shortcut never happened
        if (await previousExpertReuseConfirmedPromise) {
            return [previousExpertRun.runConfig.assistantType, expertRunWithReusedExpert]
        }
    }

    // wait for target assistant choice
    const targetExpertAssistant = await chooserPromise
    if (!targetExpertAssistant) {
        return [undefined, undefined]
    }

    return [targetExpertAssistant, undefined]
}
// message sender defines target assistant: no chooser needed
const _sendUserMessageToTargetAssistant = async (
    message: string,
    isScriptedContent: boolean,
    targetExpertAssistant: AInesAssistantType,
    previousExpertRun: AssistantRunExpert | undefined,
    optimisticExpertConfirmedPromise: Promise<boolean> | undefined = undefined
): Promise<AssistantRunExpert> => {

    const expertRunConfig = await getExpertAssistantRunConfig(targetExpertAssistant);

    if (previousExpertRun && previousExpertRun.runConfig.assistantType !== targetExpertAssistant) {
        consoleLogChat('switched expertAssistant runConfig from previous config %O to %O', previousExpertRun.runConfig, expertRunConfig)
    }

    return new AssistantRunExpert(expertRunConfig, message, isScriptedContent, optimisticExpertConfirmedPromise)
}

const _chooseExpertAssistant = async (message: string, isScriptedContent: boolean): Promise<AInesAssistantType | undefined> => {
    const metaAssistantRunConfig = await getCurrentChooserRunConfig()

    const metaRun = new AssistantRunChooser(metaAssistantRunConfig, message, isScriptedContent)

    useChatStore().setCurrentChooserRun(metaRun)

    const assistant = await metaRun.assistantChoicePromise

    useChatStore().setCurrentChooserRun(undefined)

    if (!assistant) {
        consoleErrorChat('chooser failed to choose expertAssistant: %O', metaRun.response)
        return undefined
        // throw new Error('chooser failed to choose expertAssistant')
    }

    consoleLogChat('choose expertAssistant: ' + assistant)
    return assistant
}


///////// scripted message /////

export const presentScriptedMessage = (message: ScriptedMessage) => {
    const chatStore = useChatStore();


    chatStore.resetCurrentExpertRun()

    chatStore.setScriptedMessage(message);
}


///////// available experts ////

let availableExpertList = [] as AInesAssistantType[]

const determineAvailableExperts = async (isCandidate: boolean, isPromoted?: boolean): Promise<AInesAssistantType[]> => {
    return await getAvailableAssistants()
}

// return whether sth changed
const refreshAvailableExpertList = async () : Promise<boolean> => {
    // define configuration params
    let newList = await determineAvailableExperts(useAppState().isEmployeeOrCandidate('candidate'), useCandidateStore().isPromoted)

    if (arrayContentEquals(availableExpertList, newList, true)) {
        return false
    }

    consoleLogChat('updated available experts list %o -> %o', availableExpertList, newList)
    const removedExperts = availableExpertList.filter(t => !newList.includes(t))
    removedExperts.forEach(discardExpert)
    availableExpertList = newList
    return true
}

const availableExpertListRequiresChooser = () => availableExpertList.length > 1


////// expert configs //////

const currentExpertRunConfigs = new Map<AInesAssistantType, Promise<AssistantRunConfig>>

// drop all prepared experts
const discardExperts = () => currentExpertRunConfigs.clear()

// drop prepared expert
const discardExpert = (expert : AInesAssistantType) => currentExpertRunConfigs.delete(expert)

// prepare list of experts
const warmUpExperts = (experts : AInesAssistantType[]): Promise<AssistantRunConfig[]> => {
    const expertConfigPromises = experts.map((e) => warmUpExpert(e))
    return Promise.all(expertConfigPromises)
}

// re-prepare all prepared assistants (and threads if needed) with fresh RAGs and assistant prompts
const reheatExperts = async (keepThreads = false) => {
    const currentConfigPromises = currentExpertRunConfigs.values().toArray()

    const newConfigPromises = currentConfigPromises.map(async (existingConfigPromise: Promise<AssistantRunConfig>) => {
        const existingConfig = await existingConfigPromise
        return warmUpExpert(
            existingConfig.assistantType,
            keepThreads ? existingConfig.threadId : undefined,
            true
        )
    })

    return Promise.all(newConfigPromises)
}

// prepare expert (on existing thread) (overwriting existing prepared expert)
const warmUpExpert = async (assistantType: AInesAssistantType, threadId?:string, overwrite = false): Promise<AssistantRunConfig> => {
    if (!availableExpertList.includes(assistantType)) {
        throw new Error("invalid expert for current configuration: " + assistantType)
    }
    if (!currentExpertRunConfigs.has(assistantType) || overwrite) {
        currentExpertRunConfigs.set(assistantType, generateNewExpertRunConfig(assistantType, threadId))
    }
    return currentExpertRunConfigs.get(assistantType) as Promise<AssistantRunConfig>
}

const generateNewExpertRunConfig = async (assistantType: AInesAssistantType, threadId?: string): Promise<AssistantRunConfig> => {
    consoleLogChat("generating new expert assistant" + (threadId ? ' on existing thread' : ' & new thread') + " for %o", assistantType)
    return Promise
        .all([
            createAssistant(assistantType),
            threadId ? Promise.resolve(threadId) : createThread()
        ])
        .then((v) => {
            const [assistantId, threadId] = v
            return new AssistantRunConfig(
                assistantType,
                assistantId,
                threadId
            )
        })
}

const getExpertAssistantRunConfig = async (targetExpertAssistant: AInesAssistantType): Promise<AssistantRunConfig> =>
    await (currentExpertRunConfigs.get(targetExpertAssistant) || warmUpExpert(targetExpertAssistant))



/////// Chooser ///////

let currentChooserRunConfig: Promise<AssistantRunConfig> | undefined = undefined;

interface ChooserTTL {
    pingTS: kapitelDateTimeString | undefined;
    inactiveTS: kapitelDateTimeString | undefined;
    initTS: kapitelDateTimeString | undefined;
    resetInactivityMinutes: number;
}

const chooserTTL: ChooserTTL = {
    pingTS: undefined,
    inactiveTS: undefined,
    initTS: undefined,
    resetInactivityMinutes: 5
}

const discardCurrentChooser = () => {
    currentChooserRunConfig = undefined
}
const warmUpChooser = async (): Promise<AssistantRunConfig> => {
    if (!currentChooserRunConfig) {
        currentChooserRunConfig = generateNewChooserRunConfig()
    }
    return currentChooserRunConfig
}
const generateNewChooserRunConfig = async (): Promise<AssistantRunConfig> => {
    consoleLogChat("generating new chooser assistant & thread")
    return Promise
        .all([
            createAssistant(AInesAssistantType.Meta),
            createThread(),
        ])
        .then((v) => {
            const [assistantId, threadId,] = v

            chooserTTL.initTS = datetime.getNow();

            return new AssistantRunConfig(
                AInesAssistantType.Meta,
                assistantId,
                threadId,
            )
        })
}
const getCurrentChooserRunConfig = async (): Promise<AssistantRunConfig> => {
    return (currentChooserRunConfig || warmUpChooser())
}


/////// Message Persist ///////

export const makeMessagePersistParams = async (
    {
        config,
        runId = undefined,
        role = "assistant",
        content = "",
        toolCalls = [],
        toolCallId = undefined,
        richResponses = [],
        isScriptedContent = false
    }: {
        config: AssistantRunConfig,
        runId?: string | undefined,
        role?: "assistant" | "user" | "tool",
        content?: string,
        toolCalls?: any,
        toolCallId?: string | undefined,
        richResponses?: any,
        isScriptedContent?: boolean,
    }): Promise<MessagePersistParameters> => {
    if (content.length == 0 && toolCalls.length == 0) {
        console.warn("Either content or toolCalls should probably be set.")
    }
    return {
        assistant: config.assistantId,
        assistantType: config.assistantType,
        sessionId: useChatStore().sessionId,
        threadId: config.threadId,
        pingPongId: useChatStore().pingPongId,
        runId: runId,
        role: role,
        content: content,
        toolCalls: toolCalls,
        toolCallId: toolCallId,
        richResponses: richResponses,
        isScriptedContent: isScriptedContent,
        isVoiceMode: useChatStore().isVoiceMode
    };
};


////// API KEY /////

let apiKeyPromise: Promise<string> | undefined = undefined

const warmUpApiKey = async () => {
    if (!apiKeyPromise) {
        apiKeyPromise = getApiKey()
    }
    return apiKeyPromise
}

export const getOpenAIApiKey = async () => await warmUpApiKey()


////// Warmup ////

const initialized : Ref<false|'pending'|true> = ref(false)
export const setUpAssistantDone = computed(() => initialized.value === true)
export const setUpAssistant = async () => {
    initialized.value = 'pending'

    // trigger "update" event for sentry
    useChatStore().resetSession()

    await reheatAssistantFeatureMapping()

    await refreshAvailableExpertList()

    // Initialize objectives
    const aInesObjectiveStore = useAInesObjectiveStore();
    await aInesObjectiveStore.refetch();

    // warm up items:
    const warmUpPromises = [
        warmUpExperts(availableExpertList)
    ] as Promise<any>[];

    if (availableExpertListRequiresChooser()) {
        // dont expect chooser if only one expert
        warmUpPromises.push(warmUpChooser())
    }

    const chatStore = useChatStore()
    const authStore = useAuthStore()
    if(useAppState().isEmployeeOrCandidate('candidate')) {
        setScriptedAInesRequests(scriptedAInesRequests.candidate)
    }
    if(useAppState().isEmployeeOrCandidate('candidate') && chatStore.isFirstContact){
        presentScriptedMessage(scriptedMessages.firstCandidateContact)
    }

    initialized.value = true

    return Promise.all(warmUpPromises)
}


useGlobalEmitter().on("AppStateChanged", ({state, impersonationUserSwitch}) => {
    if (initialized.value !== true) {
        return;
    }

    if (state === 'employee' && impersonationUserSwitch) {
        resetConversation()
    }
})

const shouldResetAssistantByInactivity = (lastActivityTs: kapitelDateTimeString): boolean => {
    if (!chooserTTL.initTS) {
        return false
    }

    const now = datetime.getNow()

    const resetPIT = datetime.addMinutes(
        lastActivityTs,
        chooserTTL.resetInactivityMinutes
    )

    return datetime.isAfter(now, resetPIT)

}


const shouldResetAssistant = () => {
    if (chooserTTL.initTS) {
        if (chooserTTL.inactiveTS) {
            // we have been inactve -> look at time since inactivity
            if (shouldResetAssistantByInactivity(chooserTTL.inactiveTS)) {
                consoleLogChat("reset assistant because of long inactivity")
                chooserTTL.inactiveTS = undefined
                return true
            }
        } else if (chooserTTL.pingTS) {
            // we have not been inactive -> look at time since last ping
            if (shouldResetAssistantByInactivity(chooserTTL.pingTS)) {
                consoleLogChat("reset assistant because of long inactivity")

                return true
            }
        }
    }
    consoleLogChat("no need  to reset:", chooserTTL)
    return false
}

export const resetConversation = async () => {
    const chatStore = useChatStore()

    useAInesObjectiveStore().deselectObjective()

    chatStore.resetTtsObjects()
    chatStore.setTtsStatus('silent')
    chatStore.setPingPongProcessing(false)
    chatStore.setChatStatus(ChatStatus.READY, true)
    chatStore.resetCurrentExpertRun()
    chatStore.setCurrentChooserRun(undefined)
    chatStore.resetMessageHistory()
    chatStore.setTranscribeStatus('ready')
    chatStore.resetSession()

    discardCurrentChooser()
    discardExperts()

    return setUpAssistant()
}

// similar to resetConversation but skipping all transition animations
export const reloadChat = async () => {
    const router = await import('@/router')
    router.default.go(0)
}

const emitter = useEmitter()

emitter.on('CapacitorAppStateChange:active', () => {
    consoleLogChat("active")
    if (shouldResetAssistant()) {
        // resetConversation()
        reloadChat()
    }
})

emitter.on('CapacitorAppStateChange:inactive', () => {
    consoleLogChat("inactive")
    if (chooserTTL.initTS) {
        chooserTTL.inactiveTS = datetime.getNow()
    }
})

emitter.on("SendPing", () => {
    if (chooserTTL.initTS) {
        chooserTTL.pingTS = datetime.getNow()
    }
})


////// external triggers ///

const onCandidateChange = async () => {
    consoleLogChat("candidate expert update");
    await Promise.all([
        useCandidateStore().reloadCandidate(),
        reheatExperts(true),
        reheatAssistantFeatureMapping()
    ])
}

export const onCandidatePromotion = async () => onCandidateChange()

export const onCandidateInterestedInSet = async () => onCandidateChange()
