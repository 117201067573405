<template>
    <span>
        <strong>{{ booking.shiftType }}{{ props.shift }}</strong>
        am {{
            booking.date
                ? date.formatKapitelDate(booking.date, 'd.L.')
                : date.formatKapitelDate(datetime.convertDateTime2Date(booking.begin), 'd.L.')
        }}
        von {{datetime.convertDateTime2Time(booking.begin)}} - {{datetime.convertDateTime2Time(booking.until)}}
    </span>
</template>

<script setup lang="ts">
import date from "@/helper/datetime/date";
import datetime from "@/helper/datetime/datetime";
import {Booking, ShiftType} from "@/graphql/generated/graphql";

const props = defineProps<{
        booking: Booking
        shift: ShiftType
    }>()

</script>

<style scoped>
    ion-grid {
        padding: 0;
    }
</style>
