

export const pluralize = (amount: number, singular: string, plural: string, zeroAmountOverride : string|undefined = undefined): string =>{
  if(amount===1){
    return amount+" "+singular
  }

  if (amount===0 && zeroAmountOverride !== undefined) {
    return zeroAmountOverride+" "+plural
  }

  return amount+" "+plural
}

export const pluralizeUnitOnly = (amount: number, singular: string, plural: string): string =>{
  if(amount===1){
    return singular
  }
  return plural
}

export const listFormat = (items: string[], options : Intl.ListFormatOptions = {style: 'long', type:'conjunction'}): string => {
    // use localized formatter to get "a, b und c"
    const formatter = new Intl.ListFormat('de', options);

    return formatter.format(items)
}

export const floatFormat = (amount: number, fractionalDigits = 1): string => {
    // return amount.toFixed(1)
    var power = Math.pow(10, fractionalDigits);
    return String(Math.round(amount * power) / power);
}
