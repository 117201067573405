import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AutopilotJobsiteSource,
    AutopilotPlayState,
    AutopilotStatus,
    Employee,
    PlanningStatus,
    WorktimeMode
} from "@/graphql/generated/graphql";
import date from "@/helper/datetime/date";
import {autopilotPreferenceFragment, getIconForPlayState, getIconForWorktimeMode,} from "@/helper/autopilotPreference";
import {CalendarMonth} from "@/helper/calendar/calendarMonth";
import {clientFragment, houseFragment, jobsiteFragment, splitByJobsiteCategory} from "./jobsite";

export const autopilotStatusFragmentWithStatistics = gql`fragment AutopilotStatusFragmentWithStatistics on AutopilotStatus {
    month,
    employee {
        id
    },
    contractHours,
    absenceHours,
    worktimeMode,
    worktimeModeModifierHours,
    bookingTargetHours,
    bookingTarget,
    booked,
    bookedWorkingHours,
    bookingHoursTargetReached,
    modus,
    offerStrategy,
    missingBookings,
    averageShiftDuration,
    avgDemandPerMonth {
        jobsite {
            id,
            name
            abbreviation,
            client {
                name,
                abbreviation
            }
            house {
                name,
                abbreviation
            }
        }
        averageMonthlyDemand
        currentPrediction
    },
    predictedAdditionalBookings,
    shiftPortfolio
}`


export const autopilotStatusFragment = gql`fragment AutopilotStatusFragment on AutopilotStatus {
    month,
    employee {
        id
    },
    contractHours,
    absenceHours,
    worktimeMode,
    worktimeModeModifierHours,
    bookingTargetHours,
    bookingTarget,
    booked,
    bookedWorkingHours,
    bookingHoursTargetReached,
    modus,
    offerStrategy,
    missingBookings,
    averageShiftDuration,
}`

export const planningStatusFragment = gql`

fragment PlanningStatusFragment on PlanningStatus {
    month
    employee {
        id
    }
    planningMode
    bookingCompleted

    contractHours

    bookings
    bookedJobsites {
        ...JobsiteFragment    
        client {
            ...ClientFragment
        }
        house {
            ...HouseFragment
        }    
    }

    availabilities
    availabilityShifts

    vacations
}`



/**
 * plural
 *
 * @param sorted
 */
export async function fetchCurrentAutopilotStatus(sorted = true): Promise<AutopilotStatus[]> {
    const result = await query(gql`
        ${clientFragment}
        ${houseFragment}
        ${autopilotPreferenceFragment}
        ${autopilotStatusFragment}
        query getCurrentAutopilotStatus {
            currentAutopilotStatus {
                ...AutopilotStatusFragment
                autopilotPreferences {
                    ...AutopilotPreferenceFragment
                }
            }
        }`
    )

    const autopilotStatus = result?.data?.currentAutopilotStatus || []

    return sorted
        ? autopilotStatus.sort(sorter)
        : autopilotStatus
}


/**
 * singular
 */
export async function fetchAutopilotStatus(month: CalendarMonth, employee: Employee | undefined): Promise<AutopilotStatus> {
    const result = await query(gql`
        ${autopilotPreferenceFragment}
        ${autopilotStatusFragment}
        ${jobsiteFragment}
        ${clientFragment}
        ${houseFragment}
        query getAutopilotStatus($month: KapitelDateImmutable!, $employeeId: Int = null) {
            autopilotStatus(month: $month, employeeId: $employeeId) {
                ...AutopilotStatusFragment
                autopilotPreferences {
                    ...AutopilotPreferenceFragment
                }
            }
        }`,{
            month: month.begin,
            employeeId: employee?.id || undefined,
        }
    )

    return result?.data?.autopilotStatus

}

export async function fetchAutopilotStatusWithStats(month: CalendarMonth, employee: Employee | undefined): Promise<AutopilotStatus> {
    const result = await query(gql`
            ${autopilotPreferenceFragment}
            ${autopilotStatusFragmentWithStatistics}
            ${jobsiteFragment}
            ${clientFragment}
            ${houseFragment}
            query getAutopilotStatus($month: KapitelDateImmutable!, $employeeId: Int = null) {
                autopilotStatus(month: $month, employeeId: $employeeId) {
                    ...AutopilotStatusFragmentWithStatistics
                    autopilotPreferences {
                        ...AutopilotPreferenceFragment
                    }
                }
            }`,{
            month: month.begin,
            employeeId: employee?.id || undefined,
        }
    )

    return result?.data?.autopilotStatus

}

export async function fetchPlanningStatus(month: CalendarMonth, employee: Employee | undefined = undefined ): Promise<PlanningStatus> {
    const result = await query(gql`
            ${autopilotPreferenceFragment}
            ${autopilotStatusFragment}
            ${planningStatusFragment}
            ${clientFragment}
            ${houseFragment}
            query getPlanningStatus($month: KapitelDateImmutable!, $employeeId: Int = null) {
                planningStatus(month: $month, employeeId: $employeeId, useStuffing: false) {
                    ...PlanningStatusFragment
                    autopilotStatus {
                        ...AutopilotStatusFragment
                        autopilotPreferences {
                            ...AutopilotPreferenceFragment
                        }
                    }
                }
            }`,{
            month: month.begin,
            employeeId: employee?.id || undefined,
        }
    )

    return result?.data?.planningStatus

}

export function sorter(a : AutopilotStatus, b : AutopilotStatus) {
    return date.cmpKapitelDate(a.month, b.month)
}

export function getAutopilotWorktimeModeIcon(status: AutopilotStatus) {
    return getIconForWorktimeMode(status.worktimeMode as string)
}

export function getAutopilotWorktimeModeLabel(worktimeMode: WorktimeMode) {
    switch (worktimeMode) {
        case WorktimeMode.Less:
            return 'Weniger arbeiten'
        case WorktimeMode.SpotOn:
            return 'Sollstunden erreichen'
        case WorktimeMode.More:
            return 'Überstunden ansammeln'
    }
}

export function getAutopilotStatusSummary(autopilotStatus: AutopilotStatus | undefined) {
    const autopilotPreference = autopilotStatus?.autopilotPreferences;

    const jobsites = autopilotPreference?.autopilotJobsites.map(apjs => apjs.jobsite) || [] 
    const {favorite, alternative, booked, other} = splitByJobsiteCategory(jobsites)

    const automaticallyAdded = autopilotPreference?.autopilotJobsites.filter(apjs => apjs.source == AutopilotJobsiteSource.Manually)
    const manuallyAdded = autopilotPreference?.autopilotJobsites.filter(apjs => apjs.source == AutopilotJobsiteSource.Favorite)

    const jobsiteSummary = {
        jobsites: jobsites,
        count: jobsites.length,

        favoriteCount: favorite.jobsites.length,
        favoriteOnly: jobsites.length === favorite.jobsites.length,
        alternativeCount: alternative.jobsites.length,
        remainingCount: booked.jobsites.length + other.jobsites.length,

        automaticallyAddedCount: automaticallyAdded?.length || 0,
        manuallyAddedCount: manuallyAdded?.length || 0
    }

    return {
        playState: autopilotPreference?.playState,
        playStateIcon: autopilotPreference ? getIconForPlayState(autopilotPreference?.playState) : undefined,
        statusIsRunning: autopilotPreference?.playState == AutopilotPlayState.Running,
        statusIsPaused: autopilotPreference?.playState == AutopilotPlayState.Paused,
        statusIsScrapped: autopilotPreference?.playState == AutopilotPlayState.Scrapped,
        jobsiteSummary,
        worktimeMode: autopilotStatus?.worktimeMode,
        worktimeIcon: autopilotStatus ? getAutopilotWorktimeModeIcon(autopilotStatus) : undefined,
        worktimeLabel: autopilotStatus ? getAutopilotWorktimeModeLabel(autopilotStatus.worktimeMode) : undefined,
        offerStrategy: autopilotStatus?.offerStrategy,
        absenceHours: autopilotStatus?.absenceHours,
        booked: autopilotStatus?.booked,
        bookedWorkingHours: autopilotStatus?.bookedWorkingHours,
        bookingHoursTargetReached: autopilotStatus?.bookingHoursTargetReached,

        bookingTarget: autopilotStatus?.bookingTarget,
        bookingTargetHours: autopilotStatus?.bookingTargetHours,
        contractHours: autopilotStatus?.contractHours,
        missingBookingHours: autopilotStatus?.missingBookingHours,
        missingBookings: autopilotStatus?.missingBookings,

        absencePlanningCount: autopilotPreference?.absenceDays
    }
}

