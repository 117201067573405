import { PushNotifications, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications'
import useEmitter from "@/helper/emitter";
import {Capacitor} from "@capacitor/core";
import { RouteParamsRaw } from 'vue-router';
import {consoleErrorApp, consoleLogApp} from "@/helper/console";


export interface NotificationType {
  title: string;
  body: string;
  view: string;
  props: RouteParamsRaw;
}



const emitter = useEmitter()

export const registerToPushNotifications = async (maxRetries = 3, delay = 2000) => {

    if (!isAvailable()) {
        throw new Error('Push notifications not available');
    }

    let permissionStatus = await PushNotifications.checkPermissions();

    if (permissionStatus.receive === 'prompt' || permissionStatus.receive === 'prompt-with-rationale') {
        permissionStatus = await PushNotifications.requestPermissions();
    }

    if (permissionStatus.receive !== 'granted') {
        throw new Error('Push notification permission not granted');
    }

    consoleLogApp("Start registering for push notifications");

    const registerWithRetry = async () => {
        let isDone = false;

        const promise =  new Promise((resolve, reject):void => {
            const timeout = setTimeout(() => {
                if(isDone)  return;
                isDone = true;
                reject(new Error('Registration event timeout'));
            }, delay);

            PushNotifications.addListener('registration', async (token) => {
                if(isDone)  return;
                clearTimeout(timeout);
                isDone = true;
                consoleLogApp('APNs device token:', token.value);
                resolve(true);
            });

            PushNotifications.addListener('registrationError', (error) => {
                if(isDone)  return;
                clearTimeout(timeout);
                isDone = true;
                consoleErrorApp('Registration error:', error);
                reject(error);
            });
            PushNotifications.register();
        });
        consoleLogApp("Push notification registration requested.");
        await promise;
    };

    let attempt = 0;
    let success = false;
    while (attempt <= maxRetries && !success) {
        attempt ++
        try {
            consoleLogApp(`Attempt ${attempt} to register for push notifications...`);
            await registerWithRetry()
            success = true;
            consoleLogApp(`Registration attempt ${attempt} succeeded.`);

        } catch (error){
            success = false;
            consoleLogApp(`Registration attempt ${attempt} failed. Retrying in ${delay}ms...`);
        }
    }
    if(!success) {
        consoleErrorApp('Max registration attempts reached. Registration failed.');
    }
};


const onPushNotificationActionPerformed = (notificationAction: ActionPerformed) => {
    const viewName = JSON.parse(notificationAction.notification.data.view)
    const viewProps = JSON.parse(notificationAction.notification.data.props)
    const title: string = notificationAction.notification.title ? notificationAction.notification.title : ""
    const body: string = notificationAction.notification.body ? notificationAction.notification.body : ""

    const routerParams = {
        dateId: viewProps.dateId
    }
    emitter.emit('pushNotification:actionPerformed', {
        title: title,
        body: body,
        view: viewName,
        props: routerParams
    })
}


const onNotificationReceived = (notification: PushNotificationSchema) => {
    const viewName = JSON.parse(notification.data.view)
    const viewProps = JSON.parse(notification.data.props)
    const title: string = notification.title ? notification.title : ""
    const body: string = notification.body ? notification.body : ""

    const routerParams = {
        dateId: viewProps.dateId
    }
    emitter.emit('pushNotification:received', {
        title: title,
        body: body,
        view: viewName,
        props: routerParams
    })
}

let initialized = false;
const init = () => {
    if (isAvailable()) {
        PushNotifications.addListener('pushNotificationActionPerformed', onPushNotificationActionPerformed);
        PushNotifications.addListener('pushNotificationReceived', onNotificationReceived);
    }
}

const isAvailable = () => {
    return Capacitor.isPluginAvailable('PushNotifications');
}

export default function usePushNotificationHelper() {
    if (!initialized) {
        init()
        initialized = true
    }

    return {
        isAvailable,
        registerToPushNotifications,
        onPushNotificationActionPerformed: (f:(notification:NotificationType)=>void) => emitter.on('pushNotification:actionPerformed', f),
        onNotificationReceived: (f:(notification:NotificationType)=>void) => emitter.on('pushNotification:received', f),
    };
}
