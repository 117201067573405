import useEmitter from "@/helper/emitter";
import {Ref, readonly, ref} from "vue";
import { Network } from '@capacitor/network';

const emitter = useEmitter()

const TIME_CONSTANTS = {
    pingRequestTimeout: 3000,
    checkIntervalOnline: 5000,
    checkIntervalOffline: 2000,   
}
const RETRY_CONSTANTS = {    
    retriesBeforeOnline: 0,
    retriesBeforeOffline: 1,
}

const pingURL = import.meta.env.VITE_PING_ENDPOINT as string;
const openaiStatusURL: string = 'https://status.openai.com/api/v2/summary.json';
const disablePolling = import.meta.env.VITE_DEBUG_DISABLE_POLLING === 'true';

let initialized = false;
const initialize = () => {
    // init optimistic external state
    setApplicationOnlineState(true)

    // trigger realistic check and kick off internal state tracking with debounced app state tracking    
    setAndMaintainInternalState()
        .then(() => {
            // on network status change re-check immediately
            Network.addListener('networkStatusChange', () => setAndMaintainInternalState())
            
            // on app activate re-check immediately 
            const emitter = useEmitter()
            emitter.on('CapacitorAppStateChange:active', () => setAndMaintainInternalState())
        })                      
}

/**
 * internal state
 */

let internalState = false;
const internalStateUpdating : Ref<undefined|Promise<boolean>>= ref(undefined);
let maintainTimeout:NodeJS.Timeout;
const setAndMaintainInternalState = () : Promise<boolean> => {
    if (internalStateUpdating.value) {
        return internalStateUpdating.value;
    }    

    internalStateUpdating.value = new Promise((resolve) => {
        if (maintainTimeout) {
            clearTimeout(maintainTimeout)
        }

        getCombinedOnlineState().then(isOnline => {

            internalState = isOnline
            
            debouncedSetApplicationOnlineState(internalState)
            
            // (re-)start check for changes in state        
            maintainTimeout = setTimeout(setAndMaintainInternalState, isOnline ? TIME_CONSTANTS.checkIntervalOnline : TIME_CONSTANTS.checkIntervalOffline)
            
            resolve(internalState)
            
            internalStateUpdating.value = undefined;
        })
    });

    return internalStateUpdating.value
}


/**
 * internal getter
 */
const getNavigatorOnlineState = async () => {
    const status = await Network.getStatus()
    return status.connected
}

const getServerOnlineState = async () => {
    const controller = new AbortController()
    useEmitter().emit("SendPing")
    const timeoutTimeout = setTimeout(() => { controller.abort() }, TIME_CONSTANTS.pingRequestTimeout)
    const resultPromise = fetch(pingURL, {signal: controller.signal})
        .then((response) => {            
            return response.status >= 200 && response.status <= 299    
        })
        .catch(() => false)        

    resultPromise.finally(() => {clearTimeout(timeoutTimeout)})
    
    return resultPromise    
}

const getOpenAIOnlineState = async () => {
    const controller = new AbortController()

    const timeoutTimeout = setTimeout(() => { controller.abort() }, TIME_CONSTANTS.pingRequestTimeout)
    const resultPromise = fetch(openaiStatusURL, {signal: controller.signal})
        .then(async (response)  => {
            const jsonResponse = await response.json()
            const responseOk: boolean =  response.status >= 200 && response.status <= 299
            const apiOperational: boolean = ["operational", "degraded_performance", "partial_outage"].indexOf(
                jsonResponse.components.find((it: any)=>it.name==="API").status
            ) > -1

            return responseOk && apiOperational
        })
        .catch(() => false)

    resultPromise.finally(() => {clearTimeout(timeoutTimeout)})

    return resultPromise
}

const getCombinedOnlineState = async () =>  {    
    return (
        await getNavigatorOnlineState() &&
        (disablePolling || await getServerOnlineState()) &&
        // (disablePolling || await getOpenAIOnlineState()) &&
        // @ts-ignore
        !window.DEBUG_OFFLINE
    )
}


/**
 * debounced internal state > application state 
 */

let debounceCounter = 0;

const debouncedSetApplicationOnlineState = (internalState:boolean) => {
    const applicationState = isApplicationOnline.value
    if (internalState === applicationState) {
        debounceCounter = 0;
        return
    } 
    
    debounceCounter++
    const counterThreshold = internalState ? RETRY_CONSTANTS.retriesBeforeOnline : RETRY_CONSTANTS.retriesBeforeOffline        
    if (debounceCounter > counterThreshold) {
        debounceCounter = 0;
        setApplicationOnlineState(internalState)
    }
}
const immediateSetApplicationOnlineState = (internalState:boolean) => {
    const applicationState = isApplicationOnline.value
    if (internalState === applicationState) {
        debounceCounter = 0;
        return
    } 

    debounceCounter = 0;
    setApplicationOnlineState(internalState)
}

/**
 * application facing state
 */
const isApplicationOffline = ref(false)
const isApplicationOnline = ref(false)

const setApplicationOnlineState = (state: boolean) => {
    if (state === isApplicationOnline.value) {
        return
    }
    isApplicationOffline.value = !state
    isApplicationOnline.value = state
    emitter.emit('offline:state:update')
    state ? emitter.emit('offline:state:online') : emitter.emit('offline:state:offline')
}

/**
 * helper init & exports
 */
export default function useOfflineHelper() {
    if (!initialized) {        
        initialize()
        initialized = true
    }

    return {
        checkNow: setAndMaintainInternalState,        
        checkImmediately: async () => {            
            immediateSetApplicationOnlineState(await setAndMaintainInternalState())
            return isApplicationOnline.value
        },
        isChecking: readonly(internalStateUpdating),
        isOnline: readonly(isApplicationOnline),
        isOffline: readonly(isApplicationOffline),
        onStateChange: (f:()=>void) => emitter.on('offline:state:update', f),
        onOnline: (f:()=>void) => emitter.on('offline:state:online', f),
        onOffline: (f:()=>void) => emitter.on('offline:state:offline', f)
    };
}
