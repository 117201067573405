import {query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {availabilityFragment} from "@/helper/availability";
import {absenceFragment} from "@/helper/absence";
import {bookingDetailFragment} from "@/helper/booking";
import {Absence, AbsenceType, Availability, Booking, Day} from "@/graphql/generated/graphql";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { clientFragment, houseFragment, jobsiteFragment } from "./jobsite";
import {computed} from "vue";
import {cmpKapitelDate, endOfMonth} from "@/helper/datetime/date";
import {CalendarMonth} from "@/helper/calendar/calendarMonth";

export const dayFragment = gql`
    ${availabilityFragment}
    ${absenceFragment}
    ${bookingDetailFragment}
    ${jobsiteFragment}
    ${clientFragment}
    ${houseFragment}
    fragment DayFragment on Day {
        date
        date
        availability {
            ...AvailabilityFragment
        }
        absence {
            ...AbsenceFragment
        }
        booking {
            ...BookingDetailFragment
        }
        demands {
            id
            begin
            until
            shiftType
            jobsite {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
            }
        }
        wishDemands {
            id
            begin
            until
            shiftType
            jobsite {
                ...JobsiteFragment
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
            }
        }
    }`


export async function fetchDayForDate(date: kapitelDateString, additionalFields:string = '') {
    const result = await query(
        gql`${dayFragment}
            query GetDay ($date: KapitelDateImmutable!) {
            day(
                date: $date
                useStuffing: false
            ) {
               ...DayFragment
               ${additionalFields}
            }
        }`,
        {
            date: date
        }
    )

    return result?.data?.day;
}

export type DayMap = Map<kapitelDateString, Day>
export function toDayMap(days: Day[], existingMap: DayMap | undefined = undefined): DayMap {
    const map = existingMap || new Map<kapitelDateString, Day>();
    days.forEach(day => map.set(day.date, day))
    return map;
}

export async function fetchDaysForMonth(month: CalendarMonth): Promise<Day[]>
{
    return fetchDaysForDateRange(month.begin, month.until);
}

export async function fetchDaysForDateRange(min: kapitelDateString, max: kapitelDateString, additionalFields:string = ''): Promise<Day[]>
{
    const result = await query(
        gql`${dayFragment}
            query GetDays (
                $min: KapitelDateImmutable!
                $max: KapitelDateImmutable!
            ) {
                days(betweenDates: {max: $max, min: $min}, useStuffing: false) {
                    ...DayFragment                    
                    ${additionalFields}
                }
            }`, {
                min: min,
                max: max
            }
    )

    const days = result?.data?.days;
    if (!days) {
        throw new Error("no days in days response")
    }

    return days
}

export class DayObject {
    constructor(
        public date: kapitelDateString,
        public booking: Booking|undefined,
        public absence: Absence|undefined,
        public availability: Availability|undefined,
    ) { }
}

export const objectify = (day: Day) : DayObject => new DayObject(
    day.date,
    day.booking || undefined,
    day.absence || undefined,
    day.availability || undefined
)

export async function fetchDayObjectsForDateRange(min: kapitelDateString, max: kapitelDateString): Promise<Array<DayObject>> {
    const days = await fetchDaysForDateRange(min, max)
    return days.map(objectify)
}

export const yieldEffectiveAvailabilities = (days: (Day|DayObject)[]): Availability[] => {
    return days.reduce((arr:Availability[], day) => {
        if (day.availability) {
            arr.push(day.availability)
        }
        return arr
    }, [])
}

export const yieldEffectiveAbsences = (days: (Day|DayObject)[]): Absence[] => {
    return days.reduce((arr:Absence[], day) => {
        if (day.absence) {
            arr.push(day.absence)
        }
        return arr
    }, [])
}

export const yieldEffectiveBookings = (days: (Day|DayObject)[]): Booking[] => {
    return days.reduce((arr:Booking[], day) => {
        if (day.booking) {
            arr.push(day.booking)
        }
        return arr
    }, [])
}

export function generateDay(
    date: kapitelDateString
): Day {
    return <Day>{
        date: date,
    }
}

export const sorter = (day1:Day, day2:Day) => cmpKapitelDate(day1.date, day2.date)
