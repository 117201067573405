<script setup lang="ts">
import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSelect,
    IonSelectOption
} from "@ionic/vue";
import {computed, defineExpose, PropType, Ref, ref, watch} from "vue";
import Modal from '@/components/Modal.vue';
import {DataLoader} from "@/helper/dataLoader";
import {useCandidateStore} from "@/store/candidate";
import {
    AInesEmployeeQualificationExperiencesType,
    AInesEmployeeQualificationSpecializationType,
    AInesEmployeeQualificationType,
    Candidate
} from "@/graphql/generated/graphql";
import AInesMessage from "@/views/Chat/AInesMessage.vue";
import {useChatStore} from "@/helper/chat/chatStore";

const emit = defineEmits(['submit']);

const modelValue = defineModel({type: Boolean});
const isOpen = ref(modelValue.value);
const modal = ref();

watch(() => modelValue.value, value => isOpen.value = value);

watch(() => isOpen.value, value => {
    modelValue.value = value;
});

const props = defineProps({
    useLastChatMessageAsIntroMessage: {
        type: Boolean as PropType<Boolean>,
        required: false,
        default: true
    },
})

const defaultIntroMessage = 'Um dir uneingeschränkt Zusatzdienste vorschlagen zu können brauche ich ein paar Angaben von dir:'
const chatStore = useChatStore();
const introMessage = computed(() => {
    let assistantMessage
    if (chatStore.currentExpertRunMessageTextStreaming) {
        assistantMessage = chatStore.currentExpertRunMessageTextStreaming
    } else if (chatStore.scriptedMessage) {
        assistantMessage = chatStore.scriptedMessage.textContent
    }

    return props.useLastChatMessageAsIntroMessage && assistantMessage ? assistantMessage : defaultIntroMessage
})

// local models
const fullName = ref('');
const phoneNumber = ref('');
const wantsEmployment = ref(false);
const acknowledgesContract = ref(false);
const qualification = ref<AInesEmployeeQualificationType | ''>('');
const specialization = ref('');
const experiences = ref<string[]>([]);

// Get the candidate entity
const candidate : Ref<Candidate|undefined> = ref(undefined);
const dateLoader = new DataLoader(async _ => candidate.value = await useCandidateStore().ensureCandidate())

// Dynamic qualification options from the enum
const qualificationOptions = computed(() => Object.values(AInesEmployeeQualificationType).filter(value => value !== "None" ) as AInesEmployeeQualificationType[]);

const init = async () => {
    await dateLoader.load()

    // Initialize form fields with candidate data
    fullName.value = candidate.value?.name || ''
    phoneNumber.value = candidate.value?.contact || ''
    qualification.value = (candidate.value?.employeeQualification?.qualification as AInesEmployeeQualificationType) || ''
    specialization.value = candidate.value?.employeeQualification?.specialisation || ''
    experiences.value = candidate.value?.employeeQualification?.experiences || []
}

const formIsValid = computed(() => {
    return fullName.value.trim() !== '' &&
        phoneNumber.value.trim() !== '' &&
        acknowledgesContract.value &&
        qualification.value !== '' &&
        wantsEmployment.value &&
        acknowledgesContract.value
});

const onCancel = () => {
    isOpen.value = false;
    resetForm();
};

const onSubmit = () => {
    emit('submit', {
        fullName: fullName.value,
        phoneNumber: phoneNumber.value,
        wantsEmployment: wantsEmployment.value,
        acknowledgesContract: acknowledgesContract.value,
        qualification: qualification.value,
        specialization: qualification.value === AInesEmployeeQualificationType.FachGkp ? specialization.value : '',
        experiences: qualification.value === AInesEmployeeQualificationType.Gkp ? experiences.value : [],
    });
    isOpen.value = false;
    resetForm();
};

const resetForm = () => {
    fullName.value = '';
    phoneNumber.value = '';
    wantsEmployment.value = false;
    acknowledgesContract.value = false;
    qualification.value = '';
    specialization.value = '';
    experiences.value = [];
};

// Options for specialization and experiences
const specializationOptions = Object.values(AInesEmployeeQualificationSpecializationType) // ['ITS', 'OP'];
const experiencesOptions = Object.values(AInesEmployeeQualificationExperiencesType) // ['ITS', 'OP'];

defineExpose({
    init
});
init()
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Anmelden für Dienstauswahl"
        class="no-padding-left no-padding-right no-padding-bottom"
        ref="modal"
        fullscreen
        :toolbar="true"
    >
        <template v-slot:default>
            <ion-content>
                <AInesMessage :text-content="introMessage" />

                <ion-list class="boxed no-padding-top">
                    <!-- Input field for Full Name -->
                    <ion-item>
                        <ion-label position="stacked">Vollständiger Name</ion-label>
                        <ion-input v-model="fullName" placeholder="Geben Sie Ihren vollständigen Namen ein" aria-label="Vollständiger Name"></ion-input>
                    </ion-item>
                    <!-- Input field for Phone Number -->
                    <ion-item>
                        <ion-label position="stacked">Telefonnummer</ion-label>
                        <ion-input v-model="phoneNumber" type="tel" placeholder="Geben Sie Ihre Telefonnummer ein" aria-label="Telefonnummer"></ion-input>
                    </ion-item>
                    <!-- Select field for Qualification -->
                    <ion-item>
                        <ion-label position="stacked">Ausbildungsqualifikation</ion-label>
                        <ion-select v-model="qualification" placeholder="Wählen Sie Ihre Qualifikation" aria-label="Qualifikation" interface="action-sheet">
                            <ion-select-option
                                v-for="option in qualificationOptions"
                                :key="option"
                                :value="option"
                            >
                                {{ option }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>

                    <!-- Select field for Specialization (shown only if 'FachGKP' is selected) -->
                    <ion-item v-if="qualification === AInesEmployeeQualificationType.FachGkp">
                        <ion-label position="stacked">Spezialisierung</ion-label>
                        <ion-select v-model="specialization" placeholder="Wählen Sie Ihre Spezialisierung" aria-label="Spezialisierung" interface="action-sheet">
                            <ion-select-option v-for="option in specializationOptions" :key="option" :value="option">
                                {{ option }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <!-- Multiple Select field for Experiences (shown only if 'GKP' is selected) -->
                    <ion-item v-if="qualification === AInesEmployeeQualificationType.Gkp">
                        <ion-label position="stacked">Erfahrungen</ion-label>
                        <ion-select v-model="experiences" placeholder="Wählen Sie Ihre Erfahrungen" :multiple="true" aria-label="Erfahrungen">
                            <ion-select-option v-for="option in experiencesOptions" :key="option" :value="option">
                                {{ option }}
                            </ion-select-option>
                        </ion-select>
                    </ion-item>
                    <div style="height: 1.5em;" />
                    <!-- Checkbox for Employment Start -->
                    <ion-item lines="none">
                        <ion-checkbox slot="start" label-placement="end" v-model="wantsEmployment" aria-label="Ich interessiere mich für eine Beschäftigung bei der IBFG e.V." >
                            <ion-label>
                                Ich interessiere mich für eine Beschäftigung bei der IBFG e.V.
                            </ion-label>
                        </ion-checkbox>
                    </ion-item>
                    <!-- Checkbox for Contract Acknowledgement -->
                    <ion-item lines="none">
                        <ion-checkbox slot="start" label-placement="end" v-model="acknowledgesContract" aria-label="Ich nehme zur Kenntnis, dass die Nutzung der angebotenen Funktionen an sich kein Arbeitsverhältnis begründen.">
                            <ion-label>Ich nehme zur Kenntnis, dass die Nutzung der angebotenen Funktionen an sich kein Arbeitsverhältnis begründen.</ion-label>
                        </ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-content>
        </template>
        <template v-slot:actions>
            <!-- Submit and Cancel Buttons -->
            <ion-button @click="onCancel" expand="block" color="secondary" fill="clear">Abbrechen</ion-button>
            <ion-button @click="onSubmit" expand="block" color="primary" :disabled="!formIsValid">Absenden</ion-button>
        </template>
    </Modal>
</template>

<style lang="scss">
.qualification-segment {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
    margin-bottom: 8px;
}
.jobsite-selector-modal-scroller {
    height: 50vh;
    overflow: auto;
}

.jobsite-selector-searchbar {
    padding-left: 0;
    padding-right: 0;
}

.jobsite-selector-actions {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}

.alreadySelected {
    opacity: 0.75;
}
</style>
