<template>
    <div
        class="calendar-list-item"
        @click="onDayClick"
        data-cy="Calendar-Day"
        v-bind:data-cy-date="kapitelDate"
    >
        <div
            class="date"
            :class="{'today': dateHelper.isToday(kapitelDate), 'weekend': dateHelper.isWeekend(kapitelDate) }"
        >
            <span>
                <strong>{{ dateHelper.getDayOfMonthFromKapitelDate(kapitelDate) }}</strong>
            </span>
            <span>
                {{ dateHelper.formatWeekdayShortFromKapitelDate(kapitelDate, false) }}
            </span>
        </div>
        <div
            class="body"
        >
            <Skeleton :items="['block--height-5.0rem']" v-if="propsLoading"></Skeleton>
            <template v-else>
            <div class="shifts" v-if="dayItemType !== 'empty'">
                <span
                    v-for="shift in orderedShiftTypes"
                    :key="shift"
                    :class="{'booked': shiftStates[shift].isBooked, 'absence': shiftStates[shift].isAbsent, 'available': shiftStates[shift].isAvailable, 'suggested': shiftStates[shift].isSuggested, 'wishDemands': shiftStates[shift].isWish}"
                >
                    {{ shift }}
                </span>
            </div>
            <div
                class="content"
            >
                <div
                    class="content-item"
                    :class="{'available': dayItemType == 'availability', 'absence': dayItemType == 'absence', 'booked': !!props.day?.booking, 'wishDemands': dayItemType == 'wishDemands'}"
                    >
                    <div v-if="booking" class="booking">
                        <div
                            class="labels"
                        >
                            <p
                                class="text-bold"
                            >
                                {{ booking.labelJobsite }}
                            </p>
                            <p>
                                {{ booking.begin }} - {{ booking.until }} Uhr
                            </p>
                        </div>
                        <div
                            class="image"
                        >
                            <Avatar
                                :src="booking.image"
                            />
                        </div>
                    </div>
                    <p v-else-if="dayItemType == 'absence' && props.day?.absence">
                        {{ getLabelForAbsenceType(props.day.absence.type) }}<br>
                        {{ props.day.absence.comment }}
                    </p>
                    <p v-else-if="dayItemType === 'availability' && props.day?.availability">
                        <strong>Verfügbar</strong><br>
                        {{ _availabilityEffectiveTimesHumanReadable }}
                    </p>
                    <p v-else-if="dayItemType === 'demandSuggestions'">
                        {{demandSuggestionsRenderSet.label}}
                    </p>
                    <p v-else-if="dayItemType === 'wishDemands' && props.day?.wishDemands">
                        {{ pluralizeUnitOnly(props.day?.wishDemands.length, 'Zusatzdienste', 'Zusatzdienst') }} auf {{ listFormat(props.day?.wishDemands.map(ds => ds.jobsite.abbreviation ?? '')) }}
                    </p>
                </div>

                <div
                    class="content-item text-only"
                    v-if="dayItemType === 'availability' && demandSuggestionsForAvailableDay.length > 0">
                    {{demandSuggestionsForAvailableDayRenderSet.label}}
                </div>
            </div>

            <ion-action-sheet
                :is-open="isWishDemandsModalOpen"
                :buttons="actionSheetWishDemandButtons"
                @didDismiss="onActionModalWishDemandDismiss"
            ></ion-action-sheet>

            <ion-action-sheet
                :is-open="isConfirmRemoveWishDemandModalOpen"
                :buttons="actionSheetConfirmWishDemandButtons"
                @didDismiss="onActionModalConfirmWishDemandDismiss"
            ></ion-action-sheet>

            <Modal
                v-if="dayItemType === 'demandSuggestions' && props.day?.demands && props.day?.demands?.length>0"
                ref="suggestionsModal"
                v-model="isSuggestionModalOpen"
                title="Verfügbarkeitsvorschläge"
            >
                <h3>{{dateHelper.formatKapitelDate(kapitelDate, 'DD')}}</h3>

                <DemandSuggestionSummary
                    :demands="props.day?.demands"
                    :edit-availability-action="()=>{
                        /*isSuggestionModalOpen=false; isWishDemandsModalOpen=false; isConfirmRemoveWishDemandModalOpen=false; toDeleteWishDemand=undefined; */
                        onDayClick('detail')
                    }"
                    :add-absence-action="addAbsence"
                    :add-availabilities-action="addAvailabilities"
                />
            </Modal>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router'
import {
    availabilityEffectiveTimesHumanReadable,
    generateAvailability,
    generatePropertiesFromDefault,
    hasShifts
} from "@/helper/availability";
import {computed, PropType, Ref, ref, watch} from 'vue'
import {generateAbsence, getLabelForAbsenceType} from '@/helper/absence'
import Avatar from '@/components/Avatar.vue';
import {orderedShiftTypes} from "@/helper/shiftTypes";
import datetime from "@/helper/datetime/datetime"
import Skeleton from "@/components/Skeleton.vue";
import dateHelper from "@/helper/datetime/date";
import {getImageForJobsite, getShortJobsiteDescription} from '@/helper/jobsite';
import Modal from '@/components/Modal.vue'
import {AbsenceType, Day, Demand} from '@/graphql/generated/graphql';
import {IonActionSheet} from '@ionic/vue'
import {removeWishDemand} from "@/helper/wishDemand";
import {useAppState} from "@/helper/appState";
import {CalendarDateSingleton} from "@/helper/calendar/calendarDateSingleton";
import {generateDemandSuggestionsRenderSet} from "@/helper/demand";
import DemandSuggestionSummary from "@/components/DemandSuggestionSummary.vue";
import {listFormat, pluralizeUnitOnly} from "../../helper/amountFormatter";

const router = useRouter();

const emit = defineEmits(['set:availability', 'set:absence', 'set:wish-demand', 'click:date'])

const props = defineProps({
    calendarDateSingleton: {
        type: Object as PropType<CalendarDateSingleton>,
        required: true
    },
    day: Object as PropType<Day>,
    availabilityShiftDefaults: Object as PropType<any>,
    propsLoading: {
        type: Boolean,
        default: false
    }
})

const kapitelDate = computed(() => props.calendarDateSingleton.date )

const dayItemType = computed(()=>{

    if(props.day?.booking){
        return "booking"
    }else if(props.day?.absence){
        return "absence"
    }else if (props.day?.availability &&  hasShifts(props.day?.availability)){
        return "availability"
    } else if(props.day?.demands && props.day?.demands.length>0) {
        return "demandSuggestions"
    } else if(props.day?.wishDemands && props.day?.wishDemands.length>0){
        return "wishDemands"
    }
    return "empty"
})

const shiftStates = computed(() => {
    const value = orderedShiftTypes.reduce((acc, shift) => {
        const isBooked = dayItemType.value === 'booking' && props.day?.booking?.shiftType === shift
        const isAbsent = dayItemType.value === 'absence' && !!props.day?.absence
        const isAvailable = dayItemType.value === 'availability' && !isBooked && !isAbsent && props.day?.availability?.shifts.some(sh => sh.type === shift)
        const isSuggested = (
            !isBooked &&
            !isAbsent &&
            (
                (dayItemType.value !== 'availability' && props.day?.demands?.some((d)=>d.shiftType === shift)) ||
                (dayItemType.value === 'availability' && demandSuggestionsForAvailableDay.value.some((d)=>d.shiftType === shift))
            )
        )
        const isWish = !isBooked && !isAbsent && !isAvailable && !isSuggested && props.day?.wishDemands?.some((d)=>d.shiftType === shift)

        acc[shift] = {
            isBooked: isBooked,
            isAbsent: isAbsent,
            isAvailable: isAvailable,
            isSuggested: isSuggested,
            isWish: isWish
        }

        return acc
    }, {})

    return value
})

const demandSuggestionsForAvailableDay = computed(()  => props.day ? props.day.demands : [])
const demandSuggestionsRenderSet = computed(() => generateDemandSuggestionsRenderSet(props.day?.demands ?? []))
const demandSuggestionsForAvailableDayRenderSet = computed(() => generateDemandSuggestionsRenderSet(demandSuggestionsForAvailableDay.value))


const suggestionsModal = ref(undefined)

const isSuggestionModalOpen = ref(false)
const isWishDemandsModalOpen = ref(false)
const isConfirmRemoveWishDemandModalOpen = ref(false)
const toDeleteWishDemand: Ref<Demand|undefined> = ref(undefined)

watch(() => props.modelValue, value => {
    isSuggestionModalOpen.value = value
    isWishDemandsModalOpen.value = value
    isConfirmRemoveWishDemandModalOpen.value = value
})

// watch(() => isSuggestionModalOpen.value, value => {
//     emit('update:modelValue', value)
// })


const _availabilityEffectiveTimesHumanReadable = computed(() => {
    return availabilityEffectiveTimesHumanReadable(props.day?.availability ?? [])
})

const booking = computed(() => {
    if (!props.day?.booking) {
        return
    }

    return {
        labelJobsite: getShortJobsiteDescription(props.day?.booking.jobsite, false, ' - '),
        begin: datetime.formatKapitelDateTime(props.day?.booking.begin, datetime.kapitelDateTimeFormatTime),
        until: datetime.formatKapitelDateTime(props.day?.booking.until, datetime.kapitelDateTimeFormatTime),
        image: getImageForJobsite(props.day?.booking.jobsite),
    }
})



const addAvailabilities = () => {

    if (!suggestionsModal.value) {
        return
    }

    if (!props.day?.demands) {
        return
    }

    suggestionsModal?.value?.dismiss().then(()=>{
        const availability = generateAvailability(kapitelDate.value)

        orderedShiftTypes.forEach((shiftType) => {
            if(props.day?.demands?.filter((d:Demand) => d.shiftType === shiftType ).length>0){
                const shiftDefault = props.availabilityShiftDefaults.find(shiftDef => shiftDef.type === shiftType)
                availability.shifts.push(generatePropertiesFromDefault(kapitelDate.value, shiftDefault))
            }
        })
        emit("set:availability", availability)
    })
}

const addAbsence = () => {

    if (!suggestionsModal.value) {
        return
    }

    suggestionsModal?.value?.dismiss().then(()=>{
        const absence =  generateAbsence(kapitelDate.value, AbsenceType.Timeoff)
        emit("set:absence", absence)
    })
}

const onDayClick = (detailOrSuggestion:string) => {
    if(useAppState().isEmployeeOrCandidate('candidate')){
        const wish = dayItemType.value == "wishDemands"
        if(wish) {
            isWishDemandsModalOpen.value = true
        }
    } else if (useAppState().isEmployeeOrCandidate('employee')) {
        const suggestion = dayItemType.value == "demandSuggestions"
        if(suggestion && !isSuggestionModalOpen.value){
            isSuggestionModalOpen.value = true
        }else {
            isSuggestionModalOpen.value = false
            emit('click:date', { dateId: kapitelDate.value, expectedContent: dayItemType.value })
        }
    }

}

const actionSheetWishDemandButtons = (computed(() => {

    const wishDemands = [];

    props.day?.wishDemands?.forEach((demand) => {
        wishDemands.push({
            text: demand.shiftType + " auf " + demand.jobsite.name + " entfernen",
            data: {
                action: "removeWishDemand",
                demand: demand
            },
        });
    });

    return [].concat(wishDemands, [
        {
            text: "Abbrechen",
            role: "cancel",
            data: {
                action: "cancel",
            },
        }
    ])
}));

const actionSheetConfirmWishDemandButtons = [
    {
        text: "Ja, Zusatzdienstwunsch entfernen",
        role: 'destructive',
        data: {
            action: "removeWishDemand",
        },
    },
    {
        text: "Abbrechen",
        role: "cancel",
        data: {
            action: "cancel",
        },
    },
];

const onActionModalConfirmWishDemandDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isConfirmRemoveWishDemandModalOpen.value = false
        toDeleteWishDemand.value = undefined
        return
    }

    switch (data.action) {
        case "removeWishDemand":
            removeWishDemand(toDeleteWishDemand.value)
            emit("set:wish-demand", toDeleteWishDemand.value)
            break;
    }

    isConfirmRemoveWishDemandModalOpen.value = false
    toDeleteWishDemand.value = undefined
};

const onActionModalWishDemandDismiss = async (event) => {
    const data = event.detail.data;

    if(!data?.action){
        isWishDemandsModalOpen.value = false
        return
    }

    switch (data.action) {
        case "removeWishDemand":
            toDeleteWishDemand.value = data.demand
            isConfirmRemoveWishDemandModalOpen.value = true
            break;
    }

    isWishDemandsModalOpen.value = false
};


</script>

<style lang="scss">
.calendar-list-item {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: var(--custom-spacing-app-content-padding-horizontal);

    min-height: 5.5rem; // reducing jumpiness with skeleton loader

    > .date {
        flex: 0 0 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > span:first-child {
            font-size: var(--custom-font-size-large);
            color: var(--ion-color-dark);
            margin-bottom: 0.15em;
        }

        > span:last-child {
            font-size: var(--custom-font-size-small);
            color: var(--ion-color-medium);
        }

        &.today {
            > span:first-child {
                color: var(--ion-color-primary);
            }
        }

        &.weekend {
            > span:last-child {
                color: var(--ion-color-primary);
            }
        }
    }

    > .body {
        display: flex;
        flex: 1 1 100%;

        > .shifts {
            flex: 0 0 auto;
            margin-right: 0.5rem;
            display: flex;
            flex-direction: column;

            > span {
                color: var(--ion-color-grey-tint-1);
                font-size: var(--custom-font-size-extrasmall);
                font-weight: var(--custom-font-weight-bold);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.2em;
                height: 2.2em;
                border-radius: 50%;
                margin-bottom: 0.2em;

                &.available {
                    background-color: rgba(var(--ion-color-green-rgb), 0.2);
                    color: var(--ion-color-dark);
                }

                &.wishDemands {
                    background-color: var(--ion-color-light);
                    color: var(--ion-color-dark);
                }

                &.suggested {
                    /* background-color: var(--ion-color-light); */
                    color: var(--ion-color-dark);
                }


                &.booked {
                    background-color: var(--ion-color-dark);
                    color: var(--ion-color-white);
                }
            }
        }

        > .content {
            flex: 1 1 100%;

            > .content-item {
                width: 100%;
                border-radius: 1rem;
                padding: 1rem;
                display: flex;
                align-items: center;

                &:only-child {
                    min-height: 100%;
                }
                
                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }

                &,
                * {
                    font-size: var(--custom-font-size-regular);
                }

                &.text-only {
                    padding-top: 0;
                    padding-bottom: 0;
                }

                &.available {
                    background-color: rgba(var(--ion-color-green-rgb), 0.1);
                    color: var(--ion-color-green-shade)
                }

                &.absence {
                    background-color: rgba(var(--ion-color-danger-rgb), 0.1);
                    color: var(--ion-color-danger);
                }

                &.booked {
                    background-color: var(--ion-color-dark);
                    color: var(--ion-color-white);

                    > .booking {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        > .labels {
                            flex: 1 1 100%;
                            margin-right: 1rem;
                        }

                        > .image {
                            flex: 0 0 auto;
                        }
                    }
                }

                &.wishDemands {
                    background-color: var(--ion-color-light);
                }
            }            
        }
    }
}
</style>
