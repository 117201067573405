import {AInesIcon} from "@/graphql/generated/graphql";
import {callOutline, checkmark, personAddOutline, search, squareOutline, timerOutline} from "ionicons/icons";
import {kapitelErrorHandler} from "@/helper/error";


export type IconDescription = {
    __typename?: 'IconDescription';
    iconId: string;
    iconType: string;
}

export const iconType2Icon = (icon: AInesIcon): IconDescription => {
    switch (true) {
        case icon === AInesIcon.Onboarding:
            return {iconId: personAddOutline, iconType: 'ion'}
        case icon === AInesIcon.Planning:
            return {iconId: "/icons/strategy.svg", iconType: 'icon'}
        case icon === AInesIcon.PlanningCompleted:
            return {iconId: checkmark, iconType: 'ion'}
        case icon === AInesIcon.Timesheet:
            return {iconId: timerOutline, iconType: 'ion'}
        case icon === AInesIcon.WishDemand:
            return {iconId: search, iconType: 'ion'}
        case icon === AInesIcon.RegisterBookingConfirmationMissing:
            return {iconId: callOutline, iconType: 'ion'}
        default:
            kapitelErrorHandler('unknown icon type')
            return {iconId: squareOutline, iconType: 'ion'}
    }
}
