
<script setup lang="ts">

import {IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonRadio} from '@ionic/vue'
import {computed, defineProps, PropType, ref, Ref, watch} from "vue";
import {getEmployeeJobsites, includesListJobsite, indexOfJobsiteInList} from "@/helper/jobsite"
import {informationCircleOutline} from "ionicons/icons"
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth"
import {AutopilotJobsite, Jobsite} from "@/graphql/generated/graphql"
import {DataLoader} from "@/helper/dataLoader"
import {
    addJobsiteToAutopilot,
    AutopilotPreferenceObject,
    fetchAutopilotPreferenceObject,
    removeJobsiteFromAutopilot
} from "@/helper/autopilotPreference"
import JobsiteSearchModal from "@/views/components/JobsiteSearchModal.vue"
import {JobsiteCategory, kapitelDateString} from '@/graphql/kapitelTypes';
import {getIconForJobsiteCategory, getLabelForJobsiteCategory, sortedJobsiteCategories} from '@/helper/jobsiteCategory';
import Modal from '@/components/Modal.vue'
import date from "@/helper/datetime/date";

import InlineSVG from '@/components/InlineSVG.vue';
import Skeleton from "@/components/Skeleton.vue";
import JobsiteDetails from "@/views/JobsiteDetails.vue";


/**
 * props & models
 */
const showMe = defineModel({type: Boolean})

const props = defineProps({
    monthId: {
        type: String as PropType<kapitelDateString>,
        required: true
    }
});


/**
 * fetched state
 */

const favoriteJobsites : Ref<Array<Jobsite>> = ref([]);
const secondChoiceJobsites : Ref<Array<Jobsite>> = ref([]);
const bookedJobsites : Ref<Array<Jobsite>> = ref([]);
const loadJobsites = async () => {
    const {favorite,secondChoice, booked} = await getEmployeeJobsites()

    favoriteJobsites.value = favorite
    favoriteJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))

    secondChoiceJobsites.value = secondChoice
    secondChoiceJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))

    bookedJobsites.value = booked
    bookedJobsites.value.forEach(jobsite => listNewJobsite(jobsite, false, true))
}

const autopilotPreference : Ref<AutopilotPreferenceObject | undefined> = ref()
const loadAutopilotPreference = async () => {

    autopilotPreference.value = await fetchAutopilotPreferenceObject(calendarMonth.value)
    autopilotPreference.value?.autopilotJobsites.forEach((autopilotJobsite: AutopilotJobsite) => {
        const jobsite = autopilotJobsite.jobsite
        listNewJobsite(jobsite, true, true)
    })
}

const dataLoader = new DataLoader([loadJobsites, loadAutopilotPreference])


/**
 * local state & actions
 */

const jobsiteDetailModalData: Ref<number|undefined> = ref(undefined)
const jobsiteAddModalIsOpen = ref(false)

const listedJobsites : Ref<Array<Jobsite>> = ref([])
const selectedJobsites : Ref<Array<Jobsite>> = ref([])

const resetLocalState = () => {
    jobsiteDetailModalData.value = undefined
    jobsiteAddModalIsOpen.value = false

    listedJobsites.value = []
    selectedJobsites.value = []
}

const listNewJobsite = (jobsite: Jobsite, selectedState = true, silent = false) => {
    if (!includesListJobsite(listedJobsites.value, jobsite)) {
        listedJobsites.value.push(jobsite)
    }
    if (selectedState) {
        selectJobsite(jobsite, true, silent)
    }
}
const isSelected = (jobsite: Jobsite) => includesListJobsite(selectedJobsites.value, jobsite)
const selectJobsite = (jobsite: Jobsite, state = true, silent = false) => {
    const arr = selectedJobsites.value
    const selected =  includesListJobsite(arr, jobsite)
    if (state && !selected) {
        arr.push(jobsite)
        if (!silent) {
            addJobsiteToAutopilot(calendarMonth.value, jobsite)
        }
    } else if (!state && selected) {
        arr.splice(indexOfJobsiteInList(arr, jobsite),1)
        if (!silent) {
            removeJobsiteFromAutopilot(calendarMonth.value, jobsite)
        }
    }
}



/**
 * computed
 */

const calendarMonth = computed(() => generateCalendarMonth(props.monthId))

const getJobsiteCategory = (jobsite: Jobsite) : JobsiteCategory => {
    switch (true) {
        case includesListJobsite(favoriteJobsites.value, jobsite): return JobsiteCategory.Favorite
        case includesListJobsite(secondChoiceJobsites.value, jobsite): return JobsiteCategory.Alternative
        case includesListJobsite(bookedJobsites.value, jobsite): return JobsiteCategory.Booked
        default: return JobsiteCategory.Other
    }
}
const listedJobsiteSortedRenderSets = computed(() => {
    return listedJobsites.value
        .map(jobsite => ({
            jobsite,
            label: jobsite.name || jobsite.abbreviation || '(fehlt)',
            clientLabel: jobsite.client?.abbreviation,
            houseLabel: jobsite.house?.abbreviation,
            additionalImportantLabel: jobsite.additionalAmenImportantAbbreviations ? jobsite.additionalAmenImportantAbbreviations:[],
            category: getJobsiteCategory(jobsite),
            disabledEffectively: jobsite.disabledEffectively,
        }))
        .sort((a, b) =>
            a.label.localeCompare(b.label, undefined, {numeric: true, sensitivity: 'base'})
        )
})
const jobsiteCategoriesRenderSets = computed(() => {
    return sortedJobsiteCategories
        .map((category) => { 
            const jobsiteRenderSets = listedJobsiteSortedRenderSets.value.filter(jobsiteRS => jobsiteRS.category === category)            
            return {
                category,
                label: getLabelForJobsiteCategory(category),
                icon: getIconForJobsiteCategory(category),
                jobsiteRenderSets,
                getSelectedJobsites() { return jobsiteRenderSets.map(jobsiteRS => jobsiteRS.jobsite).filter(isSelected) },
                getJobsiteCountLabel() {
                    const selectedCount = this.getSelectedJobsites().length
                    const totalCount = this.jobsiteRenderSets.length

                    return totalCount === selectedCount ? totalCount : (selectedCount + '/' + totalCount)
                }                                
            }
        })
        .filter(categoryRS => categoryRS.jobsiteRenderSets.length > 0)
})


/**
 * setup
 */
const reinit = () => {
    if (!showMe.value) {
        return
    }
    resetLocalState()
    dataLoader.load()
}
watch (showMe, reinit)
watch(() => props.monthId, reinit)
reinit()

</script>


<template>
<Modal v-model="showMe" :title="'Stationsauswahl ' + date.formatKapitelDate(props.monthId, 'MMMM')">
    <template v-slot:default>
    <Skeleton v-if="dataLoader.pending.value" :items="['title','block','block','block','margin','title','block','block']"></Skeleton>
    <ion-list  v-if="!dataLoader.pending.value" >
        <template v-for="categoryRS in jobsiteCategoriesRenderSets" :key="categoryRS.categoryKey">
            <ion-list-header>
                {{ categoryRS.label }}
                <div
                    class="icon"
                    v-if="categoryRS.icon"
                >
                    <InlineSVG
                        :src="categoryRS.icon"
                    />
                </div>
                ({{ categoryRS.getJobsiteCountLabel() }})
            </ion-list-header>
            <ion-item
                v-for="jobsiteRS in categoryRS.jobsiteRenderSets"
                :key="jobsiteRS.jobsite.id"
                class="jobsiteItem"
                :class="{
                    unselected: isSelected(jobsiteRS.jobsite),
                    removed: jobsiteRS.disabledEffectively
                }"
                @click="(e) => { selectJobsite(jobsiteRS.jobsite, !isSelected(jobsiteRS.jobsite)) }"
            >
                <ion-label>
                    <h3>{{ jobsiteRS.label }}</h3>
                    <p>{{ jobsiteRS.clientLabel }}<span v-if="jobsiteRS.houseLabel">, {{ jobsiteRS.houseLabel }}</span><span v-if="jobsiteRS.additionalImportantLabel.length>0">, {{jobsiteRS.additionalImportantLabel.join(", ")}}</span></p>
                </ion-label>
                <ion-radio
                    :aria-label="jobsiteRS.label"
                    slot="start"
                    :checked="isSelected(jobsiteRS.jobsite)"
                    label-placement="end"
                    justify="start"

                />
                <ion-button
                    slot="end"
                    class="jobsiteInfo"
                    fill="clear"
                    color="secondary"
                    @click.stop="jobsiteDetailModalData=jobsiteRS.jobsite.id"
                >
                    <ion-icon
                        slot="icon-only"
                        :icon="informationCircleOutline"
                    />
                </ion-button>
            </ion-item>
        </template>
    </ion-list>

    <JobsiteSearchModal
        v-model="jobsiteAddModalIsOpen"
        @select="jobsite => listNewJobsite(jobsite, true)"
    />

    <JobsiteDetails v-model="jobsiteDetailModalData" />
    </template>
    <template v-slot:actions>
        <ion-button
            color="light"
            expand="block"
            @click="jobsiteAddModalIsOpen = true"
        >
            Zusätzliche Station hinzufügen
        </ion-button>
        <ion-button
            color="primary"
            expand="block"
            @click="showMe = false"
        >
            Ok
        </ion-button>
    </template>
</Modal>
</template>


<style scoped lang = "scss">
.removed {
    text-decoration: line-through;
}

.icon {
    width: 1em;
    line-height: 0.75em;
    margin: 0 0.5em 0 0.2em;
    color:var(--ion-color-primary)
}

.jobsiteItem {
    ion-checkbox {
        width: 75%;
    }

    ion-button.jobsiteInfo {
        position: fixed;
        right: 0px;
    }

    &.unselected ion-label {
        opacity: 0.75;
    }
}

</style>
