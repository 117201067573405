<template>
<div class="rich-month-calendar">
    <MonthCalendarGrid
        :date="month"
        v-on:date-click="onClick"
    >
        <template v-slot="{ date, adjacent }">
            <div
                v-if="date && !adjacent"
                class="calendar-date-content"
                :class="{
                    booked: !!dayMap.get(date.id)?.booking,
                    available: (!!dayMap.get(date.id)?.availability && hasShifts(dayMap.get(date.id)?.availability as Availability)),
                    absence: !!dayMap.get(date.id)?.absence,
                    suggestion: !!suggestionMap.get(date.id),
                }"
            />
        </template>
    </MonthCalendarGrid>
    <MonthCalendarLegend :mode="suggestionMap.size > 0 ? 'suggestions' : 'regular'"/>
    <CalendarDetail
        v-model="modalValue"
        :expected-content="expectedDetailContent"
    ></CalendarDetail>
</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import MonthCalendarGrid from '@/components/MonthCalendarGrid.vue';
import {Availability, Day} from "@/graphql/generated/graphql";
import {computed, PropType, Ref, ref, watch} from "vue";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { DataLoader } from "@/helper/dataLoader";
import { startOfMonth, endOfMonth, formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import {fetchDaysForDateRange, toDayMap} from "@/helper/day";
import {hasShifts} from "@/helper/availability";
import Modal from "@/components/Modal.vue";
import date from "@/helper/datetime/date";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import MonthCalendarLegend from "@/components/MonthCalendarLegend.vue";

const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    days: {
        type: Array as  PropType<Day[]>,
        required: true
    },
    suggestions: Array as PropType<any[]>,
    // showCounts: Boolean,
    showDetailsOnClick: {
        type: Boolean,
        required: false,
        default: true
    }
})

const emit = defineEmits({
    'date-click': (param: {date:kapitelDateString}) => true
})

const modalValue: Ref<kapitelDateString|undefined> = ref(undefined)
const expectedDetailContent: Ref<"booking"|"availability"|undefined> = ref(undefined)
const dayMap = computed(() => toDayMap(props.days))
const suggestionMap = computed(() => {
    const m = new Map()
    if (props.suggestions && props.suggestions.length > 0) {
        props.suggestions.forEach(suggestion => {
            m.set(suggestion.date, suggestion)
        })
    }
    return m
})

const onClick = (data: {date: kapitelDateString}) => {
    emit('date-click', data)

    if (props.showDetailsOnClick) {
        if (!dayMap.value.get(data.date)) {
            return
        }
        expectedDetailContent.value = dayMap.value.get(data.date)?.booking ? "booking" : "availability"
        modalValue.value = data.date
    }
}
</script>

<style scoped  lang="scss">

.rich-month-calendar {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    padding: 0.5em 1em 1em 1em;
    width: 100%;
    margin-top: 0.5em;
}

.calendar-date-content {
    $SIZE: 0.5em;
    width: $SIZE;
    height: $SIZE;
    border-radius: 50%;
    transform: translateY(1.1em);

    &.suggestion {
        background-color: var(--ion-color-yellow);
    }

    &.available {
        background-color: var(--ion-color-green);
    }

    &.absence {
        background-color: var(--ion-color-red);
    }

    &.booked {
        background-color: var(--ion-color-black-tint);
    }

}
</style>
