<script setup lang="ts">
import { IonContent, IonButton, IonItem, IonLabel, IonList, onIonViewWillEnter, IonIcon } from "@ionic/vue";
import { ellipseOutline, checkmarkCircle } from 'ionicons/icons';
import { Employee, Qualification } from "@/graphql/generated/graphql";
import { ref, watch } from "vue";
import { useEmployeeStore } from "@/store/employee";
import Modal from "@/components/Modal.vue";

const props = defineProps({
    modelValue: Boolean,
});

const emit = defineEmits(["update:modelValue", "select"]);
const isOpen = ref(false);
watch(
    () => props.modelValue,
    (value) => (isOpen.value = value),
);
watch(
    () => isOpen.value,
    (value) => emit("update:modelValue", value),
);

const employee = ref<Employee | undefined>(undefined);
const qualifications = ref<Qualification[]>([]);

const onSelectQualification = (item) => {
    emit("select", item);
    isOpen.value = false;
};

const initQualificationsFromEmployee = async ()=> {
    employee.value = await useEmployeeStore().getEmployee()
    if(employee.value){
        qualifications.value = employee.value.offerableQualifications;
    }
}

initQualificationsFromEmployee()
onIonViewWillEnter(initQualificationsFromEmployee);
</script>

<template>
    <Modal
        v-model="isOpen"
        title="Qualifikation auswählen..."
        class="no-padding-left no-padding-right"
    >

        <ion-list>
            <ion-item
                button
                :detail="false"
                :key="index"
                v-for="(item, index) in qualifications"
                @click="onSelectQualification(item)"
            >
                <ion-label>
                    {{ item.label }}
                </ion-label>
            </ion-item>
        </ion-list>

        <div class="qualification-selector-actions">
            <ion-button
                color="light"
                expand="block"
                @click="isOpen = false"
            >
                Abbrechen
            </ion-button>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
.qualification-selector-actions,
ion-label {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}
</style>
