import {QuickChat} from "@/helper/chat/quickChat";
import {PreprocessedAssistantMessage, useChatStore} from "@/helper/chat/chatStore";
import {appendMessage, presentScriptedMessage, sendUserMessage} from "@/helper/chat/chatBL";
import {AInesInterestedInJobsiteTypes} from "@/graphql/generated/graphql";
import {
    calendarNumberOutline,
    compassOutline,
    diamondOutline, helpBuoyOutline, helpCircleOutline, helpOutline,
    homeOutline,
    medkitOutline,
    peopleCircleOutline,
    playCircleOutline,
    searchOutline,
    sparklesOutline
} from "ionicons/icons";
import date, {formatKapitelDate} from "@/helper/datetime/date";

export interface ScriptedQuickChat extends QuickChat{
    click: () => any
}

export interface ScriptedMessage extends PreprocessedAssistantMessage {
}

const quickChatClickHandler = ({nextScriptedMessage, userPrompt}:{nextScriptedMessage?:ScriptedMessage, userPrompt:string}) => {
    const chatStore = useChatStore();

    if(chatStore.isFirstContact){
        chatStore.setIsFirstContact(false)
    }

    // TODO: centralize prepend 4
    const previousScriptedMessage = chatStore.scriptedMessage?.textContent
    if (nextScriptedMessage && previousScriptedMessage) {
        appendMessage(previousScriptedMessage, undefined, 'assistant').then(() => {
            appendMessage(userPrompt, undefined, 'user')
        })
        presentScriptedMessage(nextScriptedMessage)
    } else if (nextScriptedMessage) {
        appendMessage(userPrompt, undefined, 'user')
        presentScriptedMessage(nextScriptedMessage)
    } else {
        sendUserMessage(userPrompt, {isScriptedContent: true, prependedAssistantMessage: previousScriptedMessage})
    }
}

export const scriptedMessages : {
    firstCandidateContact: PreprocessedAssistantMessage,
    ibfgDescription: PreprocessedAssistantMessage
    afterPromotion: PreprocessedAssistantMessage
    afterSwitchToEmployee: PreprocessedAssistantMessage
    candidateNeedsPromotion: PreprocessedAssistantMessage
}= {
    firstCandidateContact: {
            textContent: "Hallo! Ich helfe dir, einen passenden Zusatzdienst zu finden. Wir können direkt mit der Suche starten, oder ich gebe dir vorher noch einen Überblick über Zusatzdienste bei der IBFG.",
            richContents: undefined,
            suggestedQuickChats: [
                {
                    label: "Frühdienst nächste Woche im Wedding",
                    icon: calendarNumberOutline,
                    click: () => quickChatClickHandler({userPrompt: "Ich suche nach einem Frühdienst nächste Woche im Wedding."})
                },
                {
                    label: "Welche neonatologische ITS gibt es?",
                    icon: '/icons/house.svg',
                    click: () => quickChatClickHandler({userPrompt: "Welche neonatologische ITS gibts es?"})
                },
                {
                    label: "Was bedeutet Zusatzdienst bei der IBFG?",
                    icon: helpOutline,
                    click: () => quickChatClickHandler({userPrompt:'Was bedeutet Zusatzdienst bei der IBFG?', nextScriptedMessage: scriptedMessages.ibfgDescription})
                }
            ],
        },
    ibfgDescription: {
        textContent: `**Zusatzdienste bei  der IBFG**\n
Wir bieten dir an, bei der IBFG im Rahmen einer geringfügigen Beschäftigung einzelne Dienste zusätzlich zu deiner Beschäftigung bei einer anderen Leasingfirma zu machen.`,
        richContents: undefined,
        suggestedQuickChats: [
            {
                label: "Was verdiene ich hier?",
                icon: diamondOutline,
                click: () => quickChatClickHandler({userPrompt: "Was verdient man bei der IBFG?"})
            },
            {
                label: "Wo kann ich arbeiten?",
                icon: homeOutline,
                click: () => quickChatClickHandler({userPrompt: 'Bei welchen Kliniken kann ich eingesetzt werden?'})
            },
            // {
            //     label: "Wie könnt ihr euch das leisten?",
            //     icon: peopleCircleOutline,
            //     click: () => quickChatClickHandler({userPrompt: 'Wie könnt ihr euch das leisten?'})
            // },

        ],
    },
    afterPromotion: {
        textContent: "Herzlichen Glückwunsch, du kannst nun uneingeschränkt nach Zusatzdiensten suchen. Wir melden uns zeitnah bei dir um die nächsten Schritte zu besprechen.\n\nDarf ich dir direkt ein paar Dienste vorschlagen? Suchst du nach offenen Diensten auf deinen gemerkten Stationen? Oder hast du schon eine konkrete Vorstellung, wann und wo du gerne arbeiten würdest?",
        richContents: undefined,
        suggestedQuickChats: [
            {
                label: "Auf der Merkliste suchen",
                icon: homeOutline,
                click: () => quickChatClickHandler({userPrompt: 'Gibt es offene Dienste auf den Stationen auf meiner Merkliste?'})
            },
            {
                label: "Schlag mir was vor!",
                icon: sparklesOutline,
                click: () => quickChatClickHandler({userPrompt:'Schlag mir offene Dienste vor!'})
            },
            {
                label: "Frühdienste am " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'd. MMMM'),
                icon: searchOutline,
                click: () => quickChatClickHandler({userPrompt: "Welche offenen Frühdienste gibt es am " + formatKapitelDate(date.addMonths(date.getToday(), 1) , 'd. MMMM')})
            }
        ],
    },
    afterSwitchToEmployee: {
        textContent: "Du bist jetzt als Mitarbeiter angemeldet, willkommen!",
        richContents: undefined,
    },
    candidateNeedsPromotion: {
        textContent: "Damit wir den Dienst für dich buchen können, brauchen wir noch ein paar Kontaktdaten. Bitte fülle folgendes Formular aus:",
        richContents: [{
            type: "InitCandidatePromotion",
            initData: {}  
        }]
    }
}

export const scriptedQuickChats : {
    interestedInSet: ScriptedQuickChat[],
}= {
    interestedInSet: [
        {
            label: "Schlag mir was vor!",
            icon: sparklesOutline,
            click: () => quickChatClickHandler({userPrompt: 'Kannst du mir ein paar Stationen vorschlagen?'})
        },
        {
            label: "In welchen Bezirken kann ich suchen?",
            icon: compassOutline,
            click: () => quickChatClickHandler({userPrompt: 'In welchen Bezirken kann ich suchen?'})
        },
        {
            label: "Welche Fachbereiche stehen zur Auswahl?",
            icon: medkitOutline,
            click: () => quickChatClickHandler({userPrompt: 'Welche Fachbereiche stehen zur Auswahl?'})
        },
    ]
}
