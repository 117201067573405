import {gql} from "@urql/vue";
import {query} from "@/graphql/client";
import {AInesObjective, AInesObjectiveState, Employee, PlanningStatus} from "@/graphql/generated/graphql";
import {presentScriptedMessage, sendUserMessage} from "./chatBL";
import {defineStore} from "pinia";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import {fetchPlanningStatus} from "@/helper/autopilotStatus";
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth";


export async function fetchAInesObjectives(employee? : Employee): Promise<Array<AInesObjective>> {
    let result;
    if(!employee) {
        result = await query(
            gql`query getCurrentAInesObjectives {
                currentAInesObjectives {
                    reference
                    subject
                    state
                    needsAction
                    clickAction {
                        type
                        text
                        richResponse
                        assistantType
                        richResponseInitData
                    }
                    title
                    text
                    icon
                }
            }`
        );
    }else {
        result = await query(
            gql`query getCurrentAInesObjectives 
            (
                $employeeId: Int
            ){
                currentAInesObjectives(employeeId: $employeeId) {
                    reference
                    subject
                    state
                    needsAction
                    clickAction {
                        type
                        text
                        richResponse
                        assistantType
                        richResponseInitData
                    }
                    title
                    text
                    icon
                    
                }
            }`,
            {
                employeeId: employee.id,
            }
        )
    }

    return result.data?.currentAInesObjectives || [];

}


export async function handleObjectiveClick(objective: AInesObjective): Promise<void> {

    if(objective.clickAction.type === 'userMessage') {
        const richResponseInitData = objective.clickAction.richResponseInitData ?? objective.reference
        await sendUserMessage(
            objective.clickAction.text,
            {
                messageExpertAssistant: objective.clickAction.assistantType,
                isScriptedContent: true,
                richContentsPreview: objective.clickAction.richResponse ? [{
                    type: objective.clickAction.richResponse ,
                    initData: richResponseInitData
                }]
                    : undefined
            }
        )
    }else if(objective.clickAction.type === 'assistantMessage') {
        presentScriptedMessage({
            textContent: objective.clickAction.text,
            richContents: objective.clickAction.richResponse ? [{
                    type: objective.clickAction.richResponse ,
                    initData: objective.reference
                }]
                : undefined
        })
    }
}

export enum AInesObjectiveUIState {
    MINIMIZED = 'MINIMIZED',
    VISIBLE = 'VISIBLE',
}

export const useAInesObjectiveStore = defineStore("AInesObjectives", {
    state: (): {
        objectives: Array<AInesObjective>,
        selection: AInesObjective | undefined,
        uiState: AInesObjectiveUIState | undefined,
        planningObjectivesProgress: Map<string, number>,
    } => ({
        objectives: [],
        selection: undefined,
        uiState: undefined,
        planningObjectivesProgress: new Map<string, number>(),
    }),
    getters: {
        objectivesLength: (state) => state.objectives.filter(o => o.needsAction).length,
        unhandledObjectivesLength: (state) => state.objectives.filter(o => o.needsAction).filter(o => o !== state.selection).length
    },
    actions: {
        async refetch() {
            this.objectives = []
            this.objectives = await fetchAInesObjectives()
            this.refetchPlanningObjectivesProgress()
        },
        async refetchPlanningObjectivesProgress() {
            this.planningObjectivesProgress = new Map<kapitelDateString, number>()
            console.log('refetch planning objectives progress')
            for (const objective of this.objectives.filter(o => o.state === AInesObjectiveState.InProgress)) {
                fetchPlanningStatus(generateCalendarMonth(
                    objective.reference
                )).then((ps: PlanningStatus) => {
                    if (!ps) {
                        this.planningObjectivesProgress.set(objective.reference, 0)
                        return
                    }
                    this.planningObjectivesProgress.set(
                        objective.reference,
                        (
                            ps.bookings
                            / Math.max(
                                1,
                                ps.bookings + (ps.autopilotStatus?.missingBookings || 0)
                            )
                        )
                    )
                })
            }
        },
        toggleUIState() {
            this.setUIState(this.uiState !== AInesObjectiveUIState.MINIMIZED ? AInesObjectiveUIState.MINIMIZED : AInesObjectiveUIState.VISIBLE)
        },
        setUIState(uiState: AInesObjectiveUIState) {
            this.uiState = uiState
        },
        selectObjective(objective: AInesObjective | undefined) {
            this.selection = objective
        },
        deselectObjective() {
            this.selection = undefined
        }
    }
});
