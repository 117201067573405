<script setup lang="ts">

import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonButton, IonItem, IonLabel, IonList, IonSearchbar, IonListHeader, IonIcon } from "@ionic/vue"
import { ellipseOutline, checkmarkCircle } from 'ionicons/icons';
import {JobsiteCategorySegment, fetchJobsites} from "@/helper/jobsite"
import { PropType, Ref, ref, watch } from "vue"
import Modal from '@/components/Modal.vue'
import { Jobsite } from "@/graphql/generated/graphql";
import { JobsiteCategory } from "@/graphql/kapitelTypes";
import {getIconForJobsiteCategory, getLabelForJobsiteCategory} from "@/helper/jobsiteCategory"
import {IonFooter} from "@ionic/vue";

const props = defineProps({
    categorize : {
        type: Boolean,
        required: false,
        default: true
    },
    alreadySelectedJobsites: {
        type: Array as PropType<Jobsite[]>,
        required: false,
        default: () => []
    },
    quickSelectJobsiteCategorySegments: {
        type: Array as PropType<JobsiteCategorySegment[]>,
        required: false,
        default: () => []   
    }
})


const emit = defineEmits(['select'])

const modelValue = defineModel()
const isOpen = ref(modelValue.value)
const modal = ref()

watch(()=>modelValue.value, value =>isOpen.value=value)

watch(() => isOpen.value, value => {
    modelValue.value = value
    if (value) {
        if(!loading.value){
            resetList()
        }
    }else{
        loading.value = false
        searchResultSegment.jobsites.value = []
        segments.value = [searchResultSegment]
        pagination.value = 0
        searchQuery.value = ""
    }
})

// const isOpen = ref(false)
// watch(() => isOpen.value, value => emit('update:modelValue', value))

const loading = ref(false);
interface JobsiteListSegment {
    category: JobsiteCategory,
    jobsites: Ref<Array<Jobsite>>
}
const segments: Ref<Array<JobsiteListSegment>>= ref([])
const searchResultSegment : JobsiteListSegment = {category: JobsiteCategory.Other, jobsites: ref([])}
const pagination = ref(0)
const pageSize = 25;
const searchQuery = ref()
const endOfSearchListReached = ref(false)

watch(()=> endOfSearchListReached.value, value=>{
    console.log("end of list: " + value)
})


const searchByNewQuery = target => {
    searchQuery.value = target
    resetList()
}

const resetList = () => {
    pagination.value = 0
    searchResultSegment.jobsites.value = []
    segments.value = [searchResultSegment]
    endOfSearchListReached.value = false

    resetScrollPosition()

    if (prependingQuickSelectResults()) {
        segments.value.unshift(...props.quickSelectJobsiteCategorySegments.map(({category, jobsites}) => ({category, jobsites: ref(jobsites)})))
    }

    fetchNextPageResults()    
}


const prependingQuickSelectResults = () => !searchQuery.value

const fetchNextPageResults = () => {

    loading.value = true;
    const currentSearchQuery = searchQuery.value
    const excludeJobsites = prependingQuickSelectResults() ? props.quickSelectJobsiteCategorySegments.reduce((agg: Array<Jobsite> ,segment) => agg.concat(segment.jobsites), []) : []

    return fetchJobsites(searchQuery.value, pagination.value, pageSize, excludeJobsites)
        .then(gqlResult => {
            
            //discard race conditioned queryies
            if(currentSearchQuery != searchQuery.value){
                return 
            }

            if(gqlResult.length==0 || gqlResult[(gqlResult).length-1].isLastItem){
                endOfSearchListReached.value = true
            }

            searchResultSegment.jobsites.value.push(...gqlResult.map((sj) => sj.jobsite))

        })
        .finally(() => loading.value = false);
}

const ionInfinite = (ev) => {
    pagination.value = pagination.value + pageSize;
    fetchNextPageResults()
        .then(()=>{
            ev.target.complete()
        })
}

const onClick = (item: Jobsite) => {
    modal.value.dismiss().then(()=>{emit('select', item)})
}

function resetScrollPosition() {
    console.log("reset scroll position")
    document.querySelector('.jobsite-selector-modal-scroller')?.scrollToTop()
}

function isAlreadySelected(jobsite: Jobsite) {
    return !!props.alreadySelectedJobsites.find(item => item.id === jobsite.id)
}

defineExpose({
    searchByNewQuery
})

</script>

<template>
<Modal
    v-model="isOpen"
    title="Station auswählen..."
    class="no-padding-left no-padding-right"
    ref="modal"
    fullscreen
    :toolbar="true"
    keep-footer-on-keyboard-show
    @view:didPresent="() => {/*resetList()*/}"
>
    <template v-slot:default>
        <ion-content class="no-padding">
            <ion-list>
                <template v-for="segement in segments" v-bind:key="segement.category">
                    <IonListHeader 
                        v-if="segments.length > 1 && props.categorize && segement.jobsites.length > 0"
                        style="padding-left: var(--custom-spacing-app-content-padding-horizontal)"
                    >
                        <span>{{ getLabelForJobsiteCategory(segement.category) }}</span>
                    </IonListHeader>

                    <ion-item
                        v-for="item in segement.jobsites"
                        :detail="false"
                        :key="item.id"
                        @click="onClick(item)"
                        :class="isAlreadySelected(item) ? 'alreadySelected' : ''"
                        :button="true"
                    >
                        <ion-label class="app-padding-horizontal">
                            <h3>{{ item.name }}</h3>
                            <p>{{ item.client?.abbreviation }}<span v-if="item.house">, {{ item.house?.abbreviation }}</span><span v-if="item.additionalAmenImportantAbbreviations?.length>0">, {{item.additionalAmenImportantAbbreviations.join(", ")}}</span></p>
                        </ion-label>
                        <!--<ion-icon aria-hidden="true" :icon="checkmarkCircle" color="primary" slot="start" v-if="isAlreadySelected(item)" />-->
                        <!--<ion-icon aria-hidden="true" :icon="ellipseOutline" color="medium" slot="end" v-else />-->
                    </ion-item>
                </template>
            </ion-list>
            <ion-infinite-scroll
                :disabled="endOfSearchListReached"
                @ionInfinite="ionInfinite"
            >
                <ion-infinite-scroll-content />
            </ion-infinite-scroll>
        </ion-content>
        <ion-footer>
            <ion-searchbar
                :debounce=200
                @ion-input="({target})=>searchByNewQuery(target.value)"
                :value="searchQuery ? searchQuery : ''"
                placeholder="Suchen ..."
                class="jobsite-selector-searchbar"
            />
        </ion-footer>
    </template>
</Modal>
</template>

<style lang="scss">
.jobsite-selector-modal-scroller {
    height: 50vh;
    overflow: auto;
}

.jobsite-selector-searchbar {
    padding-left: 0;
    padding-right: 0;
}

.jobsite-selector-actions {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}

.alreadySelected {
    opacity: 0.75
}
</style>
