export const isElementTopOutOfBoundsInScrollContainer = (childElement: HTMLElement, scrollContainer: HTMLElement, thresholdInPx = 32) => {
    const childRect = childElement.getBoundingClientRect()
    const scrollRect = scrollContainer.getBoundingClientRect()

    return childRect.bottom < scrollRect.top + thresholdInPx
};

export const isElementBottomOutOfBoundsInScrollContainer = (childElement: HTMLElement, scrollContainer: HTMLElement, thresholdInPx = 32) => {
    const childRect = childElement.getBoundingClientRect()
    const scrollRect = scrollContainer.getBoundingClientRect()

    return childRect.top > scrollRect.bottom - thresholdInPx
};

export const isElementVisibleInScrollContainer = (childElement: HTMLElement, scrollContainer: HTMLElement, thresholdInPx = 32) => {
    return !isElementTopOutOfBoundsInScrollContainer(childElement, scrollContainer, thresholdInPx) && !isElementBottomOutOfBoundsInScrollContainer(childElement, scrollContainer, thresholdInPx)
};

export const getVerticalDifferencInPx = (elementA: HTMLElement, elementB: HTMLElement) => {
    const aRect = elementA.getBoundingClientRect()
    const bRect = elementB.getBoundingClientRect()

    return aRect.bottom - bRect.bottom
}