<template>
    <Modal v-model="model" :title="'Zieldienste für ' + date.formatKapitelDate(props.month, 'MMMM')" @view:willPresent="reset">

        <template #default>
            <div><!--ScrollBox-->
                <Skeleton v-if="initialDataLoader.pending.value" :items="['line--height-3.5em', 'block--height-10em']"/>
                <template v-else>

                    <PlusMinusInput
                        v-model="targetBookingInput"
                        @update:modelValue="onUpdateTargetBookings"
                        :min="minBookings"
                        :max="maxBookings"
                        :unit="pluralizeUnitOnly(targetBookingInput, 'Dienst', 'Dienste')"/>

                    <RichResponseListItem
                        v-for="worktimeMode in getWorktimeModes()"
                        v-bind:key="worktimeMode"

                        :title="getWorktimeModeLabel(worktimeMode)"
                        :selectable="isSelectableWorktimeMode(worktimeMode)"

                        icon-type="icon"
                        :icon-color="isSelectedWorktimeMode(worktimeMode) ? 'primary' : 'grey'"
                        :icon="getIconForWorktimeMode(worktimeMode)"

                        @selected="(value:boolean)=>{onClickWorktimeModeItem(worktimeMode, value)}"
                        :selected="worktimeModeInput === worktimeMode"
                    />

                    <div class="foldableContainer">
                        <div class="foldable" :class="{expanded:showAbsencePlanningSubSection}">

                            <IonCard
                                style="margin-top: 6px; margin-bottom: 0; padding: 0 18px; min-height: 6em /*prevent jumping on complete*/">
                                <IonCardContent>
                                    <IonProgressBar
                                        :value="absencePlanningRS.complete ? 1 : absencePlanningRS.plannedAbsences/absencePlanningRS.requiredAbsences"
                                        style="height: 4px"
                                        :color="absencePlanningRS.complete ? 'success' : 'primary'"></IonProgressBar>

                                    <IonLabel>
                                        <template v-if="absencePlanningRS.complete">
                                            <IonIcon :icon="checkmarkCircleOutline" class="globalTextAlignedIcon"/>
                                            {{ absencePlanningRS.complete ? pluralize(absencePlanningRS.plannedAbsences, 'Abwesenheit', 'Abwesenheiten') : (absencePlanningRS.plannedAbsences + ' / ' + pluralize(absencePlanningRS.requiredAbsences, 'Abwesenheit', 'Abwesenheiten')) }}
                                            eingetragen
                                        </template>
                                        <template v-else>
                                            <IonIcon :icon="informationCircleOutline" id="overlay-trigger"
                                                     class="globalTextAlignedIcon"/>

                                            {{ absencePlanningRS.complete ? pluralize(absencePlanningRS.plannedAbsences, 'Abwesenheit', 'Abwesenheiten') : (absencePlanningRS.plannedAbsences + ' / ' + pluralize(absencePlanningRS.requiredAbsences, 'Abwesenheit', 'Abwesenheiten')) }}
                                            eingetragen

                                            <div>
                                                <AZKValue :employee="employee"/>
                                            </div>

                                            <ion-popover trigger="overlay-trigger" trigger-action="click" side="right"
                                                         style="--width:75vw">
                                                <ion-content class="ion-padding">
                                                    <p><strong>
                                                        <IonIcon :icon="informationCircle" id="overlay-trigger"
                                                                 class="globalTextAlignedIcon"/>
                                                        Weniger arbeiten</strong></p>
                                                    <p>Für "Weniger arbeiten" kann das AZK abgebaut werden oder
                                                        Urlaubstage geplant werden.</p>

                                                    <p>Für {{ calendarMonth.format('MMMM') }} entsprechen
                                                        <strong>{{ pluralize(absencePlanningRS.missingBookings, 'Dienst', 'Dienste') }} weniger</strong>
                                                        (~{{ floatFormat(absencePlanningRS.missingHours) }}h) ungefähr
                                                        <strong>{{ pluralize(absencePlanningRS.requiredAbsences, 'Tag', 'Tagen') }}</strong>
                                                        mit Urlaub oder AZK Ausgleich (voraussichtlich
                                                        {{ absencePlanningRS.hourValueAbsence }}h/Tag*).
                                                    </p>
                                                    <sub>
                                                        <strong>*</strong> Dies ist eine Prognose. Die exakte Wertigkeit
                                                        steht erst zur Lohnabrechnung fest.
                                                    </sub>
                                                </ion-content>
                                            </ion-popover>

                                        </template>
                                    </IonLabel>
                                </IonCardContent>
                            </IonCard>

                            <div class="rich-month-calendar">
                                <MonthCalendarGrid
                                    :date="month"
                                    :showDetailsOnClick="false"
                                    v-on:date-click="onCalendarDateClick"
                                >
                                    <template v-slot="{ date, adjacent }">
                                        <div
                                            v-if="date && !adjacent && isModAbsent(date.id)"
                                            class="calendar-date-content absence"
                                            :class="{absencePlanningType: isAbsencePlanningType(getModAbsenceType(date.id) as AbsenceType)}"
                                        >{{
                                                isAbsencePlanningType(getModAbsenceType(date.id) as AbsenceType) ? getLabelForAbsenceType(getModAbsenceType(date.id) as AbsenceType, true) : ''
                                            }}
                                        </div>
                                        <div
                                            v-if="date && !adjacent && isBooked(date.id)"
                                            class="calendar-date-content booked"
                                        />
                                    </template>
                                </MonthCalendarGrid>
                                <MonthCalendarLegend mode="absences"/>
                                <Modal :model-value="!!absenceModificationModalValue"
                                       @view:didDismiss="absenceModificationModalValue = undefined"
                                       :title="formatKapitelDate(absenceModificationModalValue)">
                                    <RadioGroup
                                        v-if="absenceModificationModalValue"
                                        :items="absencePlanningAbsenceTypes"
                                        :model-value="getModAbsenceType(absenceModificationModalValue)"
                                        @update:modelValue="onSetAbsenceTypeClick"
                                        v-slot="{item} : { item:AbsenceType }"
                                    >
                                        {{ getLabelForAbsenceType(item) }}
                                    </RadioGroup>
                                    <IonButton type="reset" @click="onRemoveAbsenceClick" color="primary">Abwesenheit
                                        Löschen
                                    </IonButton>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </template>

            </div>
        </template>

        <template #actions>
            <ion-button
                color="primary"
                expand="block"
                :disabled="!!preventSubmitMessage"
                @click="onClickOk"
            >
                {{ preventSubmitMessage || 'Ok' }}
            </ion-button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '@/components/Modal.vue'
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonLabel,
    IonPopover,
    IonProgressBar
} from '@ionic/vue';
import {computed, defineProps, PropType, ref, Ref, watch} from 'vue';
import {generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import date, {formatKapitelDate, isWeekend} from "@/helper/datetime/date";

import {
    AutopilotPreferenceObject,
    fetchAutopilotPreferenceObject,
    fetchPlanningTemplate,
    getIconForWorktimeMode,
    getWorktimeModeLabel,
    getWorktimeModes,
    updateAutopilotPreference
} from "@/helper/autopilotPreference";
import {DataLoader, InstantDataLoader} from "@/helper/dataLoader";

import Skeleton from "@/components/Skeleton.vue";
import {floatFormat, pluralize, pluralizeUnitOnly} from '@/helper/amountFormatter';
import {Absence, AbsenceType, Day, PlanningStatus, PlanningTemplate, WorktimeMode} from "@/graphql/generated/graphql";
import {fetchPlanningStatus} from "@/helper/autopilotStatus";

import {kapitelDateString} from '@/graphql/kapitelTypes';
import PlusMinusInput from "@/components/PlusMinusInput.vue";
import {checkmarkCircleOutline, informationCircle, informationCircleOutline} from "ionicons/icons";
import MonthCalendarLegend from "@/components/MonthCalendarLegend.vue";
import {DayMap, fetchDaysForMonth, generateDay, toDayMap} from "@/helper/day";
import {
    absencePlanningAbsenceTypes,
    generateAbsence,
    getLabelForAbsenceType,
    removeAbsence,
    setAbsence
} from "@/helper/absence";
import RadioGroup from "@/components/RadioGroup.vue";
import AZKValue from "@/views/components/Planning/AZKValue.vue";
import {useEmployeeStore} from "@/store/employee";
import MonthCalendarGrid from "@/components/MonthCalendarGrid.vue";

const model = defineModel({type: Boolean})

const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    migrationMode: {
        type: Boolean,
        required: false,
        default: false
    }
});

const initialDataLoader = new DataLoader();


const calendarMonth = computed(() => generateCalendarMonth(props.month));

const employee = ref()
initialDataLoader.add(
    async () =>  employee.value = await useEmployeeStore().getEmployee()
)



/**
 * worktime target
 */

const planningTemplate: Ref<undefined | PlanningTemplate> = ref(undefined)
const autopilotPreference: Ref<AutopilotPreferenceObject | undefined> = ref();
const planningStatus: Ref<PlanningStatus | undefined> = ref()
initialDataLoader.add([
    async () => {
        autopilotPreference.value = await fetchAutopilotPreferenceObject(calendarMonth.value)
    },
    async () => planningStatus.value = await fetchPlanningStatus(calendarMonth.value),
    async () => planningTemplate.value = await fetchPlanningTemplate(calendarMonth.value)
]);

const minBookings = computed(() => planningTemplate.value ? planningTemplate.value.bookingTargetMin : 0)
const maxBookings = computed(() => planningTemplate.value ? planningTemplate.value.bookingTargetMax : undefined)

const saveAutopilotPreference = () => {
    const bookingTarget = targetBookingInput.value;
    const absenceDays = absencePlanningRS.value.requiredAbsences
    updateAutopilotPreference(calendarMonth.value, {bookingTarget, absenceDays});
}

const isSelectableWorktimeMode = (mode: WorktimeMode) => {
    if (
        mode === WorktimeMode.More
        && planningTemplate.value?.bookingTargetSpotOn == planningTemplate.value?.bookingTargetMax
    ) {
        return false
    } else if (
        mode === WorktimeMode.Less
        && planningTemplate.value?.bookingTargetSpotOn == planningTemplate.value?.bookingTargetMin
    ) {
        return false
    }

    return true

}

const targetBookingInput = ref(0)
const worktimeModeInput: Ref<undefined | WorktimeMode> = ref(undefined);

const getSelectedWorktimeMode = computed((): WorktimeMode | undefined => worktimeModeInput.value || inferWorktimeModeFromTargetBookings(targetBookingInput.value))
const isSelectedWorktimeMode = (mode: WorktimeMode) => getSelectedWorktimeMode.value === mode;

const inferTargetBookingsFromWorktimeMode = (worktimeMode: WorktimeMode) => {
    const t = planningTemplate.value
    if (!t) {
        return;
    }

    const factor = 0.1
    const diff = Math.ceil(t.bookingTargetSpotOn * factor)

    switch (worktimeMode) {
        case WorktimeMode.Less:
            return t.bookingTargetSpotOn - diff;
        case WorktimeMode.SpotOn:
            return t.bookingTargetSpotOn;
        case WorktimeMode.More:
            return maxBookings.value ? Math.min(t.bookingTargetSpotOn + diff, maxBookings.value) : t.bookingTargetSpotOn;
    }
}
const syncTargetBookingsFromWorktimeMode = () => {
    if (!worktimeModeInput.value) {
        return false;
    }
    const target = inferTargetBookingsFromWorktimeMode(worktimeModeInput.value)
    if (target === undefined || target === targetBookingInput.value) {
        return false;
    }
    targetBookingInput.value = target
    return true
}
const inferWorktimeModeFromTargetBookings = (targetBookings: number) => {
    const t = planningTemplate.value
    if (!t) {
        return;
    }

    switch (true) {
        case targetBookings === t.bookingTargetSpotOn:
            return WorktimeMode.SpotOn;
        case targetBookings > t.bookingTargetSpotOn:
            return WorktimeMode.More;
        case targetBookings < t.bookingTargetSpotOn:
            return WorktimeMode.Less;
    }
}
const syncWorktimeModeFromTargetBookings = () => worktimeModeInput.value = inferWorktimeModeFromTargetBookings(targetBookingInput.value)

const onClickWorktimeModeItem = (item: WorktimeMode, value: boolean) => {
    worktimeModeInput.value = item;
    onUpdateWorktimeMode()
}

const onUpdateTargetBookings = () => syncWorktimeModeFromTargetBookings()
const onUpdateWorktimeMode = () => syncTargetBookingsFromWorktimeMode()


/**
 * absence planning
 */
const showAbsencePlanningSubSection = computed(() => isSelectedWorktimeMode(WorktimeMode.Less))

const isAbsencePlanningType = (type: AbsenceType) => absencePlanningAbsenceTypes.includes(type)

const days: Ref<DayMap> = ref(new Map() as DayMap)
const getDay = (date: kapitelDateString): Day | undefined => days.value.get(date)
const isBooked = (date: kapitelDateString) => getDay(date)?.booking

const modifiedDays: Ref<DayMap> = ref(new Map() as DayMap)
const getModDay = (date: kapitelDateString) => modifiedDays.value.get(date) || getDay(date)
const getModAbsence = (date: kapitelDateString) => getModDay(date)?.absence
const isModAbsent = (date: kapitelDateString) => !!getModAbsence(date)
const getModAbsenceType = (date: kapitelDateString) => getModAbsence(date)?.type
const setModAbsence = (date: kapitelDateString, type: AbsenceType) => {
    const day = generateDay(date)
    day.absence = generateAbsence(date, type)
    modifiedDays.value.set(date, day)
}
const removeModAbsence = (date: kapitelDateString) => {
    const day = generateDay(date)
    modifiedDays.value.set(date, day)
}
const saveAbsencePlanningAbsences = () => {
    modifiedDays.value.forEach((day, date) => {
        if (!day.absence && getDay(date)?.absence) {
            removeAbsence(getDay(date)?.absence as Absence)
        } else if (day.absence) {
            setAbsence(day.absence)
        }
    })
    modifiedDays.value.clear()
}

const absenceModificationModalValue: Ref<kapitelDateString | undefined> = ref(undefined)
let lastUsedAbsenceType = AbsenceType.Vacation
const onCalendarDateClick = ({date}: { date: kapitelDateString }) => {
    if (isBooked(date)) return
    if (isModAbsent(date) && !isAbsencePlanningType(getModAbsenceType(date) as AbsenceType)) return
    if (isWeekend(date) /* TODO: || isHoliday */) return

    if (!isModAbsent(date)) {
        setModAbsence(date, lastUsedAbsenceType)
    } else {
        absenceModificationModalValue.value = date
    }
}
const onRemoveAbsenceClick = () => {
    if (!absenceModificationModalValue.value) return

    removeModAbsence(absenceModificationModalValue.value)
    absenceModificationModalValue.value = undefined
}
const onSetAbsenceTypeClick = (type: AbsenceType) => {
    if (!absenceModificationModalValue.value) return

    if (getModAbsenceType(absenceModificationModalValue.value) !== type) {
        setModAbsence(absenceModificationModalValue.value, type)
        lastUsedAbsenceType = type
    }
    absenceModificationModalValue.value = undefined
}

const absencePlanningRS = computed(() => {
    const t = planningTemplate.value

    const hourValueBooking = t?.averageShiftDuration || 0
    const hourValueAbsence = t?.absenceDayHours || 0

    const missingBookings = t ? t.bookingTargetSpotOn - targetBookingInput.value : 0
    const missingHours = missingBookings * hourValueBooking

    const plannedAbsences = calendarMonth.value.getKapitelDates().map(getModAbsenceType).filter(t => !!t).filter(isAbsencePlanningType).length
    const requiredAbsences = t ? Math.round(missingHours / t.absenceDayHours) : 0
    const complete = plannedAbsences >= requiredAbsences
    const missingAbsences = complete ? 0 : requiredAbsences - plannedAbsences

    return {
        hourValueBooking,
        hourValueAbsence,
        missingBookings,
        missingHours,
        plannedAbsences,
        requiredAbsences,
        complete,
        missingAbsences
    }
})

watch(() => showAbsencePlanningSubSection.value, async (value) => {
    if (value) {
        modifiedDays.value.clear()
        days.value = toDayMap(await fetchDaysForMonth(calendarMonth.value))
    }
})
initialDataLoader.add(
    async () => {
        if (showAbsencePlanningSubSection.value) {
            days.value = toDayMap(await fetchDaysForMonth(calendarMonth.value))
        }
    }
)


/**
 * overall
 */

const preventSubmitMessage = computed(() => {
    if (!showAbsencePlanningSubSection.value) return undefined;
    return absencePlanningRS.value.complete
        ? undefined
        : `Noch ${pluralize(absencePlanningRS.value.missingAbsences, 'Abwesenheit', 'Abwesenheiten')}`
})

const onClickOk = () => {
    model.value = false
    saveAutopilotPreference()
    saveAbsencePlanningAbsences()
}


/**
 * reset
 */

const reset = () => initialDataLoader.reload()
    .then(() => {
        // INIT
        const targetBookingInitialValue = (() => {
            if (autopilotPreference.value?.bookingTarget) {
                // already saved bookingTarget
                return autopilotPreference.value?.bookingTarget
            } else if (planningStatus.value?.autopilotStatus?.bookingTarget) {
                // take infered from worktimemode setting
                return planningStatus.value?.autopilotStatus?.bookingTarget
            } else {
                // take suggestion based on last month
                return planningTemplate.value?.bookingTargetSuggestion
            }
        })()
        targetBookingInput.value = targetBookingInitialValue || 0
        syncWorktimeModeFromTargetBookings()
    })


</script>

<style lang="scss" scoped>
// RR-Item as Radio
.worktime-mode-radio-group {
    ion-radio {
        .inline-svg {
            display: inline-block;
            margin-right: 0.5rem;
            width: 1rem;
            height: 1rem;
            color: var(--ion-color-primary);
        }
    }
}

// FOLDING BOX
.foldableContainer {
    overflow: hidden;
}

.foldable {
    margin-top: -500px;
    transition: all 1s;
}

.foldable.expanded {
    margin-top: 0;

}

// CALENDAR
.rich-month-calendar {
    background-color: var(--ion-color-light);
    border-radius: 1rem;
    padding: 0.5em 1em 1em 1em;
    width: 100%;
    margin-top: 0.5em;
}

.calendar-date-content {
    $SIZE: 0.5em;
    width: $SIZE;
    height: $SIZE;
    border-radius: 50%;
    transform: translateY(1.1em);

    &.absence {
        background-color: var(--ion-color-red);
    }

    &.absencePlanningType {
        $SIZE: 0.9rem;
        width: $SIZE;
        height: $SIZE;
        transform: translateY(calc($SIZE + 0.3em));
        overflow: hidden;
        text-align: center;
        line-height: $SIZE;
        font-size: $SIZE;
        color: var(--ion-color-light);
        font-weight: var(--custom-font-weight-bold);
    }

    &.booked {
        background-color: var(--ion-color-black-tint);
    }

    &.available {
        background-color: var(--ion-color-green);
    }
}

.globalTextAlignedIcon {
    vertical-align: middle;
    margin-bottom: 0.1em;
}
</style>
