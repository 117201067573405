import { Capacitor } from "@capacitor/core";
import { Mute } from "@capgo/capacitor-mute";
import { kapitelErrorHandler } from "@/helper/error";
import { ChatCompletionStream } from "openai/lib/ChatCompletionStream";

declare global {
    interface Window {
        webkitAudioContext: typeof AudioContext;
    }
}

// Helper to decode base64 to ArrayBuffer (PCM16)
function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// Create or resume an AudioContext
function initAudioContext(): AudioContext {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 24000,
    });

    // Ensure the AudioContext is resumed on user interaction (especially for iOS)
    const resumeAudioContext = () => {
        if (audioContext.state === "suspended") {
            audioContext.resume();
        }
    };
    window.addEventListener("touchend", resumeAudioContext, false);
    window.addEventListener("click", resumeAudioContext, false);

    return audioContext;
}

function closeAudioContext(audioContext: AudioContext) {
    audioContext.close();
}

// Keep references in the global scope to stop them
let currentAudioContext: AudioContext | null = null;
let currentWorkletNode: AudioWorkletNode | null = null;

/**
 * Plays streaming PCM16 audio data from OpenAI ChatCompletionStream using an AudioWorklet.
 *
 * @param stream - an async iterator of ChatCompletionStream chunks.
 * @param cb - callback invoked after the stream finishes.
 */
export const audioContextPlay = async (stream: any, cb: () => void) => {
    try {

        // Stop any existing audio
        stopAudio();

        // Initialize new AudioContext
        currentAudioContext = initAudioContext();

        // Load the AudioWorklet processor
        // Make sure the path to `pcm16-worklet-processor.js` is correct:
        await currentAudioContext.audioWorklet.addModule(
            "/worklets/audioWorklet.js"
        );

        // Create a new AudioWorkletNode using our registered processor name
        currentWorkletNode = new AudioWorkletNode(
            currentAudioContext,
            "pcm16-worklet-processor"
        );


        // Connect the worklet node to the destination so we can hear audio
        currentWorkletNode.connect(currentAudioContext.destination);

        // Read chunks from the stream
        for await (const chunk of stream) {
            const audioDelta = chunk?.choices?.[0]?.delta?.audio;
            if (audioDelta?.data) {
                // Convert base64-encoded PCM16 to ArrayBuffer
                const pcmArrayBuffer = base64ToArrayBuffer(audioDelta.data);

                // Pass PCM16 data to our AudioWorklet
                currentWorkletNode.port.postMessage(pcmArrayBuffer);
            }
        }

        // Finished reading the entire stream
        if (cb) cb();
    } catch (error) {
        console.error("Error streaming audio data:", error);
        if (cb) cb();
    }
};

/**
 * Stops audio playback and closes the current AudioContext.
 */
export const stopAudio = () => {
    // There is no direct "stop" method on an AudioWorkletNode,
    // but we can disconnect the node and close the context.
    if (currentWorkletNode) {
        currentWorkletNode.disconnect();
        currentWorkletNode = null;
    }
    if (currentAudioContext) {
        closeAudioContext(currentAudioContext);
        currentAudioContext = null;
    }
};
