<script setup lang="ts">
import {ref, computed, watch, PropType} from 'vue';
import date from "@/helper/datetime/date";
import {floatFormat} from "@/helper/amountFormatter";
import {Employee} from "@/graphql/generated/graphql";

const props = defineProps({
    employee: {
        type: Object as PropType<Employee|undefined>,
        required: true,
        default: undefined
    },
    compact: {
        type: Boolean,
        required: false,
        default: false
    }
})

const azkValue = computed(() => props.employee?.aZK?.value || 0)
const azkValid = computed(() => props.employee?.aZK?.validMonth || undefined)

</script>

<template>
    <span v-if="compact">AZK: {{floatFormat(azkValue)}}h</span>
    <span v-else>Aktuelles AZK: {{floatFormat(azkValue)}}h (Stand {{azkValid ? date.formatKapitelDate(azkValid, 'MMMM y') : 'unbekannt'}})</span>
</template>

<style lang="scss">
</style>
