import { FCM } from '@capacitor-community/fcm'
import { useAuthStore } from "@/store/auth";
import { mutation } from "@/graphql/client";
import { gql } from "@urql/vue";
import { isPlatform } from '@ionic/vue';
import {Capacitor} from "@capacitor/core";


export const deviceProfileRefresh = async (silent = false) => {
    if(isAvailable()){
        const authStore = useAuthStore()

        let currentlyValidDeviceToken
        try {
            currentlyValidDeviceToken = (await FCM.getToken()).token
            // if (refreshInstantly) {
            //     currentlyValidDeviceToken = (await FCM.refreshToken()).token
            // }
        } catch (e) {
            currentlyValidDeviceToken = 'FCM.getToken error'
            // console.error(e)
        }
        const localStorageToken = authStore.getFCMToken()
        const devicePlatform = getDevicePlatform() || ''

        authStore.setFCMToken(currentlyValidDeviceToken)

        await setDeviceProfile(currentlyValidDeviceToken, devicePlatform, localStorageToken, silent)
    }
}

const isAvailable = () => Capacitor.isPluginAvailable('FCM') && getDevicePlatform() !== undefined /* && Capacitor.isNativePlatform() */

const setDeviceProfile = async (newToken: string, platform: string, oldToken: string|undefined, silent = false) => {
    const MUTATION = gql`
        mutation SetDeviceProfile(
            $newFirebaseCloudMessagingRegistrationToken: String!
            $oldFirebaseCloudMessagingRegistrationToken: String
            $platform: String!
        ) {
            setDeviceProfile(
                newDeviceProfile: {
                    firebaseCloudMessagingRegistrationToken: $newFirebaseCloudMessagingRegistrationToken
                    platform: $platform
                }
                oldFirebaseCloudMessagingRegistrationToken: $oldFirebaseCloudMessagingRegistrationToken
            ) {
                firebaseCloudMessagingRegistrationToken
                platform
            }
        }
    `;

    const result = await mutation(MUTATION, {
        oldFirebaseCloudMessagingRegistrationToken: oldToken,
        newFirebaseCloudMessagingRegistrationToken: newToken,
        platform: platform,
    }, silent);

    const success = result?.data;

    if (!success) {
        throw new Error("error while storing device profile");
    }
};

export const getDevicePlatform = (): string | undefined => {
    if (isPlatform('ios')) {
        return 'ios'
    }

    if (isPlatform('android')) {
        return 'android'
    }

    return undefined
};
