import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {Jobsite, TimesheetToDo} from "@/graphql/generated/graphql";
import datetime from "@/helper/datetime/datetime";
import date from "@/helper/datetime/date";
import {pluralize} from "@/helper/amountFormatter";
import {getJobsiteDescription} from "@/helper/jobsite";
import { kapitelDateString } from "@/graphql/kapitelTypes";

export const findByBookingId = (needle: number, haystack: TimesheetToDo[]): TimesheetToDo | null => {
  const ll = haystack.filter((tstd) => {
    const storeBooking = tstd.booking
    if (storeBooking) {
      if (storeBooking.id == needle) {
        return true
      }
    }
  });
  if(ll.length!=1){
    return null
  }
  return ll[0]
}

const equalTimes = (a: TimesheetToDo, b: TimesheetToDo) => {

  if (!a.timesheet || !b.timesheet) {
    return false
  }

  const sameBeginUntil = (
    datetime.isSame(a.timesheet?.begin, b.timesheet?.begin)
    && datetime.isSame(a.timesheet?.until, b.timesheet?.until)
  )

  if (!a.timesheet.breakBegin || !a.timesheet.breakUntil || !b.timesheet.breakBegin || !b.timesheet.breakUntil){
    return sameBeginUntil
  }

  return (
    sameBeginUntil
    && datetime.isSame(a.timesheet?.breakBegin, b.timesheet?.breakBegin)
    && datetime.isSame(a.timesheet?.breakUntil, b.timesheet?.breakUntil)
  )
}

export const isModified = (timesheetToDo: TimesheetToDo, collection: TimesheetToDo[]): boolean => {
  const bookingId = timesheetToDo?.booking?.id
  if (bookingId) {
    const collectionTstd = findByBookingId(bookingId, collection)
    if (!collectionTstd) {
      return false
    }
    return !equalTimes(timesheetToDo, collectionTstd)
  }
  return false
}

export interface TimesheetSummary {
    kapitelDate?: kapitelDateString,
    date: string,
    begin: string,
    until: string,
    breakBegin: string,
    breakUntil: string,
    modified: boolean,
    ref: TimesheetToDo|undefined,
    jobsiteDescription: string,
    jobsite?: Jobsite
}
export const getSummary = (timesheetToDo: TimesheetToDo, referenceCollection: TimesheetToDo[]):TimesheetSummary =>
{

  if ((
    !timesheetToDo.timesheet
    || !timesheetToDo.timesheet.begin
    || !timesheetToDo.timesheet.until
  )) {
    return {
        kapitelDate: undefined,
        date: "",
        begin: "",
        until: "",
        breakBegin: "",
        breakUntil: "",
        modified: false,
        ref: undefined,
        jobsiteDescription: "",
        jobsite: undefined,
    }
  }

  return {
    kapitelDate: datetime.convertDateTime2Date(timesheetToDo?.timesheet?.begin),
    date: date.formatKapitelDate(datetime.convertDateTime2Date(timesheetToDo?.timesheet?.begin), 'DD'),
    begin: datetime.formatKapitelDateTime(timesheetToDo?.timesheet?.begin, datetime.kapitelDateTimeFormatTime),
    until: datetime.formatKapitelDateTime(timesheetToDo?.timesheet?.until, datetime.kapitelDateTimeFormatTime),
    breakBegin: timesheetToDo.timesheet.breakBegin ? datetime.formatKapitelDateTime(timesheetToDo?.timesheet?.breakBegin, datetime.kapitelDateTimeFormatTime): "",
    breakUntil: timesheetToDo.timesheet.breakUntil ? datetime.formatKapitelDateTime(timesheetToDo?.timesheet?.breakUntil, datetime.kapitelDateTimeFormatTime): "",
    modified: isModified(timesheetToDo, referenceCollection),
    ref: timesheetToDo,
    jobsiteDescription : timesheetToDo.booking?.jobsite ? getJobsiteDescription(timesheetToDo.booking.jobsite): "",
    jobsite: timesheetToDo.booking?.jobsite,
  }
}

export const timesheetToDoButtonLabel = (timesheetToDos: TimesheetToDo[]) : string  => {

    let result = pluralize(timesheetToDos.length, "Dienst", " Dienste")

    if (timesheetToDos.length === 1) {
        result += ' vom ' + date.formatKapitelDate(timesheetToDos[0].booking.date, 'd.L.')
    }

    result += ' Zeiterfassen'

    return result
}


export async function fetchTimesheetToDos(): Promise<any> {
  const result = await query(
    gql`query GenerateTimesheetToDos {
              generateTimesheetToDos {
                booking {
                  id,
                  date,
                  jobsite{
                    id,
                    client {
                      name
                      abbreviation
                    }
                    house {
                      name
                      abbreviation
                    }
                    name
                    abbreviation
                  }
                },
                timesheet {
                  begin
                  breakBegin
                  breakUntil
                  signateur
                  signateurEmail
                  signature
                  until
                }
              }
            }`
  )

  return result?.data?.generateTimesheetToDos
}

export async function saveTimesheet(timesheetToDo: TimesheetToDo, silent=false): Promise<any> {
  const MUTATION = gql`
            mutation saveTimesheet (
                $bookingIdDings: Int!,
                $beginDings: DateTime!,
                $untilDings: DateTime!,
                $breakBeginDings: DateTime,
                $breakUntilDings: DateTime,
                $signateurDings: String!,
                $signateurEmailDings: String!,
                $signatureDings: String!

            ) {
                saveTimesheet (
                  bookingId: $bookingIdDings,
                  begin: $beginDings,
                  until: $untilDings,
                  breakBegin: $breakBeginDings,
                  breakUntil: $breakUntilDings,
                  signateur: $signateurDings,
                  signateurEmail: $signateurEmailDings,
                  signature: $signatureDings
                )
            }`

  const result = await mutation(
    MUTATION, {
    bookingIdDings: timesheetToDo?.booking?.id,
    beginDings: timesheetToDo?.timesheet?.begin,
    untilDings: timesheetToDo?.timesheet?.until,
    breakBeginDings: timesheetToDo?.timesheet?.breakBegin || undefined,
    breakUntilDings: timesheetToDo?.timesheet?.breakUntil || undefined,
    signateurDings: timesheetToDo?.timesheet?.signateur,
    signateurEmailDings: timesheetToDo?.timesheet?.signateurEmail,
    signatureDings: timesheetToDo?.timesheet?.signature,
  },
  silent
  )

  const success = result?.data

  if (!success || !success.saveTimesheet) {
    throw new Error("error while storing time sheet")
  }

  return success
}

export function cmpTimesheetBookingBegin(t1: TimesheetToDo, t2: TimesheetToDo) {
    return date.cmpKapitelDate(t1.booking.date, t2.booking.date)
}
