<script setup lang="ts">

import {
    IonContent,
    IonFooter,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonButton,
    IonItem,
    IonLabel,
    IonList,
    IonSearchbar,
    IonListHeader,
    IonIcon,
    IonRadio
} from "@ionic/vue"
import {ellipseOutline, checkmarkCircle, informationCircleOutline} from 'ionicons/icons';
import {JobsiteCategorySegment, fetchJobsites} from "@/helper/jobsite"
import { PropType, Ref, ref, watch } from "vue"
import Modal from '@/components/Modal.vue'
import { Jobsite } from "@/graphql/generated/graphql";
import { JobsiteCategory } from "@/graphql/kapitelTypes";
import {getLabelForJobsiteCategory} from "@/helper/jobsiteCategory"
import JobsiteDetails from "@/views/JobsiteDetails.vue";

const props = defineProps({
    jobsites: {
        type: Array as PropType<Jobsite[]>,
        required: false,
        default: () => []
    },
    heading: {
        type: String,
        default: "Stationsauswahl"
    }
})

const emit = defineEmits({
    select:  (jobsite:Jobsite) => {}
})

const modelValue = defineModel({type: Boolean})

const jobsiteDetailModalData : Ref<number | undefined> = ref(undefined)

const onClick = (item: Jobsite) => {
    modelValue.value = false
    emit('select', item)
}

</script>

<template>
    <Modal
        v-model="modelValue"
        :title="props.heading"
        class="no-padding-left no-padding-right"
        ref="modal"
        :toolbar="true"
    >

        <IonList>
            <ion-item
                v-for="jobsite in props.jobsites"
                :detail="false"
                :key="jobsite.id"
                @click="onClick(jobsite)"
                :button="true"
                :class="{removed: jobsite.disabledEffectively}"
            >

                <ion-label class="app-padding-left">
                    <h3>{{ jobsite.name }}</h3>
                    <p>{{ jobsite.client?.abbreviation }}<span v-if="jobsite.house">, {{ jobsite.house?.abbreviation }}</span><span v-if="jobsite.additionalAmenImportantAbbreviations?.length">, {{jobsite.additionalAmenImportantAbbreviations.join(", ")}}</span></p>
                </ion-label>
                <ion-button
                    slot="end"
                    class="jobsiteInfo app-padding-right"
                    fill="clear"
                    color="secondary"
                    @click.stop="jobsiteDetailModalData=jobsite.id"
                >
                    <ion-icon
                        slot="icon-only"
                        :icon="informationCircleOutline"
                    />
                </ion-button>
            </ion-item>
        </IonList>
        <JobsiteDetails v-model="jobsiteDetailModalData" />

        <template v-slot:actions>
            <ion-button
                expand="block"
                color="primary"
            >Zurück</ion-button>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.removed {
    text-decoration: line-through;
}

ion-item {
    --padding-end: 0;
}
ion-button {
    --padding-end: 0;
}

.jobsite-selector-modal-scroller {
    height: 50vh;
    overflow: auto;
}

.jobsite-selector-searchbar {
    padding-left: 0;
    padding-right: 0;
}

.jobsite-selector-actions {
    padding-top: 1rem;
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
}

.alreadySelected {
    opacity: 0.75
}
</style>
