<template>
<div>
    <RichResponseListItem
        v-for="item in renderSet"
        iconType="image"
        :icon="item.icon"
        :title="item.title"
        :text="item.text"
        :key="item.jobsiteId"
        selectable
        :modelValue="selectedJobsiteId === item.jobsiteId"
        @update:modelValue="selectedJobsiteId = selectedJobsiteId === item.jobsiteId ? undefined : item.jobsiteId"
        style="margin-bottom: 10px /*@TWINKIIII*/"
    />
    <ion-button
        expand="block"
        color="primary"
        class="floating"
        :disabled="selectedJobsiteId === undefined || timesheetToDoJobsites.length==0"
        @click="onClickSubmit"
    >
        Zeiterfassung starten
    </ion-button>
    <TimesheetProcess
        v-model="modalValue"
        :selected-jobsite-id="selectedJobsiteId"
        v-on:done="onDone"
        />
</div>
</template>

<script setup lang="ts">
import RichResponseListItem from "./components/RichResponseListItem.vue"
import {IonButton,} from '@ionic/vue';
import {getImageForJobsite} from '@/helper/jobsite'
import {computed, ref, Ref} from "vue";
import {useTimesheetStore} from "@/store/timesheet"
import {pluralize} from "@/helper/amountFormatter";
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";
import {sendUserMessage} from "@/helper/chat/chatBL";
import {AInesAssistantType} from "@/graphql/generated/graphql";


const props = defineProps<{
    initData: number|string|undefined
}>()


const timesheetStore = useTimesheetStore()
const timesheetToDoJobsites = computed(()=> timesheetStore.getToDoJobsites)

const parseJobsiteId = () => {
    if(!props.initData){
        return undefined
    }

    let parsedJobsiteId;
    if(typeof props.initData === 'string'){
        parsedJobsiteId = Number(props.initData)
    }else{
        parsedJobsiteId = props.initData
    }

    return timesheetToDoJobsites.value.find((tstdj) => tstdj.jobsite.id === parsedJobsiteId)?.jobsite.id
}

const selectedJobsiteId = ref<number | undefined>(parseJobsiteId())
    
const modalValue: Ref<boolean|undefined> = ref(undefined)

const onClickSubmit = () => {
    if(selectedJobsiteId.value){
        modalValue.value=true
    }
}

const onDone = () => {
    sendUserMessage("Vielen Dank, ich konnte über das Interface meine Zeiten erfassen.", {messageExpertAssistant: AInesAssistantType.Timesheet, isScriptedContent: true})
}

const renderSet = computed(() => {

    return timesheetToDoJobsites.value.map(jobsite => {
        const todos = timesheetStore.getSignableTimesheetTodosForJobsiteId(jobsite.jobsite.id)

        return {
            jobsiteId: jobsite.jobsite.id,
            icon: getImageForJobsite(jobsite.jobsite),
            title: jobsite.jobsite?.name,
            text: pluralize(todos.length, 'Dienst', 'Dienste')
        }
    })
})
</script>
