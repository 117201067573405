<template>
<div>
    <RichResponseHeadline
        v-if="!!headline"
        :title="headline"
    />
    
    <MonthCalendarForDays
        :month="startOfMonth(props.initData)"
        :days="days"
    />

</div>
</template>

<script setup lang="ts">
import RichResponseHeadline from "./components/RichResponseHeadline.vue";
import {Availability, Day} from "@/graphql/generated/graphql";
import {computed, PropType, Ref, ref, watch} from "vue";
import { kapitelDateString } from "@/graphql/kapitelTypes";
import { DataLoader } from "@/helper/dataLoader";
import { startOfMonth, endOfMonth, formatMonthFromKapitelDate, getYearFromKapitelDate } from '@/helper/datetime/date';
import { fetchDaysForDateRange } from "@/helper/day";
import {hasShifts} from "@/helper/availability";
import Modal from "@/components/Modal.vue";
import date from "@/helper/datetime/date";
import CalendarDetail from "@/views/CalendarDetail/CalendarDetail.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import MonthCalendarLegend from "@/components/MonthCalendarLegend.vue";
import MonthCalendarForDays from "@/components/MonthCalendarForDays.vue";

const props = defineProps({
    // month start
    initData: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    headline: {
        type: [String, null] as PropType<string|null>,
        required: false,
        default: (props:{initData:kapitelDateString}) => `${formatMonthFromKapitelDate(props.initData)} ${getYearFromKapitelDate(props.initData)}`
    }
})

const days = ref<Day[]>([])

const loader = new DataLoader(
    async () => {
        const from = startOfMonth(props.initData)
        const to = endOfMonth(from)

        days.value = await fetchDaysForDateRange(from, to)
    }
)

const init = () => {
    days.value = []
    loader.load()
}

// refresh content
useGlobalEmitter().on('day:mutated', (d) => {
    if (Object.keys(days.value).includes(d)) {
        loader.load()
    }
})


// re-init on prop change
watch(() => props.initData, init)
init()
</script>

<style scoped  lang="scss">
</style>
