<script setup lang="ts">
import { kapitelDateString } from "@/graphql/kapitelTypes"
import { CalendarDate, generateCalendarDate } from "@/helper/calendar/calendarDate"
import { weekdays, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isBefore, isAfter } from "@/helper/datetime/date"
import {computed, PropType} from "vue";
import useEmitter from "@/helper/emitter";
import {Jobsite} from "@/graphql/generated/graphql";

const props = defineProps({
    // any date within the month
    date: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    showWeeks: {
        type: Boolean,
        required: false,
        default: true
    },
    showWeekdays: {
        type: Boolean,
        required: false,
        default: true
    }
})

const emit = defineEmits({
    'date-click': (param: {date:kapitelDateString}) => true
})
const onClick = (date: kapitelDateString|undefined) => {
    if(date){
        emit("date-click", {date: date})
    }
}

type CellType = 'empty' | 'date' | 'weekday' | 'week'
type Cell = {
    type: CellType
    date?: CalendarDate
    label?: string,
    adjacentMonth?: boolean,
    weekend?: boolean,
    today?: boolean
}

// Add weekday row

const cells = computed(() => {
    const cells = [] as Cell[]
    const firstOfMonth = startOfMonth(props.date)
    const lastOfMonth = endOfMonth(props.date)
    const firstDate = startOfWeek(firstOfMonth)
    const lastDate = endOfWeek(lastOfMonth)
    let iterator = firstDate

    if (props.showWeekdays) {
        if (props.showWeeks) {
            cells.push({
                type: 'empty'
            })
        }

        weekdays().forEach(weekday => {
            cells.push({
                type: 'weekday',
                label: weekday.labelShort
            })
        })
    }
    
    while (iterator <= lastDate) {
        const calendarDate = generateCalendarDate(iterator)

        if (props.showWeeks && calendarDate.isFirstOfWeek) {
            cells.push({
                type: 'week',
                label: calendarDate.week.toString()
            })
        }

        cells.push({
            type: 'date',
            date: calendarDate,
            label: calendarDate.day.toString(),

            adjacentMonth: isBefore(iterator, firstOfMonth) || isAfter(iterator, lastOfMonth),
            weekend: calendarDate.isWeekend,
            today: calendarDate.isToday
        })

        iterator = addDays(iterator, 1)
    }

    return cells
});
</script>

<template>
<div
    class="month-calendar"
    :class="{'show-weeks': props.showWeeks}"
>
    <div
        class="cell"
        :class="{
            'adjacent-month': cell.adjacentMonth,
            [cell.type]: true,
            'weekend': cell.weekend,
            'today': cell.today
        }"
        v-for="cell in cells"
        v-on:click="() => onClick(cell.date?.id)"
    >
        <div class="background">
            <slot
                name="background"
                :date="cell.date"
                :type="cell.type"
                :adjacent="cell.adjacentMonth"
            />
        </div>
        <div class="label">
            <slot
                name="label"
                :date="cell.date"
                :type="cell.type"
                :adjacent="cell.adjacentMonth"
            >
                {{ cell.label }}
            </slot>
        </div>
        <div class="content">
            <slot
                :date="cell.date"
                :type="cell.type"
                :adjacent="cell.adjacentMonth"
            />
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.month-calendar {
    display: flex;
    flex-wrap: wrap;

    > .cell {
        min-height: 3em; // Twinki said so
    }

    > .cell {
        flex: 0 1 calc(100% / 7);
    }

    &.show-weeks {
        > .cell {
            flex: 0 1 calc(100% / 8);
        }
    }

    > .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &:before{
            content: '';
            display: block;
            padding-top: 100%;
        }

        > * {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: inherit;
        }

        &.weekday,
        &.week {
            color: var(--ion-color-grey);
        }

        &.weekend {
            color: var(--ion-color-grey-tint);
            font-weight: var(--custom-font-weight-medium);
        }

        &.today {
            color: var(--ion-color-red);
            font-weight: var(--custom-font-weight-medium);
        }

        &.adjacent-month {
            color: var(--ion-color-grey-tint-1);
        }
    }
}
</style>
