<script setup lang="ts">
import {computed, onBeforeUpdate, onMounted, onUpdated, ref} from "vue";
import {IonIcon} from "@ionic/vue";
import {useAInesObjectiveStore} from "@/helper/chat/ainesObjectives";
import {
    AInesAssistantType,
    AInesObjective,
    AInesObjectiveState,
    AInesObjectiveSubject,
    Booking, PlanningStatus,
    TimesheetToDo
} from "@/graphql/generated/graphql";
import RichResponseListItem from "./RichResponses/components/RichResponseListItem.vue";
import date from "@/helper/datetime/date";
import Skeleton from "@/components/Skeleton.vue";
import {iconType2Icon} from "@/helper/icon";
import ProgressCircle from "@/components/ProgressCircle.vue";
import {fetchPlanningStatus} from "@/helper/autopilotStatus";
import {CalendarMonth, generateCalendarMonth} from "@/helper/calendar/calendarMonth";
import {AssistantRunConfig} from "@/helper/chat/assistantRun/assistantRunConfig";
import {kapitelDateString} from "@/graphql/kapitelTypes";
import { isElementBottomOutOfBoundsInScrollContainer, getVerticalDifferencInPx } from '@/helper/dom'
import Transitionator from "../components/transitions/Transitionator.vue";

const props = defineProps({
    blur: {
        type: Boolean,
        required: false,
        default: false
    }
})

const emit = defineEmits(["avatar:click", "objective:click"]);
const isLoading = ref(false)
const itemRefs = ref([])
const isActionNeededScrollHintVisible = ref(false)
const scrollElement = ref()

const setItemRef = comp => comp ? itemRefs.value.push(comp) : void 0

const store = useAInesObjectiveStore();

const showProgress = (objective: AInesObjective) => {
    const progress = store.planningObjectivesProgress?.get(objective.reference)
    let completed = false;
    if (progress && progress==1.0) {
        completed = true;
    }
    return objective.subject === AInesObjectiveSubject.Planning &&
        objective.state === AInesObjectiveState.InProgress &&
        !completed;
}

onMounted(async () => {
    isLoading.value = true
    await store.refetch();
    isLoading.value = false

    requestAnimationFrame(() => {
        updateNeedsActionScrollHint();
    })
});

onBeforeUpdate(() => {
    itemRefs.value = []
})

const sortedTitles: string[] = [
    'Erste Schritte',
    'Dienstplanung',
    //  Dienstvorschläge
    'Zeiterfassung',
    'Eingetragene Dienste',
    // Settings
    // Human Support
]

const objectiveSubjectTitlesMap = {
    [AInesObjectiveSubject.Planning]: 'Dienstplanung',
    [AInesObjectiveSubject.Timesheet]: 'Zeiterfassung',
    [AInesObjectiveSubject.RegisterBooking]: 'Eingetragene Dienste',
    [AInesObjectiveSubject.CandidateDemand]: 'Erste Schritte',
    [AInesObjectiveSubject.CandidateJobsites]: 'Erste Schritte',
    default: 'Sonstiges',
}

const sortGroupDependingOnSubject = (group: { subject: AInesObjectiveSubject, title: string, objectives: AInesObjective[] }) => {

    switch (group.subject) {
        case AInesObjectiveSubject.Planning:
            return {
                ...group,
                objectives: group.objectives.sort((a, b) => date.cmpKapitelDate(a.reference, b.reference))
            }
        case AInesObjectiveSubject.Timesheet:
            return {
                ...group,
                objectives: group.objectives.sort((a, b) => date.cmpKapitelDate(
                    a.reference,
                    b.reference
                    )
                )
            }
        case AInesObjectiveSubject.RegisterBooking:
            return {
                ...group,
                objectives: group.objectives.sort((a, b) => date.cmpKapitelDate(
                    a.reference,
                    b.reference
                    )
                )
            }
        // todo: dringende nach oben!
        // case AInesObjectiveSubject.HumanSupport:
        //     return {
        //         ...group,
        //         objectives: group.objectives.sort((a, b) => a.needsAction ? -1 : 1)
        //     }
        default:
            return group
    }
}


const objectivesGrouped = computed(() => {
    const groups = store.objectives.reduce((res, objective) => {
        const title: string = objectiveSubjectTitlesMap[objective.subject]
        res[title] = res[title] || {
            subject: objective.subject,
            title: title,
            objectives: []
        }

        res[title].objectives.push(objective)

        return res
    }, {})

    return sortedTitles.filter(
        title => groups[title]
    ).map(title => {
        const selectedGroup = groups[title]
        return sortGroupDependingOnSubject(selectedGroup)
    })

})

const getFirstInVisibleNeedsActionItem = () => {
    return itemRefs.value.find(itemComponent => {
        return itemComponent.props?.badge &&
            isElementBottomOutOfBoundsInScrollContainer(itemComponent.$el, scrollElement.value as HTMLElement)
    })
}

const updateNeedsActionScrollHint = () => {
    const isVisible = getFirstInVisibleNeedsActionItem() !== undefined
    isActionNeededScrollHintVisible.value = isVisible
}

const onClickScrollToAction = () => {
    const item = getFirstInVisibleNeedsActionItem()

    if (!item) {
        return
    }

    const delta = getVerticalDifferencInPx(item.$el, scrollElement.value)
    scrollElement.value.scrollTo({top: scrollElement.value.scrollTop + delta, behavior: 'smooth'});
}

defineExpose({
    updateNeedsActionScrollHint
})

</script>

<template>
    <div class="aines-container transparent no-padding-top no-padding-bottom" :class="{blur: props.blur}">
        <div ref="scrollElement" class="scroller app-padding-left app-padding-right" @scroll="updateNeedsActionScrollHint" >
            <h1 class="custom-ricarda">
                
                Aktuelles
                <img src="/logo.png" />
            </h1>
            <Skeleton
                v-if="isLoading"
                :items="['block--height-6em', 'block--height-6em', 'block--height-5em', 'block--height-6em', 'block--height-5em', 'block--height-6em', 'block--height-6em', 'block--height-6em']"
            />

            <div
                v-for="group in objectivesGrouped"
            >
                <h2>{{ group.title }}</h2>
                <RichResponseListItem
                    v-for="objective in group.objectives"
                    :key="objective.reference"
                    :title="objective.title"
                    :text="objective.text"
                    :icon-type="showProgress(objective) ? 'progress' : iconType2Icon(objective.icon).iconType"
                    :icon="showProgress(objective)? store.planningObjectivesProgress?.get(objective.reference) : iconType2Icon(objective.icon).iconId"
                    :icon-color="objective.needsAction ? 'primary' : undefined"
                    @click="emit('objective:click', objective)"
                    :type="objective.needsAction ? 'primary' : undefined"
                    :badge="objective.needsAction"
                    :ref="setItemRef"
                    badgeValue="!"
                >
                    <!--<template v-slot:end>
                        <ProgressCircle v-if="objective.subject===AInesObjectiveSubject.Planning & objective.state == AInesObjectiveState.InProgress"
                            class="listItemSuffixImage"
                            :stroke-width="15"
                            track-color="grey-tint-1"
                            :stroke-color="objective.needsAction ? 'primary-tint' : 'grey-tint-1'"
                            :glow="false"
                            :value=""
                        />
                    </template>-->
                </RichResponseListItem>
            </div>
        </div>
        <Transitionator :types="['opacity', 'scale']" easing="easeOutBounce">
            <div
                v-if="isActionNeededScrollHintVisible"
                class="needs-action-hint-button floating-big-pseudo"
                @click="onClickScrollToAction"
                style="transform: translate(-50%, -4em)"
            >
                Weitere
                <ion-icon src="/icons/caret-down.svg" />
            </div>
        </Transitionator>
    </div>
</template>

<style scoped lang="scss">
.aines-container {
    transition: filter 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    > .scroller {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;

    }

    .needs-action-hint-button {
        position: absolute;
        top: 100%;
        left: 50%;
        background-color: var(--ion-color-red-tint-3);
        color: var(--ion-color-primary);
        z-index: 300;
        // transform: translate(-50%, -4em);
        padding: 0.5em;
        border-radius: 1em;
        font-weight: var(--custom-font-weight-medium);
        display: flex;
        align-items: center;

        ion-icon {
            margin-left: 0.3em;
        }
    }

    h1 {
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
        margin-left: -0.1em;

        img {
            $SIZE: 0.6em;
            width: $SIZE;
            height: $SIZE;
            margin-left: 0.15em;
            margin-right: 0.25em;
            margin-top: -0.5em;
        }
    }

    h2 {
        margin-top: 2em;
        margin-bottom: 1em;
    }

    ion-item {
        margin-bottom: 1em;
    }

    &.blur {
        filter: blur(0.5em);

        &,
        * {
            pointer-events: none;
        }
    }
}


</style>
