<template>
<div>
    <Skeleton v-if="loader.pending.value" :items="['title','block--height-12rem','block--height-4rem','block--height-4rem']"></Skeleton>
    <template v-else>

        <template v-if="renderSet.status === PlanningMode.Uncontracted">
            <span>Fehlender Arbeitsvertrag</span>
        </template>
        <template v-else-if="renderSet.status === PlanningMode.AvailabilityPlanning || renderSet.status === PlanningMode.BookingPlanning">

            <!-- calendar - only if showCalendar -->
            <MonthCalendarForDays
                v-if="showCalendar && (renderSet.status === PlanningMode.BookingPlanning || startPlanningMode)"
                :month="calendarMonth.getId()"
                :days="days"
                style="flex-grow: 1; margin-bottom: 0.5em"
            />

            <!-- bookings & bookingTarget: focusMode status -->
            <RichResponseListItem
                v-if="calculatedFocusMode==='status' && (renderSet.status === PlanningMode.BookingPlanning || startPlanningMode)"
                :icon="bookingRS.count.toString()"
                :title="bookingRS.text"
                :text="planningRS.count.toString() + ' ' + planningRS.text"
                @click="planningRS.click"
                :button="!!planningRS.click"
            >
                <template v-slot:end>
                    <ProgressCircle
                        class="listItemSuffixImage"
                        :stroke-width="15"
                        track-color="grey-tint-1"
                        stroke-color="black"
                        :value="bookingRS.completionRatio"
                        :glow="false"
                    />
                </template>
            </RichResponseListItem>
            <!-- bookings & bookingTarget: focusMode settings -->
            <RichResponseListItem
                v-else-if="calculatedFocusMode==='settings' && (renderSet.status === PlanningMode.BookingPlanning || startPlanningMode)"
                :icon="planningRS.count.toString()"
                icon-color="primary"
                :title="planningRS.text"
                :text="bookingRS.count.toString() + ' ' + bookingRS.text"
                @click="planningRS.click"
                :button="!!planningRS.click"
            >
                <template v-slot:end>
                    <IonIcon :icon="planningRS.icon" color="primary" class="listItemSuffixImage"></IonIcon>
                </template>
            </RichResponseListItem>

            <!-- absencePlanning -->
            <RichResponseListItem
                v-if="absencePlanningRS.absencePlanningMissing > 0 && (renderSet.status === PlanningMode.BookingPlanning || startPlanningMode)"
                icon-color="primary"
                icon-type="ion"
                :icon="warning"
                title="Zu wenig Abwesenheiten"
                :text="`Für das aktuelle Planungsziel fehlen noch ${absencePlanningRS.absencePlanningTarget - absencePlanningRS.absencePlanningCount} Abwesenheiten`"
                :button="true"
                @click="showModalFlags.worktimePreferences = true"
            >
                <template v-slot:end>
                    <ProgressCircle
                        class="listItemSuffixImage"
                        :stroke-width="15"
                        track-color="grey-tint-1"
                        stroke-color="primary"
                        :value="absencePlanningRS.absencePlanningCount / absencePlanningRS.absencePlanningTarget"
                        :glow="false"
                    />
                </template>
            </RichResponseListItem>

            <!-- availabilities -->
            <RichResponseListItem
                v-if="(renderSet.status === PlanningMode.BookingPlanning || startPlanningMode)"
                icon-color="green"
                :icon-type="availabilityRS.iconType"
                :icon="availabilityRS.icon"
                :title="availabilityRS.label"
                :text="availabilityRS.sublabel"
            />

            <!-- jobsites - only if planning or startPlanningMode -->
            <RichResponseListItem
                v-if="renderSet.status === PlanningMode.BookingPlanning || startPlanningMode"
                :title="jobsiteRS.label"
                :icon="jobsiteRS.icon"
                :icon-type="jobsiteRS.iconType"
                icon-color="grey"
                :text="jobsiteRS.sublabel"
                @click="() => showModalFlags.jobsitePreferences = true"
                :button="true"
            />

            <!--            <CalendarModal v-model="showModalFlags.listCalendar" :initial-date="calendarMonth.begin"></CalendarModal>-->

            <!-- jobsite expansion trigger item - only if prop set AND suggestions available-->
            <RichResponseListItem
                v-if="(renderSet.status === PlanningMode.BookingPlanning || startPlanningMode) && props.showJobsiteSuggestions && jobsiteSuggestionRS.amount > 0"
                title="Stationsvorschläge"
                :text="jobsiteSuggestionRS.label"
                @click="() => showModalFlags.jobsiteSuggestions = true"
                :button="true"
                :icon="jobsiteSuggestionRS.amount.toString()"
                icon-color="yellow"
            />

            <!-- availablity expansion trigger item - only if prop set AND suggestions available-->
            <RichResponseListItem
                v-if="props.showAvailabilitySuggestions && availabilitySuggestionRS.amount > 0"
                title="Verfügbarkeitsvorschläge"
                :text="'im ' + availabilitySuggestionRS.shifts"
                @click="() => showModalFlags.listCalendar = true"
                :button="true"
                :icon="availabilitySuggestionRS.amount.toString()"
                icon-color="yellow"
            />

            <!-- register booking trigger item - only if prop set-->
            <RichResponseListItem
                v-if="props.showRegisterBooking"
                title="Abgesprochene Dienste eintragen"
                text="Neue Dienste mit der Station absprechen und direkt planen"
                @click="() => showModalFlags.registerBooking = true"
                :button="true"
                :icon="addCircleOutline"
                iconType="ion"
                icon-color="yellow"
            />

            <!-- start planning required - only if unplanned AND not in "startPlanningMode" modal-->
            <RichResponseListItem
                v-if="renderSet.status === PlanningMode.AvailabilityPlanning && !props.startPlanningMode"
                title="Noch nicht gestartet"
                :text="'Die Dienstplanung für ' + calendarMonth.format('MMMM') + ' läuft noch nicht. Sie kann jederzeit gestartet werden.'"
                :icon="playCircleOutline"
                iconType="ion"
                icon-color="grey"
            />

            <!-- start planning trigger button - only if unplanned AND not in "startPlanningMode" modal-->
            <RichResponseButton
                v-if="renderSet.status === PlanningMode.AvailabilityPlanning && !props.startPlanningMode"
                :title="'Dienstplanung überprüfen & starten...'"
                @click="() => showModalFlags.startPlanningWizard = true"
            />

            <!-- submodals -->
            <CalendarModal
                v-model="showModalFlags.listCalendar"
                :initial-date="availabilitySuggestionRS.firstDate ?? calendarMonth.begin"
                :minDate="calendarMonth.begin"
                :maxDate="calendarMonth.until"
                :showPlanningStatus="false"
            />
            <AutopilotJobsitePreference v-model="showModalFlags.jobsitePreferences" :month-id="props.month" />
            <AutopilotWorktimePreferences v-model="showModalFlags.worktimePreferences" :month="props.month" />
            <StartPlanningMonth
                v-if="renderSet.status === PlanningMode.AvailabilityPlanning"
                v-model="showModalFlags.startPlanningWizard"
                :month="props.month"
            ></StartPlanningMonth>
            <RegisterBookingModal
                v-if="showRegisterBooking"
                v-model="showModalFlags.registerBooking"
                :date-min="props.month"
                :date-max="endOfMonth(props.month)"
            ></RegisterBookingModal>
            <JobsitePickerModal
                v-if="(renderSet.status === PlanningMode.BookingPlanning || startPlanningMode) && props.showJobsiteSuggestions && jobsiteSuggestionRS.amount > 0"
                :jobsites="jobsiteSuggestions"
                heading="Stationsvorschläge"
                v-model="showModalFlags.jobsiteSuggestions"
                @select="(jobsite: Jobsite) => addJobsiteToAutopilot(calendarMonth, jobsite)"
            />

        </template>
    </template>
</div>
</template>

<script setup lang="ts">
import ProgressCircle from '@/components/ProgressCircle.vue'
import date, {cmpKapitelDate, endOfMonth, startOfMonth} from "@/helper/datetime/date"
import {computed, PropType, ref, Ref, watch} from "vue"
import {type Absence, Day, Jobsite, PlanningMode, PlanningStatus, ShiftType} from "@/graphql/generated/graphql";
import {clientListLabel, jobsiteSummaryLabel, pluralizedJobsiteAmount} from '@/helper/jobsite';
import {kapitelDateString} from '@/graphql/kapitelTypes';
import {fetchPlanningStatus, getAutopilotWorktimeModeIcon} from '@/helper/autopilotStatus';
import {generateCalendarMonth} from '@/helper/calendar/calendarMonth';
import {getLabelForPlanningStatus} from '@/helper/planningMode';
import {listFormat, pluralize, pluralizeUnitOnly} from '@/helper/amountFormatter';
import {sortShifts, uniqueShifts} from '@/helper/shiftTypes';
import {IonIcon} from "@ionic/vue";
import {DataLoader, InstantDataLoader} from "@/helper/dataLoader";
import Skeleton from "@/components/Skeleton.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import AutopilotJobsitePreference from "@/views/components/Planning/AutopilotJobsitePreference.vue";
import AutopilotWorktimePreferences from "@/views/components/Planning/AutopilotWorktimePreferences.vue";
import CalendarModal from "@/views/Calendar/CalendarModal.vue";
import MonthCalendar from "@/views/Chat/RichResponses/MonthCalendar.vue";
import {useGlobalEmitter} from "@/helper/emitter";
import RichResponseButton from "@/views/Chat/RichResponses/components/RichResponseButton.vue";
import RichResponseListItem from "@/views/Chat/RichResponses/components/RichResponseListItem.vue";
import RegisterBookingModal from "@/views/components/RegisterBookingModal.vue";
import {addCircle, addCircleOutline, addOutline, playCircleOutline, warning, warningOutline} from "ionicons/icons";
import {fetchDaysForDateRange, fetchDaysForMonth, sorter} from "@/helper/day";
import JobsitePickerModal from "@/views/components/JobsitePickerModal.vue";
import {addJobsiteToAutopilot, fetchJobsiteSuggestions} from "@/helper/autopilotPreference";
import {getAbsenceSummary} from "@/helper/absence";
import MonthCalendarForDays from "@/components/MonthCalendarForDays.vue";
import RichResponseHeadline from "@/views/Chat/RichResponses/components/RichResponseHeadline.vue";

const props = defineProps({
    month: {
        type: String as PropType<kapitelDateString>,
        required: true
    },
    showCalendar: {
        type: Boolean,
        required: false,
        default: true
    },
    /** skip initial fetch */
    planningStatusPreload: {
        type: Object as PropType<PlanningStatus>,
        required: false,
        default: undefined
    },
    /** enables "start planning" mode */
    startPlanningMode: {
        type: Boolean,
        required: false,
        default: false
    },
    focusMode: {
        type: String as PropType<'status' | 'settings' | 'auto'>,
        required: false,
        default: 'auto'
    },
    showJobsiteSuggestions: Boolean,
    showAvailabilitySuggestions: Boolean,
    showRegisterBooking: Boolean,
    startPlanningImmediately: Boolean,
})
const calculatedFocusMode = computed(() => props.focusMode === 'auto' ? (renderSet.value.status === 'AVAILABILITY_PLANNING' ? 'settings' : 'status') : props.focusMode);

const calendarMonth = computed(() => generateCalendarMonth(props.month))

const emit = defineEmits(['details', 'startProcess'])

const showModalFlags = ref({
    startPlanningWizard: props.startPlanningImmediately || false,
    jobsitePreferences: false,
    worktimePreferences: false,
    listCalendar: false,
    registerBooking: false,
    jobsiteSuggestions: false,
})

const status : Ref<PlanningStatus|undefined> = ref(undefined);
const days : Ref<Day[]> = ref([]);

const renderSet = computed(() => {
    const s = status.value
    const renderSet = {
        month: date.formatMonthFromKapitelDate(props.month),
        status: PlanningMode.Uncontracted as PlanningMode,
        monthIsStatusLabel: '',
        booking: {
            booked: 0,
            jobsites: [] as Jobsite[],
            completed: false
        },
        planning: {            
            missing: 0,
            target: 0,
            targetReached: false,
            jobsites: [] as Jobsite[],
            favoriteJobsites: [] as Jobsite[],
            nonFavoriteJobsites: [] as Jobsite[],
        }
    }

    if (!s) {
        return renderSet
    }

    renderSet.status = s.planningMode
    renderSet.monthIsStatusLabel = getLabelForPlanningStatus(s, true)

    if (s.planningMode !== PlanningMode.Uncontracted) {

        renderSet.booking.booked = s.bookings
        renderSet.booking.jobsites = s.bookedJobsites
        renderSet.booking.completed = s.bookingCompleted
    }
        
    if (s.autopilotStatus) {
        const jobsites = s.autopilotStatus.autopilotPreferences.autopilotJobsites.map(ajs => ajs.jobsite)
        renderSet.planning = {            
            missing: s.autopilotStatus.missingBookings,
            target: s.autopilotStatus.bookingTarget,
            targetReached: s.autopilotStatus.bookingHoursTargetReached,
            jobsites,
            favoriteJobsites: jobsites.filter(j => j.isFavorite),
            nonFavoriteJobsites: jobsites.filter(j => !j.isFavorite),
        }
    }

    return renderSet
})

const planningRS = computed(() => {
    const text = `${pluralizeUnitOnly(renderSet.value.planning.target, 'Dienst', 'Dienste')} Planungsziel`
    const count = renderSet.value.planning.target

    return {
        text,
        count,
        click: renderSet.value.status === 'BOOKING_PLANNING' || props.startPlanningMode
            ? () => { showModalFlags.value.worktimePreferences = true }
            : undefined,
        icon: status.value?.autopilotStatus ? getAutopilotWorktimeModeIcon(status.value?.autopilotStatus) : undefined,
    }
})


const absencePlanningRS = computed(() => {
    let absencePlanningTarget = 0
    let absencePlanningCount = 0
    let absencePlanningMissing = 0
    if (status.value?.autopilotStatus) {
        const absences = days.value
            .map((d) => d.absence)
            .filter((a): a is Absence => a !== null && a !== undefined);
        const absenceSummary = getAbsenceSummary(absences)

        absencePlanningTarget = status.value?.autopilotStatus?.autopilotPreferences.absenceDays || 0
        absencePlanningCount = absenceSummary.absencePlanningCount || 0
        absencePlanningMissing = absencePlanningTarget - absencePlanningCount
    }

    return {
        absencePlanningTarget,
        absencePlanningCount,
        absencePlanningMissing
    }
})

const jobsiteRS = computed(() => {
    const count = renderSet.value.planning?.jobsites?.length || 0
    let label = `${pluralizedJobsiteAmount(count)} ausgewählt`
    let sublabel = `${jobsiteSummaryLabel(renderSet.value.planning.jobsites)} (${clientListLabel(renderSet.value.planning.jobsites)})`
    let icon = count.toString()
    let iconType : 'text' | 'ion' = 'text'

    if (count === 0) {
        icon = warning
        iconType = 'ion'
        label =  'Bislang noch keine Stationen aktiv'
        sublabel = undefined
    }

    return {
        count,
        icon,
        iconType,
        label,
        sublabel
    }
})

const bookingRS = computed(() => {
    const text = `${pluralizeUnitOnly(renderSet.value.booking.booked, 'Dienst', 'Dienste')} bereits geplant`
    const count = renderSet.value.booking.booked

    let completionRatio = renderSet.value.planning.target > 0 ? (renderSet.value.booking.booked / renderSet.value.planning.target) : 0

    return {
        text,
        count,
        completionRatio,
    }
})


const availabilityRS = computed(() => {
    const count = status.value?.availabilities || 0

    let label = pluralizeUnitOnly(count, 'verfügbarer Tag', 'verfügbare Tage')
    let sublabel = status.value?.availabilityShifts && status.value?.availabilityShifts.length > 0
        ? 'im ' + listFormat(status.value?.availabilityShifts.toSorted(sortShifts))
        : undefined
    let icon = count.toString()
    let iconType = 'text'

    if (renderSet.value.status === 'BOOKING_PLANNING' && renderSet.value.planning.missing > count) {
        icon = warning
        iconType = 'ion'
        label = 'Aktuell nur  ' + pluralize(count, 'verfügbarer Tag', 'verfügbare Tage')
        sublabel = 'für ' + pluralize(renderSet.value.planning.missing, 'noch zu planenden Dienst', 'noch zu planende Dienste')
    }

    return {
        count,
        icon,
        iconType,
        label,
        sublabel
    }
})

const availabilitySuggestionRS = computed(() => {
    // const amount = days.value.reduce((sum, day) => day.demands.length + sum, 0)
    const amount = days.value.reduce((sum, day) => (day.demands.length > 0 ? 1 : 0) + sum, 0)
    const shifts = uniqueShifts(
        days.value
            .reduce(
                (shifts,day) => shifts.concat(day.demands.map(d => d.shiftType)),
                [] as ShiftType[],
            )
            .toSorted(sortShifts)
    )
    const firstDate = days.value.toSorted(sorter).find(day => day.demands.length > 0)?.date

    return {
        amount,
        shifts,
        firstDate
    }
})

const jobsiteSuggestions = ref([] as Jobsite[])
const jobsiteSuggestionRS = computed(() => {
    const label = clientListLabel(jobsiteSuggestions.value)
    const amount = jobsiteSuggestions.value.length
    return {
        amount,
        label
    }
})


let preloadedPlanningStatus = props.planningStatusPreload
const loader = InstantDataLoader([
    async () => {
        console.log("load ", props.month)
        if (preloadedPlanningStatus) {
            status.value = preloadedPlanningStatus
            preloadedPlanningStatus = undefined
        } else  {
            status.value = await fetchPlanningStatus(calendarMonth.value)
        }
    },
    async () => {
         days.value = await fetchDaysForMonth(calendarMonth.value)
    },
    async () => {
        jobsiteSuggestions.value = await fetchJobsiteSuggestions(calendarMonth.value)
    }
])


useGlobalEmitter().on('day:mutated', (date) => {
    if (calendarMonth.value.containsDate(date)) {
        loader.load()
    }
})
useGlobalEmitter().on('autopilotPreferences:mutated', (month) => {
    if (month === props.month) {
        loader.load()
    }
})
useGlobalEmitter().on('availabilityPlanningMonth:mutated', (month) => {
    if (month === props.month) {
        loader.load()
    }
})

watch(() => props.month, () => {loader.load()})

</script>

<style lang="scss" scoped>
.listItemSuffixImage {
    width: 2.5em;
    height: 2.5em;
}

ion-button {
   margin-top: 0.5em;
}
</style>
