<script setup lang="ts">
import {kapitelDateString} from '@/graphql/kapitelTypes'
import {IonChip} from '@ionic/vue'
import date from '@/helper/datetime/date'
import {computed, ComputedRef, Prop, PropType, ref, Ref} from 'vue'
import {MonthMetaData} from './types';
import {pluralize} from '@/helper/amountFormatter';
import Skeleton from '@/components/Skeleton.vue';
import TimesheetProcess from "@/views/Timesheet/TimesheetProcess.vue";
import StartPlanningMonth from "@/views/components/Planning/StartPlanningMonth.vue";
import PlanningMonthSettingsModal from "@/views/components/Planning/PlanningMonthSettingsModal.vue";
import {pluralizedJobsiteAmount, pluralizedJobsiteLabel} from "@/helper/jobsite";
import {pluralizedBookingAmount, pluralizedBookingLabel} from "@/helper/booking";

const props = defineProps({
    month: String as PropType<kapitelDateString>,
    data: Object as PropType<MonthMetaData | undefined>,
    showPlanningStatus: {
        type: Boolean as PropType<boolean>,
        required: true,
    }
})

const monthState = computed(() => {
    if (!props.showPlanningStatus) {
        return undefined
    }

    switch (true) {
        case props.data?.isPlanning:
            return 'planningPending'
        case props.data?.isBooking:
            return props.data?.isPlanningComplete ?  'planningCompleted' : 'planningRunning'
        case props.data?.isTimeTracking:
            return 'timeTracking'
        case props.data?.isPast:
            return 'done'
        default:
            return undefined
    }
})

const subheading : ComputedRef<{text: string, button?: {label: string, click: any}}>= computed(() => {
    const availabilityCount = props.data?.numAvailabilities || 0
    const bookingCount = props.data?.numBookings || 0
    const untrackedCount = props.data?.numUntrackedPastBookings || 0
    const bookingTarget = props.data?.targetBookings || 0
    const jobsiteCount = props.data?.jobsiteCount || 0
    const missingBookings = bookingTarget - bookingCount

    const absencePlanningTarget = props.data?.numAbsencePlanningDays || 0
    const absencePlanningCount = props.data?.numAbsencePlanningAbsences || 0

    let text = ''
    let button = undefined

    if (props.showPlanningStatus) {

        if (props.data?.isContractStart) {
            text += 'Vertragsanfang, '
        }

        switch (monthState.value) {
            case 'planningPending':
                text += `Dienstplanung noch nicht gestartet, ${pluralize(availabilityCount, 'Tag', 'Tage', 'bislang noch keine')} verfügbar.`
                button = {
                    label: "Jetzt starten",
                    click: () => {openStartPlanningModal.value = true}
                }
                break
            case 'planningRunning':
                text +=  `Dienstplanung läuft: `
                text += `${bookingCount} von ${bookingTarget} Diensten bereits geplant`
                text += (availabilityCount >= missingBookings)
                    ? `, ${pluralize(availabilityCount, 'Tag', 'Tage', 'keine')} verfügbar`
                    : `, nur ${pluralize(availabilityCount, 'Verfügbarkeit', 'Verfügbarkeiten' )}`
                if (absencePlanningTarget > 0 && absencePlanningCount < absencePlanningTarget) {
                    text += `, zu wenig Abwesenheiten für aktuelles Planungsziel`
                }
                text += `, ${pluralize(jobsiteCount, 'Station', 'Stationen')} aktiv - `
                button = {
                    label: "anpassen",
                    click: () => {openPlanningPreferencesModal.value = true},
                }
                break
            case 'planningCompleted':
                text +=  `Dienstplan fertig: `
                text += `${pluralizedBookingAmount(bookingCount)} geplant auf ${pluralizedJobsiteAmount(jobsiteCount)}`
                button = {
                    label: "Einstellungen anzeigen",
                    click: () => {openPlanningPreferencesModal.value = true},
                }
                break
            case 'timeTracking':
                text = `Zeiterfassung: ${pluralize(untrackedCount, 'Dienst', 'Dienste')} Dienste noch ohne Unterschrift.`
                button = {
                    label: `Jetzt unterschreiben lassen`,
                    click: () => {openTimesheetProcessModal.value=true}
                }
                break
            case 'done':
                text = 'Abgeschlossen'
                break
        }
    }


    return {
        text,
        button,
    }
})


const monthLabel = computed(() => date.formatKapitelDate(props.month, 'MMMM'))

const openTimesheetProcessModal: Ref<boolean> = ref(false)
const openPlanningPreferencesModal: Ref<boolean> = ref(false)
const openStartPlanningModal: Ref<boolean> = ref(false)
</script>

<template>
<div
    class="calendar-month"
>
    <h1 class="custom-ricarda no-margin-left">
        {{ monthLabel }}
    </h1>
    <Skeleton v-if="!props.data" :items="['chip']" />
    <template v-if="props.showPlanningStatus && props.data && (subheading.button || subheading.text)">
        <p @click="subheading.button?.click" class="subheading">
            {{subheading.text}}
            <strong v-if="subheading.button" class="clickable">{{subheading.button.label}} ></strong>
        </p>
    </template>

    <TimesheetProcess v-if="monthState==='timeTracking'" v-model="openTimesheetProcessModal"/>
    <PlanningMonthSettingsModal v-if="monthState==='planningRunning' || monthState === 'planningCompleted'" v-model="openPlanningPreferencesModal" :month="props.month" />
    <StartPlanningMonth  v-if="monthState==='planningPending'" v-model="openStartPlanningModal" :month="props.month"></StartPlanningMonth>
</div>
</template>

<style lang="scss" scoped>
.calendar-month {
    padding-left: var(--custom-spacing-app-content-padding-horizontal);
    padding-right: var(--custom-spacing-app-content-padding-horizontal);
    padding-top: var(--custom-spacing-app-content-padding-vertical);
    padding-bottom: var(--custom-spacing-app-content-padding-vertical);

    ion-chip {
        margin-left: 0;
    }

    // white-space: nowrap; // to prevent chips from getting 2 lined
    .subheading {
        color: var(--color-text);

        .clickable {
            white-space: nowrap;
            color: var(--ion-color-primary);
        }
    }
}
</style>
