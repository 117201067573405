<template>
    <div class="chat-voice-input" :class="{'disabled':disabled}">
        <div class="icon-container floating-big-pseudo"
             :class="{'recording-active': recording.status.value === 'on', 'button-holding': buttonMode === 'holding', 'button-undetermined': buttonMode === 'undetermined', 'button-toggled': buttonMode === 'toggled'}"
        >
            <div
                v-if="recording.status.value === 'on'"
                class="pulse"
                />
            <div
                class="clickarea"
                @touchstart="recordButton"
                @touchend="e => recordButton(e, true)"
                @mousedown="e => recordButton(e)"
                @mouseup="e => recordButton(e, true)"
            />
            <InlineSVG :src="recording.status.value === 'on' && buttonMode === 'toggled' ? '/icons/stop.svg' : '/icons/microphone.svg'"/>
        </div>

        <Transitionator :types="['marginbottom', 'opacity']" easing="easeOutBounce">
            <div class="stop-hint floating" v-if="!show.showMuteAlert && (recording.status.value === 'on' && buttonMode == 'toggled')">
                Drücke Stop um die Aufnahme zu senden.
            </div>
        </Transitionator>

        <Transitionator :types="['marginbottom', 'opacity']" easing="easeOutBounce">
            <div class="stop-hint floating" v-if="show.showMuteAlert">
                Dein Gerät ist stumm geschaltet!
            </div>
        </Transitionator>
        
        <!--<div v-if="recording.status.value === 'on'" class="recording">
            <span>{{ recording.time.value }}</span>
        </div>
        <div v-if="recording.status.value === 'postprocessing'" class="processing">
            <span>Verarbeitung...</span>
        </div>
        <InlineSVG
            v-if="show.showMicrophone"
            @touchstart="recordButton"
            @touchend="recordButton"
            @mousedown="recordButton"
            @mouseovup="recordButton"
            :class="{'icon-gear': !['on','off'].includes(recording.status.value), 'icon-microphone': ['on','off'].includes(recording.status.value), 'recording-active': recording.status.value === 'on'}"
        />
        -->
    </div>
</template>

<script setup lang="ts">
import {computed, Ref, ref, watch} from "vue";
import InlineSVG from "@/components/InlineSVG.vue";
import {IonInput} from "@ionic/vue";
import {SpeechToTextRecorder} from "@/helper/chat/speechToTextRecorder";
import {consoleLogChat} from "@/helper/console";
import {useChatStore} from "@/helper/chat/chatStore";
import {stopAudio} from "@/helper/audioContext";
import Transitionator from '@/views/components/transitions/Transitionator.vue';
import {Capacitor} from "@capacitor/core";
import {Mute} from "@capgo/capacitor-mute";

const emit = defineEmits(["text:submit", "text:input"]);

const text = ref("");

const reevaluateMuted = async () => {
    if(Capacitor.isNativePlatform()) {
        isMuted.value = (await Mute.isMuted()).value;
    }else{
        isMuted.value = false;
    }
}
const isMuted = ref(false)

const chatStore = useChatStore()
const show = computed(()=>{
    return {
        showMicrophone:chatStore.voiceOrTextMode=="voice",
        showInput:chatStore.voiceOrTextMode=="text",
        showMuteAlert: isMuted.value
    }
})

const props = defineProps({
    disabled: {
        type: Boolean,
        default : false,
        required : false,
    }
})

const recording : {
    recorder: {type: string, start : () => Promise<void>, stop: () => Promise<void>, transcription: Promise<string|undefined>} | undefined,
    status: Ref<'on'|'off'|'preprocessing'|'postprocessing'> ,
    time: Ref<string>,
    timeUpdateInterval: NodeJS.Timeout | undefined
} = {
    recorder: undefined,
    status: ref('off'),
    time: ref("00:00"),
    timeUpdateInterval: undefined
}

const onInput = () => {
    if (text.value.length === 0) {
        stopRecording();
    }
    emit("text:input", text.value.trim());
};


const _recordingTimerStart = () => {
    recording.time.value = "00:00";

    recording.timeUpdateInterval = setInterval(() => {
        let [minutes, seconds] = recording.time.value.split(":").map(Number);
        seconds++;
        if (seconds >= 60) {
            minutes++;
            seconds = 0;
        }
        recording.time.value = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    }, 1000); 
}
const _recordingTimerStop = () => {
    if (recording.timeUpdateInterval) {
        clearInterval(recording.timeUpdateInterval)
        recording.timeUpdateInterval = undefined
    }
}

let holdToggleTimer:any = undefined
let debounceTimer:any = undefined
const buttonMode:Ref<'off'|'undetermined'|'holding'|'toggled'> = ref('off')
const setButtonMode = (mode) => {
    consoleLogChat('change recording mode from %o to %o', buttonMode.value, mode)
    buttonMode.value = mode
}
const recordButton = (e: Event | undefined, isEndEvent: boolean = false) => {

    reevaluateMuted()

    if (e) {
        e.preventDefault();
        e.stopPropagation()
    }

    if (holdToggleTimer) {
        clearTimeout(holdToggleTimer)
        holdToggleTimer = undefined
        setButtonMode('toggled')
    }

    // skipp all inputs (down/up) very close to another
    if (debounceTimer) {
        return
    }
    debounceTimer = setTimeout(() => {debounceTimer = undefined}, 250)


    // reset speaker stuff
    stopAudio(); // stop concurrently playing stuff
    if(chatStore.ttsStatus === 'speaking'){
        chatStore.resetCurrentExpertRun()
    }


    if (recording.status.value === 'off' && props.disabled) {
        return
    }
    if (recording.status.value !== 'on' && recording.status.value !== 'off') {
        if (recording.status.value === 'preprocessing') {
            // don't intervene
            return
        }
        if (recording.status.value === 'postprocessing') {
            // allow override and restart
            consoleLogChat('force reset voice input despite currently postprocessing')
            resetRecording()
        }
    }

    if (recording.status.value === 'on') {
        setButtonMode('off')
        stopRecording()
    } else if (!isEndEvent) {
        setButtonMode('undetermined')
        holdToggleTimer = setTimeout(() => {
            holdToggleTimer = undefined;
            setButtonMode('holding')
        }, 500)
        startRecording()
    }
}

const startRecording = async () => {
    if (recording.status.value !== 'off') {
        return
    }

    recording.status.value = 'preprocessing'
    useChatStore().setTranscribeStatus('preparing')

    recording.recorder = await SpeechToTextRecorder();

    if (!recording.recorder) {
        recording.status.value = 'off'
        useChatStore().setTranscribeStatus('ready')
        return
    }

    _recordingTimerStart()

    try {
        await recording.recorder.start()
    } catch {
        recording.status.value = 'off'
        useChatStore().setTranscribeStatus('ready')
    }

    recording.status.value = 'on'
    useChatStore().setTranscribeStatus('listening')
};

const stopRecording = async () => {
    if (recording.status.value !== 'on' || !recording.recorder) {
        return
    }
    
    recording.status.value = 'postprocessing'
    useChatStore().setTranscribeStatus('transcribing')

    _recordingTimerStop()

    const recorder = recording.recorder

    let text
    try {
        await recorder.stop()

        text = await recorder.transcription

        consoleLogChat('transcription result: %s', text)
    } catch (e) {
        console.error(e)
    }

    // ensure we are still the active recorder
    if (recorder == recording.recorder) {
        resetRecording()

        if (!text) {
            consoleLogChat("skip empty")
            return
        }

        emit("text:submit", text)
    }
};

const resetRecording = () => {
    recording.status.value = 'off'
    useChatStore().setTranscribeStatus('ready')
    recording.recorder = undefined
    reevaluateMuted()
}

watch(recording.status, () => { consoleLogChat('Recorder %o %o',recording.recorder?.type, recording.status.value) })
reevaluateMuted()
</script>

<style scoped lang="scss">
.chat-voice-input {
    position: absolute;
    width: 100%;

    &.disabled {
        opacity: 0.6;
    }

    > .stop-hint {
        position: absolute;
        top: -7em;
        left: 50%;
        width: 50vw;
        font-size: var(--custom-font-size-small);
        color: var(--ion-color-grey);
        text-align: center;
        transform: translateX(-50%);
        background-color: var(--ion-color-white);
        padding: 0.5em;
        border-radius: 1em;
    }

    > .icon-container {
        $SIZE: 5em;
        $MIC_SCALE: 2.25;
        position: absolute;
        left: 50%;
        top: 50%;

        width: $SIZE;
        height: $SIZE;
        border-radius: 50%;
        background-color: var(--ion-color-white);
        color: var(--ion-color-primary);

        -webkit-transition: background-color 250ms linear, color 250ms linear;
        -ms-transition: background-color 250ms linear, color 250ms linear;
        transition: background-color 250ms linear, color 250ms linear;

        display: flex;
        align-items: center;
        justify-content: center;

        transform: translate(-50%, -50%);

        > .inline-svg {
            width: calc($SIZE / $MIC_SCALE);
            height: calc($SIZE / $MIC_SCALE);
        }

        > .pulse {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1, 1);
            animation: recording-pulse 1s infinite;
            box-shadow: 0 0 0 0 rgba(var(--ion-color-primary-rgb), 0.2);
            border-radius: 100%;

            @keyframes recording-pulse {
                0% {
                    box-shadow: 0 0 0 0px rgba(var(--ion-color-primary-rgb), 0.2);
                }
                100% {
                    box-shadow: 0 0 0 2rem rgba(var(--ion-color-primary-rgb), 0);
                }
            }
        }

        > .clickarea {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            
        }



        &.button-holding,
        &.button-undetermined {
            background-color: var(--ion-color-primary);
            color: var(--ion-color-white);
        }

        &.button-toggled {
            &:before {
                content: " ";
                position: absolute;
                top: -1px;
                left: -1px;
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                border: 1px solid transparent;
                border-top-color: red;
                border-radius: inherit;
                animation: rotate-mic-border 1s linear infinite;

                @keyframes rotate-mic-border {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }


}




.fragAInesInput {
    color: var(--ion-color-secondary);
}
.voice-input-container {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.15em;
    padding-bottom: 0.15em;
    border-radius: 1rem;
    z-index: 9;
    background-color: var(--ion-color-white);

    ion-input {
        flex: 1;
        border: none;
        outline: none;
        padding: 8px 12px;
        border-radius: 24px;
        font-size: 16px;
    }



    .microphone,
    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--ion-color-secondary);
    }
    .inline-svg {
        width: 2rem;
        height: 2rem;
        padding: .3rem;
    }
    .microphone {
        width: 2rem;
        height: 2rem;
        padding: .3rem;
    }

    .submit-button {
        width: 2rem;
        height: 2rem;

        color: var(--ion-color-grey);

        &.active {
            color: var(--ion-color-primary);
        }
        
    }

    .recording,
    .processing {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-light);
        padding: 0 16px;
        border-radius: 24px;
        margin-right: 6px;
        .recording-icon {
            margin-right: 8px;
        }
    }



    .icon-microphone::before,.icon-gear::before {
        background-color: var(--ion-color-black);
        content: '';
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        mask-size: cover;
        mask-position: center;
        mask-repeat: no-repeat;
    }




    .icon-microphone::before {
        mask-image: url(/icons/microphone.svg);
        //background-image: url('/icons/microphone.svg');
        -webkit-mask-image: url(/icons/microphone.svg);
    }


    .icon-gear::before {

        mask-image: url(/icons/gear.svg);
        //background-image: url('/icons/gear.svg');
        -webkit-mask-image: url(/icons/gear.svg);
    }

    .icon-gear{
        animation-name: spinning;
        animation-duration: 5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: 55% 55%; // gear is not centered, todo fix by professional
    }
    @keyframes spinning {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
