<template>
    <div class="plus-minus-input">
        <IonButton
            @click="onDecrement"
            color="light"
            :disabled="!!inputValueNumber && !!min && inputValueNumber<=min"
        >
            <ion-icon slot="icon-only" :icon="removeOutline" />
        </IonButton>
        <div class="value-display">
            {{ pluralize(inputValueNumber || 0, 'Dienst', 'Dienste') }}
        </div>
        <IonButton
            @click="onIncrement"
            expand="block"
            color="light"
            :disabled="!!inputValueNumber && !!max && inputValueNumber>=max"
        >
        <ion-icon slot="icon-only" :icon="addOutline" />
        </IonButton>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, Ref, ref, watch} from "vue";
import { pluralize } from "@/helper/amountFormatter";
import { IonInput, IonButton,IonRow, IonCol, IonGrid, IonLabel, IonIcon } from "@ionic/vue";
import { removeOutline, addOutline } from 'ionicons/icons'

const props = defineProps({
    modelValue: {
        required: false,
        type: Number as PropType<number | undefined>,
        default: undefined
    },
    min: {
        required: false,
        type: Number as PropType<number | undefined>,
        default: undefined
    },
    max: {
        required: false,
        type: Number as PropType<number | undefined>,
        default: undefined
    },
    placeholder: {
        required: false,
        type: String,
        default: '-'
    },
    unit: {
        required: false,
        type: String  as PropType<String | undefined>,
        default: undefined
    },
})
const emit = defineEmits(["update:modelValue"]);

/**
 * internal value state & modifiers
 */
const value : Ref<undefined|number> = ref(props.modelValue || undefined)
watch(() => props.modelValue, v => {
    value.value = v
    resetInputValue()
})
const setValue = (newValue: number) => {
    if (props.min != undefined && newValue < props.min) {
        return false
    }
    if (props.max != undefined && newValue > props.max) {
        return false
    }

    value.value = newValue
    emit('update:modelValue', value.value)

    resetInputValue()

    return true
}

/**
 * input field value
 */
const inputValue : Ref<string> = ref(value.value?.toString() || '')
const inputValueNumber = computed(() => parseInt(inputValue.value) || undefined)
const resetInputValue = () => inputValue.value = value.value?.toString() || ''

/**
 * interaction handler
 */
const onDecrement = () => setValue((value.value || 0) - 1)
const onIncrement = () => setValue((value.value || 0) + 1)
const onChange = (e:Event) => {
    if (!inputValueNumber.value || !setValue(inputValueNumber.value)) {
        resetInputValue()
    }
}
</script>

<style scoped lang="scss">
.plus-minus-input {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    > ion-button {
        flex: 0 0 auto;
    }

    > .value-display {
        flex: 1;
        text-align: center;
        font-size: var(--custom-font-size-large);
        font-weight: var(--custom-font-weight-bold);
    }
}
</style>
