import {mutation, query} from "@/graphql/client";
import {gql} from "@urql/vue";
import {
    AutopilotJobsite,
    AutopilotPlayState,
    AutopilotPreference,
    Jobsite,
    PlanningTemplate,
    WorktimeMode
} from "@/graphql/generated/graphql";
import {CalendarMonth, generateCalendarMonth, sorter as calendarMonthSorter} from "@/helper/calendar/calendarMonth";
import {useGlobalEmitter} from "@/helper/emitter";
import {clientFragment, houseFragment, jobsiteFragment} from "./jobsite";

export const autopilotPreferenceFragment = gql`
${jobsiteFragment}
fragment AutopilotPreferenceFragment on AutopilotPreference {
    month
    worktimeMode
    playState
    bookingTarget
    absenceDays
    autopilotJobsites {
        source
        jobsite {
            ...JobsiteFragment
            client {
                ...ClientFragment
            }
            house {
                ...HouseFragment
            }
        }
    }
}`

export class AutopilotPreferenceObject {
    constructor(
        public id: string,
        public month: CalendarMonth,
        public autopilotJobsites: Array<AutopilotJobsite>,
        public worktimeMode: WorktimeMode | undefined | null,
        public bookingTarget: number | undefined | null,
        public playState: AutopilotPlayState
    ) {}

    static createFromGraphQL(autopilotPreference: AutopilotPreference) : AutopilotPreferenceObject {
        return new AutopilotPreferenceObject(
            autopilotPreference.month,
            generateCalendarMonth(autopilotPreference.month),
            autopilotPreference.autopilotJobsites,
            autopilotPreference.worktimeMode,
            autopilotPreference.bookingTarget,
            autopilotPreference.playState
        )
    }
}

export async function fetchAutopilotPreferenceObject(month: CalendarMonth): Promise<AutopilotPreferenceObject | undefined> {
    const result = await query(gql`
        ${autopilotPreferenceFragment}
        ${clientFragment}
        ${houseFragment}
        query getAutopilotPreference(
            $month: KapitelDateImmutable!
        ) {
            autopilotPreference (month: $month){
                ...AutopilotPreferenceFragment
            }
        }`, {
            month: month.begin
        }
    )

    const autopilotPreference = result?.data?.autopilotPreference

    return autopilotPreference
        ? AutopilotPreferenceObject.createFromGraphQL(autopilotPreference)
        : undefined
}

export async function updateAutopilotPreference(
    month: CalendarMonth,
    autopilotPreferenceProperties: {
        worktimeMode?: WorktimeMode
        playState?: AutopilotPlayState
        bookingTarget?: number,
        absenceDays?:number
    }
): Promise<AutopilotPreferenceObject> {

    const result = await mutation(gql`
        ${autopilotPreferenceFragment}
        ${clientFragment}
        ${houseFragment}
        mutation updateAutopilotPreference(
            $month: KapitelDateImmutable!,
            $worktimeMode: WorktimeMode,
            $playState: AutopilotPlayState,
            $bookingTarget: Int,
            $absenceDays: Int
        ) {
            updateAutopilotPreference(
                month: $month
                worktimeMode: $worktimeMode
                playState: $playState
                bookingTarget: $bookingTarget
                absenceDays: $absenceDays
            ) {
                ...AutopilotPreferenceFragment
            }
        }`, {
            month: month.begin,
            worktimeMode: autopilotPreferenceProperties.worktimeMode || null,
            playState: autopilotPreferenceProperties.playState || null,
            bookingTarget: typeof autopilotPreferenceProperties.bookingTarget != 'undefined' ? autopilotPreferenceProperties.bookingTarget : null,
            absenceDays: typeof autopilotPreferenceProperties.absenceDays != 'undefined' ? autopilotPreferenceProperties.absenceDays : null,
        }
    )

    useGlobalEmitter().emit('autopilotPreferences:mutated', month.getId())

    return AutopilotPreferenceObject.createFromGraphQL(result.data.updateAutopilotPreference)
}

export async function addJobsiteToAutopilot(month: CalendarMonth, jobsite: Jobsite): Promise<AutopilotJobsite> {

    const result = await mutation(gql`
            mutation addJobsiteToAutopilot(
                $month: KapitelDateImmutable!,
                $jobsiteId: Int!
            ) {
                addJobsiteToAutopilot(
                    month: $month
                    jobsiteId: $jobsiteId
                ) {
                    jobsite {
                        id
                    }
                }
            }`, {
            month: month.begin,
            jobsiteId: jobsite.id
        }
    )

    useGlobalEmitter().emit('autopilotPreferences:mutated', month.getId())

    return result.data
}

export async function removeJobsiteFromAutopilot(month: CalendarMonth, jobsite: Jobsite): Promise<AutopilotJobsite> {

    const result = await mutation(gql`
            mutation removeJobsiteFromAutopilot(
                $month: KapitelDateImmutable!,
                $jobsiteId: Int!
            ) {
                removeJobsiteFromAutopilot(
                    month: $month
                    jobsiteId: $jobsiteId
                ) {
                    jobsite {
                        id
                    }
                }
            }`, {
            month: month.begin,
            jobsiteId: jobsite.id
        }
    )

    useGlobalEmitter().emit('autopilotPreferences:mutated', month.getId())

    return result.data
}

/**
 * to be used as compareFn for AutopilotPreferenceObject[].sort()
 */
export function sorter(a : AutopilotPreferenceObject, b : AutopilotPreferenceObject) {
    return calendarMonthSorter(a.month, b.month)
}

export function getWorktimeModes() {
    return [
        WorktimeMode.More,
        WorktimeMode.SpotOn,
        WorktimeMode.Less
    ]
}

export function getWorktimeModeLabel(worktimeMode: string) {
    switch(worktimeMode) {
        case WorktimeMode.Less: return 'Weniger arbeiten'
        case WorktimeMode.SpotOn: return 'Sollstunden'
        case WorktimeMode.More: return 'Überstunden ansammeln'
    }
}

export const getIconForWorktimeMode = (worktimeMode: string) => {
    switch (worktimeMode) {
        case WorktimeMode.Less: return '/icons/trend-down.svg'
        case WorktimeMode.SpotOn: return '/icons/target.svg'
        case WorktimeMode.More: return '/icons/trend-up.svg'
    }
}

export function getIconForPlayState(type: AutopilotPlayState | AutopilotPreference ) {
    switch (type) {
        case AutopilotPlayState.Running: return '/icons/play-circle.svg'
        case AutopilotPlayState.Paused: return '/icons/pause-circle.svg'
        case AutopilotPlayState.Scrapped: return '/icons/stop-circle.svg'
    }
}

export async function fetchPlanningTemplate(month: CalendarMonth): Promise<PlanningTemplate> {
    const result = await query(gql`
            query getPlanningTemplate(
                $month: KapitelDateImmutable!
            ) {
                planningTemplate (month: $month){
                    bookingTargetMax
                    bookingTargetMin
                    bookingTargetSpotOn
                    bookingTargetSuggestion
                    availabilityShiftSuggestion {
                        dow
                        shifts
                    }
                    absenceDayHours
                    averageShiftDuration
                }
            }`, {
            month: month.begin
        }
    )

    return result.data.planningTemplate
}

export async function fetchJobsiteSuggestions(month: CalendarMonth): Promise<Jobsite[]> {


    const result = await query(
        gql`${clientFragment}
            ${houseFragment}
            ${jobsiteFragment}
            query GetJobsiteSuggestions (
                $month: KapitelDateImmutable!
            ){
              jobsiteSuggestions (
                month: $month
              ){
                ...JobsiteFragment                    
                client {
                    ...ClientFragment
                }
                house {
                    ...HouseFragment
                }
              }
        }`,
        {
            month: month.begin
        }
    )

    return result?.data?.jobsiteSuggestions

}
